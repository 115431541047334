import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_IMPORT_SHIPPING_MARK } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomStyle from "../../common/CustomStyle";

const ValidationSchema = Yup.object().shape({
    batchNo: Yup.string().required('Batch Number is required'),
    productId: Yup.number().required('Product is required'),
    shippingMark: Yup.string().required('Shipping Mark is required'),
});

const CreateShippingMark = ({ handleClose, setMessage, fetchData, productsList, clientCompanyId, commanShipmentId, importShipmentId }) => {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                commanShipmentId: values.commanShipmentId,
                importShipmentId: values.importShipmentId,
                clientCompanyId: values.clientCompanyId,
                batchNo: values.batchNo,
                productId: values.productId,
                shippingMark: values.shippingMark,
            };

            const response = await genericPost(CREATE_IMPORT_SHIPPING_MARK, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipping mark added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        commanShipmentId: commanShipmentId,
        clientCompanyId: clientCompanyId,
        importShipmentId: importShipmentId,
        batchNo: '',
        productId: '',
        shippingMark: '',
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Shipping Mark
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Batch Number"
                                    name="batchNo"
                                    fieldLabel="Batch Number"
                                    required={true}
                                    value={values.batchNo}
                                    onChange={handleChange}
                                    error={touched.batchNo && !!errors.batchNo}
                                    helperText={touched.batchNo && errors.batchNo}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={productsList}
                                    fieldLabel="Select Product"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={productsList.find((option) => option.id === values.productId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('productId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Product"
                                    error={touched.productId && !!errors.productId}
                                    helperText={touched.productId && errors.productId}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <CustomTextarea
                                    name="shippingMark"
                                    fieldLabel="Shipping Mark"
                                    placeholder="Enter Shipping Mark"
                                    required={true}
                                    value={values.shippingMark}
                                    onChange={handleChange}
                                    sx={{ height: '100px !important' }}
                                    error={touched.shippingMark && !!errors.shippingMark}
                                    helperText={touched.shippingMark && errors.shippingMark}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateShippingMark;

