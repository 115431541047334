import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Card, Divider, Link, Typography } from '@mui/material';
import SubscriptionHistory from '../../Pages/SubscriptionHistory';
import Subscription from '../../Pages/Subscription';
import MyCompany from '../../Pages/MyCompany';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { PersonalDetails } from '../Masters/PersonalSettings';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

const CustomSetting = ({ defaultView, onViewChange }) => {
    const [open, setOpen] = React.useState(true);
    const [expanded, setExpanded] = React.useState({});
    const [activeView, setActiveView] = React.useState(defaultView);

    useEffect(() => {
        const initialExpandedIndex = menuItems.findIndex(item =>
            item.subItems.some(subItem => subItem.view === defaultView)
        );
        if (initialExpandedIndex !== -1) {
            setExpanded(prev => ({ ...prev, [initialExpandedIndex]: true }));
        }
    }, [defaultView]);

    const menuItems = [
        {
            label: 'General',
            subItems: [
                {
                    label: 'Personal Settings',
                    view: 'personalSettings',
                    content: <PersonalDetails />,
                },
                {
                    label: 'Company Settings',
                    view: 'companySettings',
                    content: <MyCompany />,
                },
            ],
        },
        {
            label: 'Subscription Plans',
            subItems: [
                {
                    label: 'Subscription Plans',
                    view: 'SubscriptionPlans',
                    content: <Subscription />,
                },
                {
                    label: 'Subscription History',
                    view: 'SubscriptionHistory',
                    content: <SubscriptionHistory />,
                },
            ],
        },
        {
            label: 'Users & Control',
            subItems: [
                {
                    label: 'Company Users',
                    view: 'CompanyUsers',
                    content: 'Company Users Content',
                },
                {
                    label: 'Security Controls',
                    view: 'securityControls',
                    content: " Security Controls",
                },
            ],
        },
        {
            label: 'Modules',
            subItems: [
                {
                    label: 'Company Module',
                    view: 'companyModule',
                    content: 'Company Module',
                },
            ],
        },

    ];

    const handleToggle = (index) => {
        setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleViewChange = (view) => {
        setActiveView(view);
        if (onViewChange) {
            onViewChange(view);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        border: '0px solid rgba(0, 0, 0, 0.12)',
                        top: "58px"
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                < Card variant="outlined" sx={{ height: "100vh", marginLeft: "10px", color: "#333333", }} >
                    <Typography style={{ padding: "10px 10px 10px 10px", fontSize: "16px", fontWeight: "600" }}>Setup</Typography>
                    <Divider />
                    <List>
                        {menuItems.map((item, index) => (
                            <React.Fragment key={index}>
                                <ListItem disablePadding>
                                    <ListItemButton sx={{
                                        pl: 1, pt: 1, pb: 1,
                                    }}
                                        onClick={() => handleToggle(index)}>
                                        {expanded[index] ? <ArrowDropDownOutlinedIcon /> : <ArrowDropUpOutlinedIcon />}
                                        <Typography
                                            sx={{
                                                fontWeight: "600", color: "#333333", fontSize: "14px",
                                            }}
                                        >
                                            {item.label}
                                        </Typography>
                                    </ListItemButton>
                                </ListItem>
                                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.subItems.map((subItem, subIndex) => (
                                            <ListItem key={subIndex} disablePadding >
                                                <ListItemButton
                                                    sx={{
                                                        pl: 3, pt: 0, pb: 0,
                                                        fontSize: "14px",
                                                        color: activeView === subItem.view ? '#ffff' : 'inherit',
                                                        fontWeight: activeView === subItem.view ? 'bold' : 'normal',
                                                    }}
                                                    style={{
                                                        backgroundColor: activeView === subItem.view ? '#007BFF' : 'inherit',

                                                    }}
                                                    onClick={() => handleViewChange(subItem.view)}
                                                >
                                                    <ListItemText primary={subItem.label} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        ))}
                    </List>
                </Card>
            </Drawer>
            <Main open={open}>
                <Card variant="outlined" sx={{ pr: 1, pl: 1 }}>
                    {menuItems.map((item) =>
                        item.subItems.map((subItem) =>
                            activeView === subItem.view ? (
                                <Typography key={subItem.view} variant="body1">
                                    {subItem.content}
                                </Typography>
                            ) : null
                        )
                    )}
                </Card>
            </Main>
        </Box>
    );
};


export default CustomSetting;