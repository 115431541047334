import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_PRODUCT_WISE_IMPORT } from "../../services/apiRoutes";
import CustomLoader from "../common/CustomLoader";
const title = "Product Wise Import Report";

const ProductWiseImportReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: 'sn', header: 'SN', size: 50 },
            { accessorKey: 'productName', header: 'Product Name', size: 20 },
            { accessorKey: 'totalQty', header: 'Import Qty by Unit', size: 100 },
            { accessorKey: 'sdd', header: 'Import Qty by Alternate Unit', size: 180 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.particularName)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const handleViewClick = (particularName) => {
        navigate(`/app/reports/productwiseimportreport/${particularName}`);
    };



    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_PRODUCT_WISE_IMPORT });
            if (response.status === 200 && response.data.success) {
                const productData = response.data.data.map((item, index) => ({
                    sn: index + 1,
                    particularName: item.particularName,
                    productName: item.productName,
                    unitName: item.unitName,
                    totalQty: item.totalQty,
                    totalRate: item.totalRate,
                    totalAmount: item.totalAmount.toFixed(2),
                    totalUnit: item.totalUnit
                }));

                setData(productData);
            } else {
                setError('No data available or unsuccessful response.');
            }
        } catch (error) {
            setError('Error fetching product data');
            console.error('Error fetching product data', error);
        } finally {
            setLoading(false);
        }
    };





    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default ProductWiseImportReport;
