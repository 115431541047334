import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/common/DataTable";
import { genericGet } from "../../../services/apiServices";
import { READ_CUSTOMER_LEDGER, READ_SUPPLIER_LEDGER } from "../../../services/apiRoutes";
import { AuthContext } from "../../../context/AuthContext";
import CustomLoader from "../../common/CustomLoader";
import axios from "axios";

const title = "Customer Ledger  Wise Invoice";

const CustomerledgerView = () => {
    const { clientCompanyId } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: 'commercialInvExpId', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 150 },
            { accessorKey: 'proformaInvoiceNo', header: 'Commercial Invoice', size: 100 },
            { accessorKey: 'productName', header: 'Particular', size: 150 },
            { accessorKey: 'invoiceAmount', header: 'Invoice Amount', size: 100 },
            { accessorKey: 'receipt', header: 'Receipt', size: 100 },
            { accessorKey: 'balance', header: 'Balance', size: 100 },
        ],
        []
    );

    const fetchData = async (clientCompanyId) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.get(READ_CUSTOMER_LEDGER(clientCompanyId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const mappedData = response.data.commercialInvExps.map(item => {
                const totalAmount = item.particulars.reduce((sum, particular) => {
                    return sum + parseFloat(particular.amount);
                }, 0);
                const productNames = item.particulars.slice(0, 5)
                    .map(particular => particular.products.productName)
                    .join(", ");
                return {
                    commercialInvExpId: item.commercialInvExpId,
                    proformaInvoiceNo: item.proformaInvoiceNo,
                    productName: productNames,
                    date: item.date,
                    ciNo: item.ciNo,
                    particulars: item.particulars,
                    invoiceAmount: totalAmount.toFixed(2),
                    receipt: item.receipt,
                    balance: item.balance,
                    shipmentId: item.importShipment?.shipmentId,
                };
            });
            setData(mappedData);
        } catch (err) {
            setError(err.message);
            setData([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!clientCompanyId) return;
        setLoading(true);
        fetchData(clientCompanyId);
    }, [clientCompanyId, token]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Box sx={{ padding: "10px" }}>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </Box>
    );
};

export default CustomerledgerView;
