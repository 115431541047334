import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_IMPORT_SHIPPING_MARK, READ_IMPORT_SHIPPING_MARK } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";

const ValidationSchema = Yup.object().shape({
    batchNo: Yup.string().required('Batch Number is required'),
    productId: Yup.number().required('Product is required'),
    shippingMark: Yup.string().required('Shipping Mark is required'),
});


const UpdateExpShippingMark = ({ handleClose, setMessage, fetchData, shippingMarkId, productsList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!shippingMarkId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPPING_MARK(shippingMarkId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.shipment);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shippingMarkId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_IMPORT_SHIPPING_MARK(shippingMarkId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipping Mark updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    batchNo: data?.batchNo || '',
                    productId: data?.productId || '',
                    shippingMark: data?.shippingMark || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Shipping Mark
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="batchNo"
                                    fieldLabel="Batch Number"
                                    value={values.batchNo}
                                    onChange={handleChange}
                                    error={touched.batchNo && !!errors.batchNo}
                                    helperText={touched.batchNo && errors.batchNo}
                                    placeholder="Enter Batch Number"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={productsList}
                                    fieldLabel="Select Product"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={productsList.find((option) => option.id === values.productId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('productId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Product"
                                    error={touched.productId && !!errors.productId}
                                    helperText={touched.productId && errors.productId}
                                />
                                {touched.productId && errors.productId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.productId}</p> : null}
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextarea
                                    name="shippingMark"
                                    fieldLabel="Shipping Mark"
                                    value={values.shippingMark}
                                    onChange={handleChange}
                                    error={touched.shippingMark && !!errors.shippingMark}
                                    helperText={touched.shippingMark && errors.shippingMark}
                                    placeholder="Enter Shipping Mark"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateExpShippingMark;
