import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import { BUSINESS_GROUP, CREATE_BUSINESS_GROUP } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import CustomModal from "../common/CustomModal";
import UpdateBusinessGroup from "./UpdateMaster/UpdateBusinessGroup";

const title = "Business Group";

const ValidationSchema = Yup.object().shape({
    shortName: Yup.string().required('shortName is required'),
    businessGroupName: Yup.string().required('businessGroupName is required'),
});

const BusinessGroup = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "businessGroupId", header: "SN.", size: 50 },
            { accessorKey: "businessGroupName", header: "Business Group Name", size: 150 },
            { accessorKey: "shortName", header: "Short Name", size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <IconButton onClick={() => handleUpdateClick(row.original.businessGroupId)}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </>
                ),
            },
        ],
        []
    );

    const handleUpdateClick = (businessGroupId) => {
        setSelectedId(businessGroupId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: BUSINESS_GROUP });
            if (response.data) {
                const CustumData = response.data.businessGroups.map((data, index) => ({
                    ...data,
                    businessGroupId: data.businessGroupId || '',
                    businessGroupName: data.businessGroupName || '',

                }));
                setData(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                shortName: values.shortName,
                businessGroupName: values.businessGroupName,
            };
            const response = await genericPost(CREATE_BUSINESS_GROUP, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        businessGroupName: '',
        shortName: '',

    });


    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <Box sx={{ paddingLeft: "20px", paddingRight: "10px", paddingTop: "10px" }} >
                    <Formik
                        initialValues={formData}
                        validationSchema={ValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                            <Form>
                                <Grid container spacing={2} sx={{ px: "0%" }}>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Business Group Name"
                                            name="businessGroupName"
                                            fieldLabel="Business Group Name"
                                            required
                                            value={values.businessGroupName}
                                            onChange={handleChange}
                                            error={touched.businessGroupName && !!errors.businessGroupName}
                                            helperText={touched.businessGroupName && errors.businessGroupName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <CustomTextField
                                            placeholder="Enter Short Name"
                                            name="shortName"
                                            fieldLabel="Short Name"
                                            required
                                            value={values.shortName}
                                            onChange={handleChange}
                                            error={touched.shortName && !!errors.shortName}
                                            helperText={touched.shortName && errors.shortName}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ pt: 3 }}>
                                            <CustomButton
                                                startIcon={<SaveRounded />}
                                                title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    handleOpen={handleOpen}
                />
                <CustomModal
                    modalStyles={{
                        maxHeight: '35vh',
                        top: "10%",
                    }}
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateBusinessGroup
                        handleClose={handleCloseUpdate}
                        businessGroupId={selectedId}
                        fetchData={fetchData}
                        setMessage={setMessage}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

            </Box>
        </>
    );
};
export default BusinessGroup;

