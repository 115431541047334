import React from 'react';
import {
    TextField,
    FormLabel,
    InputAdornment,
    Box,
    FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        height: '35px',
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        },
    },
    '& .MuiInputBase-input': {
        padding: '0 0',
        paddingLeft: '0px',
    },
    '& .MuiInputAdornment-root': {
        paddingLeft: '0px !important',
    },
}));

const CustomPrefixSuffixField = ({
    name,
    value,
    onChange,
    fieldLabel,
    required,
    helperText,
    placeholder,
    type = 'text',
    readOnly = false,
    prefix,
    suffix,
    ...props
}) => {
    return (
        <Box style={{ marginBottom: '1px' }}>
            <FormLabel style={fieldLabelstyle}>{fieldLabel}</FormLabel>
            <StyledTextField
                sx={{
                    '& .MuiInputBase-root': {
                        paddingLeft: '0px',
                    },
                }}
                name={name}
                value={value || ''}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                variant="outlined"
                style={required ? { ...style, ...requiredStyle } : style}
                {...props}
                InputProps={{
                    readOnly,
                    startAdornment: prefix && (
                        <InputAdornment position="start">{prefix}</InputAdornment>
                    ),
                    endAdornment: suffix && (
                        <InputAdornment position="end">{suffix}</InputAdornment>
                    ),
                }}
            />
            <FormHelperText error={true} style={helperTextStyle}>
                {helperText}
            </FormHelperText>
        </Box>
    );
};

export default CustomPrefixSuffixField;

const style = {
    width: '100%',
    border: '1px solid #CED4DA',
    borderRadius: '4px',
    paddingLeft: '10px',
    height: '35px',
    marginTop: '4px',
    fontFamily: 'Poppins, sans-serif !important',
    color: '#495057',
    fontSize: '14px',
    marginBottom: '3px',
};

const requiredStyle = {
    borderLeft: '2px solid #F27B77',
};

const fieldLabelstyle = {
    color: '#212529',
    fontWeight: '600',
    fontSize: '14px',
};

const helperTextStyle = {
    fontFamily: 'Poppins, sans-serif !important',
    fontSize: '12px',
    marginTop: 0,
};
