// ExportPaymentReportView.js
import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, Stack, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { genericPost } from '../../services/authServices';
import { LIST_PURCHASE_ORDERS, READ_IMPORT_SHIPMENT } from '../../services/apiRoutes';
import RenderPurchaseOrderTable from './renderPurchaseOrderTable';
import RenderCommercial from './renderCommercial';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    })
);

export default function ExportPaymentReportView() {
    const { importShipmentId } = useParams();
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [purchaseOrderData, setPurchaseOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeTable, setActiveTable] = useState('purchase');

    const theme = useTheme();
    const [open, setOpen] = useState(true);


    useEffect(() => {
        const fetchShipmentDetails = async () => {
            setLoading(true);
            try {
                const authToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPMENT(importShipmentId), {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setShipmentDetails(response.data.shipment);
            } catch (error) {
                console.error('Error fetching shipment details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchShipmentDetails();
    }, [importShipmentId]);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleTableChange = (table) => setActiveTable(table);

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Typography variant="h6">Shipment Details {shipmentDetails && shipmentDetails.supplierId}</Typography>
                    <Typography variant="body2">Company: {shipmentDetails && shipmentDetails.company.companyName}</Typography>
                </CardContent>
            </Card>
            <Box sx={{ display: 'flex', marginTop: "150px" }}>
                <MuiAppBar position="fixed" open={open} style={{ backgroundColor: "#fff", marginTop: "150px" }}>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                            <MenuIcon />
                        </IconButton>
                        <Stack spacing={2} direction="row">
                            <Button variant="outlined" onClick={() => handleTableChange('purchase')}>Purchase Order</Button>
                            <Button variant="outlined" onClick={() => handleTableChange('commercial')}>Commercial Invoice</Button>
                        </Stack>
                    </Toolbar>
                </MuiAppBar>
                <Main open={open}>
                    <Typography paragraph>
                        {activeTable === 'purchase' && <RenderPurchaseOrderTable shipmentDetails={shipmentDetails} purchaseOrderData={purchaseOrderData} />}
                        {activeTable === 'commercial' && <RenderCommercial shipmentDetails={shipmentDetails} purchaseOrderData={purchaseOrderData} />}
                    </Typography>
                </Main>
            </Box>
        </>
    );
}
