import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_IMPORT_SHIPMENT_REPORT } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import { formatDate } from "../../services/CommonServices";
import CustomLoader from "../common/CustomLoader";
import { date } from "yup";
const title = "Import Shipment Report";

const ImportShipmentReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'commercialInvoiceId', header: 'SN.', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 50 },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 50 },
            { accessorKey: 'clientCompanyName', header: 'Supplier Name', size: 50 },
            { accessorKey: 'mailingCountry', header: 'Country', size: 50 },
            { accessorKey: 'amount', header: 'Amount', size: 50 },
            { accessorKey: 'noOfContainers', header: 'No. of Containers', size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_IMPORT_SHIPMENT_REPORT });
            if (response.data.success && response.data.shipments) {
                const shipmentData = response.data.shipments.map((shipment) => {
                    // Calculate the total amount from particulars
                    const totalAmount = shipment.particulars?.reduce((acc, particular) => {
                        return acc + parseFloat(particular.amount || 0);
                    }, 0) || 0;

                    return {
                        commercialInvoiceId: shipment.commercialInvoiceId || '',
                        date: shipment.date || '',
                        shipmentId: shipment.importShipment?.shipmentId || '',
                        noOfContainers: shipment.importShipment?.noOfContainers || '',
                        clientCompanyName: shipment.importShipment?.supplier?.clientCompanyName || '',
                        mailingCountry: shipment.importShipment?.supplier?.mailingCountry?.name || '',
                        amount: totalAmount.toFixed(2),
                    };
                });
                setData(shipmentData);
            } else {
                setError('No import shipments found');
            }
        } catch (error) {
            setError('Error fetching import shipments');
            console.error('Error fetching import shipments', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default ImportShipmentReport;
