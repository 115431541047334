//  import React from 'react';
// import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper } from '@mui/material';

// function RenderCommercial({ shipmentDetails, purchaseOrderData }) {
//     return (
//         <TableContainer component={Paper}>
//             <Table sx={{ minWidth: 650 }} aria-label="commercial invoice table">
//                 <TableHead>
//                     <TableRow>
//                         <TableCell>Commercial Invoice ID {shipmentDetails.company.companyName}</TableCell>
//                         <TableCell align="right">Date</TableCell>
//                         <TableCell align="right">Supplier</TableCell>
//                         <TableCell align="right">Amount</TableCell>
//                         <TableCell align="right">Due Date</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {purchaseOrderData.map((row) => (
//                         <TableRow key={row.purchaseOrderId}>
//                             <TableCell component="th" scope="row">{row.purchaseOrderId}</TableCell>
//                             <TableCell align="right">{row.date}</TableCell>
//                             <TableCell align="right">{row.supplier}</TableCell>
//                             <TableCell align="right">{row.totalAmount}</TableCell>
//                             <TableCell align="right">{row.dueDate}</TableCell>
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     );
// }

// export default RenderCommercial;
// CommercialInvoiceImport.js


import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//  import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
//  import CustomModal from "../../common/CustomModal";
// import CreateCommercialInvoice from "../CreateForm/CreateCommercialInvoice";
// import { GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_COMMERCIAL_INVOICES } from '../../../services/apiRoutes';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import MessageBox from '../../common/CustomMessageBox';
// import UpdateCommercialInvoice from '../UpdateForm/UpdateCommercialInvoice';
import axios from 'axios';
import DetailsPageCard from '../common/DetailsPage/DetailsPageCard';
import CustomModal from '../common/CustomModal';
import CreateCommercialInvoice from '../importshipement/CreateForm/CreateCommercialInvoice';
import UpdateCommercialInvoice from '../importshipement/UpdateForm/UpdateCommercialInvoice';
import CommercialInvoiceView from '../exportshipement/ViewDetails/CommercialInvoiceView';
import MessageBox from '../common/CustomMessageBox';
import { GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_COMMERCIAL_INVOICES } from '../../services/apiRoutes';
// import { genericPost } from '../../../services/authServices';
// import CommercialInvoiceView from '../ViewDetails/CommercialInvoiceView';
import { genericPost } from '../../services/authServices';
import { LIST_PURCHASE_ORDERS } from '../../services/apiRoutes';


// export default function CommercialInvoiceImport({ shipmentDetails }) {
function RenderCommercial({ shipmentDetails, }) {
    const { importShipmentId } = useParams();
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Commercial Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    // const { shipmentDetails } = useShipmentDetails(importShipmentId);
    const [products, setProducts] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);

    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [commercialInvoiceId, setCommercialInvoiceId] = useState('');

    let supplierId, cifFob, currency;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            currency = shipmentDetails.currency.currency;
            fetchData();
            fetchProductsList();
            getSuppliersList();
            hasFetched.current = true;

        }
    }, [shipmentDetails, commercialInvoiceId, leadModal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                supplierNamesId: supplierId
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES, payload);

            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice) => {
                    const totalQty = invoice.particulars.reduce((acc, item) => acc + item.qty, 0);
                    const totalAmount = invoice.particulars.reduce((acc, item) => acc + (item.qty * parseFloat(item.rate)), 0);
                    return {
                        commercialInvoiceId: invoice.commercialInvoiceId || '',
                        reference: invoice.reference || '',
                        proformaInvoiceNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        upload: invoice.ciUpload || '',
                        currencyName: currency || '',
                        totalQty,
                        totalAmount: totalAmount.toFixed(2),
                        particulars: invoice.particulars.map(({
                            particularName: name = '',
                            qty = '',
                            rate = '',
                            unit = '',
                            description = ''
                        }) => ({
                            name, qty, rate, unit, description
                        }))
                    };
                });

                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };



    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: supplierId,

            };

            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });

            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };


    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }


    const handleViewClick = (id) => {
        handleOpenView(id);
    };



    const handleUpdateClick = (id) => {
        setCommercialInvoiceId(id);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setCommercialInvoiceId('');
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };

    const handleOpenView = (id) => {
        setCommercialInvoiceId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setCommercialInvoiceId('');
    };


    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const supplierIdName = shipmentDetails.supplierId;
    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';


    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const commercialInvoiceColumns = [
        { id: 'commercialInvoiceId', label: 'SN', accessor: 'commercialInvoiceId' },
        { id: 'proformaInvoiceNo', label: 'CI No.', accessor: 'proformaInvoiceNo' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        {
            id: 'totalQty', label: 'Qty', accessor: 'totalQty', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'unit', label: 'Unit', accessor: 'unit' },
        { id: 'rate', label: 'Rate', accessor: 'rate' },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        { id: 'currencyName', label: 'Currency', accessor: 'currencyName' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.commercialInvoiceId)} style={{ marginRight: '8px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.commercialInvoiceId)} style={{ marginRight: '8px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.commercialInvoiceId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <>
            <div>
                <DetailsPageCard
                    columns={commercialInvoiceColumns}
                    title={'Commercial Invoice'}
                    buttonTitle={'Create Commercial Invoice'}
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={leadModal} handleClose={handleClose}>
                    <CreateCommercialInvoice
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleClose}
                        suppliersList={suppliersList}
                        productsList={products}
                        supplierIdName={supplierIdName}
                        importShipmentId={importShipmentId}
                    />
                </CustomModal>
                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateCommercialInvoice
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        commercialInvoiceId={commercialInvoiceId}
                        suppliersList={suppliersList}
                        productsList={products}

                    />
                </CustomModal>
                <CustomModal open={viewModal} handleClose={handleCloseView}>
                    <CommercialInvoiceView
                        fetchData={fetchData}
                        handleClose={handleCloseView}
                        commercialInvoiceId={commercialInvoiceId}
                        suppliersList={suppliersList}
                        productsList={products}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </div>
        </>
    );
}

export default RenderCommercial;