import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomSetting from '../common/CustomSetting';
import { GET_COMPANY } from '../../services/apiRoutes';
import { genericGet } from '../../services/apiServices';
import { AuthContext } from '../../context/AuthContext';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Typography, IconButton, Avatar, Link, Table, TableBody, TableRow, TableCell } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MailIcon from '@mui/icons-material/Mail';
import CustomButton from '../common/CustomButton';
import CustomLoader from '../common/CustomLoader';

export const PersonalSettings = () => {
    return (
        <CustomSetting
            defaultView="personalSettings"
            onViewChange={(view) => console.log('View changed to:', view)}
        />
    );
};

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
};

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const userDetails = {
    name: "Deepali Pawar",
    role: "Sales Executive",
    email: "cnd1.kg@khetigaadi.in",
    phone: "9158075833",
    company: "SAR Agro Industries Private Limited.",
    profileImage: "/images/user-thumbnail.png",
};

const userInfo = [
    { label: 'First Name', value: 'Deepali' },
    { label: 'Last Name', value: 'Pawar' },
    { label: 'Email', value: 'cnd1.kg@khetigaadi.in' },
    { label: 'Role', value: 'Sales Executive' },
    { label: 'Phone', value: '9158075833' },
];

export const PersonalDetails = () => {
    const [value, setValue] = useState(0); // Added state for Tabs
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [loading, setLoading] = useState(false)

    const { token } = useContext(AuthContext);

    const handleChange = (event, newValue) => {
        setValue(newValue); // Handle tab changes
    };

    const fetchCompanies = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY });
            const companyData = response?.data?.companies || [];
            const defaultCompanies = companyData.filter((company) => company.isDefault);

            const defaultCompany = defaultCompanies[0] || null;
            const getCompanyId = defaultCompany?.companyId || null;
            setCompanies(defaultCompanies);
            setCompanyId(getCompanyId);
            setLoading(false)

        } catch (error) {
            console.error('Error fetching companies:', error);
            setLoading(false)

        }
    };

    useEffect(() => {
        fetchCompanies();
    }, [token]);


    return (
        <div>
            <CustomLoader open={loading} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="User Details"
                            {...a11yProps(0)}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: value === 0 ? '#007BFF' : 'inherit',
                            }}
                        />
                        <Tab
                            label="Company Details"
                            {...a11yProps(1)}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                color: value === 1 ? '#007BFF' : 'inherit',
                            }}
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    {/* {companies.map((company) => (
                        <p key={company.companyId}> {company.companyName}</p>
                    ))} */}
                    {companies.map((company) => (
                        <Box sx={{ p: 1, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item>
                                        <Box textAlign="center">
                                            <Avatar src={userDetails.profileImage} alt="user-profile" sx={{ width: 100, height: 100, mx: 'auto' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Box display="flex" alignItems="center" mb={1}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ m: 0, fontSize: "18px", fontWeight: "bold" }}> {company.user.firstName} {company.user.lastName} </Typography>
                                            <CustomButton
                                                title={userDetails.role}
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    color: "#007BFF", textTransform: "capitalize", borderColor: '#007BFF',
                                                    marginLeft: "10px"
                                                }}
                                            />
                                        </Box>
                                        <Box mb={1}>
                                            <Link variant="body2" color="textSecondary" sx={{ fontSize: "15px" }}>
                                                {userDetails.role} at {company.companyName}
                                            </Link>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <MailIcon fontSize="small" sx={{ mr: 1 }} />
                                            {company.user.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>User Information</Typography>
                                <Grid container sx={{ pl: 10 }}>
                                    <Grid item xs={12}>
                                        <Table size="small" sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}>
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell width="120" sx={{ color: 'text.secondary', fontSize: "14px" }}> User Name</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>{company.user.firstName} {company.user.lastName}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="120" sx={{ color: 'text.secondary', fontSize: "14px" }}>Email</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.user.email}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="120" sx={{ color: 'text.secondary', fontSize: "14px" }}>Mobile</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.user.mobile}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="120" sx={{ color: 'text.secondary', fontSize: "14px" }}>User Roles</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {userDetails.role} </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {companies.map((company) => (
                        <Box sx={{ p: 1, }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item>
                                        <Box textAlign="center">
                                            <Avatar src={userDetails.profileImage} alt="user-profile" sx={{ width: 100, height: 100, mx: 'auto' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs>
                                        <Box display="flex" alignItems="center" mb={1}>
                                            <Typography variant="h6" fontWeight="bold" sx={{ m: 0, fontSize: "18px", fontWeight: "bold" }}> {company.companyName}   </Typography>
                                            <CustomButton
                                                title="Update Company"
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    color: "#007BFF", textTransform: "capitalize", borderColor: '#007BFF',
                                                    marginLeft: "10px"
                                                }}
                                            />
                                        </Box>
                                        <Box mb={1}>
                                            <Link variant="body2" color="textSecondary" sx={{ fontSize: "15px" }}>
                                                {company.website}
                                            </Link>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <MailIcon fontSize="small" sx={{ mr: 1 }} />
                                            {company.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box my={2}>
                                <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>Company Information</Typography>
                                <Grid container sx={{ pl: 10 }}>
                                    <Grid item xs={12}>
                                        <Table size="small" sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}>
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Company Name</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>  {company.companyName}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Email</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.email}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Mobile</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.mobile}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>financial Year Start</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.financialYearStart}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>financial Year End</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}> {company.financialYearEnd}</TableCell>
                                                </TableRow>
                                                <TableRow >
                                                    <TableCell width="180" sx={{ color: 'text.secondary', fontSize: "14px", textTransform: "capitalize" }}>Address</TableCell>
                                                    <TableCell sx={{ fontSize: "14px" }}>
                                                        {company?.address ?? ''}, {company?.city?.name ?? ''}, {company?.state?.name ?? ''}, {company?.country?.name ?? ''} ({company?.pincode ?? ''}).
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))}
                </CustomTabPanel>
            </Box>
        </div>
    );
};

