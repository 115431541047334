import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Card, Grid, Typography, Divider, Container } from "@mui/material";
import CustomButton from "./CustomButton";
import {
  ClearRounded,
  SaveRounded,
  Replay10Rounded,
} from "@mui/icons-material";

const cardStyle = {
  marginBottom: '10px',
  paddingRight: '20px',
  paddingLeft: '20px',

};



const CustomModal = ({ open, children, handleClose, modalStyles }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      width={"xl"}
    >
      <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <Box sx={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 0%)",
          width: "70%",
          bgcolor: "background.paper",
          border: 0,
          boxShadow: 24,
          maxHeight: "calc(100vh - 20px)",
          overflowY: "auto",
          borderRadius: "5px",
          height: "100vh",

          ...modalStyles
        }}>

          <Box style={cardStyle}> {children}</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomModal;
