import React from 'react';
import { Backdrop, CircularProgress, Box } from '@mui/material';

const CustomLoader = ({ open }) => {
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        // backdropFilter: 'blur(5px)', // Apply blur to the background
      }}
      invisible={true}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{
          color: '#007BFF',
        }} />
      </Box>
    </Backdrop>
  );
};

export default CustomLoader;
