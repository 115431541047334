// Import necessary libraries and functions
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, Typography } from '@mui/material';
import { genericPost } from '../../services/authServices';
import { LIST_PURCHASE_ORDERS } from '../../services/apiRoutes';
import PurchaseOrder from '../importshipement/ImportMenu/PurchaseOrder';

function RenderPurchaseOrderTable({ shipmentDetails }) {
    const [purchaseOrderData, setPurchaseOrderData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Adjust payload if necessary
                const payload = { supplierNamesId: shipmentDetails?.supplierId };
                const response = await genericPost(LIST_PURCHASE_ORDERS, payload, {});

                if (response.data.success) {
                    const fetchedPurchaseOrders = response.data.purchaseOrders.map((order) => ({
                        ...order,
                        totalAmount: order.particulars.reduce((total, { qty, rate }) => {
                            const parsedQty = Number(qty) || 0;
                            const parsedRate = parseFloat(rate) || 0;
                            return total + (parsedQty * parsedRate);
                        }, 0),
                    }));
                    setPurchaseOrderData(fetchedPurchaseOrders);
                } else {
                    setError('No purchase orders found');
                }
            } catch (error) {
                setError('Error fetching purchase orders');
                console.error('Error fetching purchase orders', error);
            } finally {
                setLoading(false);
            }
        };

        if (shipmentDetails && shipmentDetails.supplierId) {
            fetchData();
        }
    }, [shipmentDetails]); // Dependency array to re-run if shipmentDetails changes

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <>

            {/* <PurchaseOrder /> */}
            <Typography>Company Name Data Import from Parent Component: {shipmentDetails?.company?.companyName}</Typography>
            <Typography variant="body2">ID: {shipmentDetails?.shipmentId}</Typography>
            <Typography variant="body2">Company: {shipmentDetails?.company?.companyName}</Typography>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="purchase order table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Supplier</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Due Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseOrderData.map((row) => (
                            <TableRow key={row.purchaseOrderId}>
                                <TableCell component="th" scope="row">{row.purchaseOrderId}</TableCell>
                                <TableCell align="right">{row.date}</TableCell>
                                <TableCell align="right">{row.supplier?.clientCompanyName || 'N/A'}</TableCell>
                                <TableCell align="right">{row.totalAmount.toFixed(2)}</TableCell>
                                <TableCell align="right">{row.dueDate}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default RenderPurchaseOrderTable;
