const URL = 'http://18.166.29.36'

// const URL = 'http://localhost:9000'

const BASE_URL = `${URL}/api`
export const LOGIN_PATH = `${BASE_URL}/user/login`
export const REGISTER_PATH = `${BASE_URL}/user/register`

//common 
export const BUSINESS_GROUP = `${BASE_URL}/common/business-groups`
export const CREATE_BUSINESS_GROUP = `${BASE_URL}/common/business-groups-create`
export const UPDATE_BUSINESS_GROUP = (businessGroupId) => `${BASE_URL}/common/business-groups/${businessGroupId}`;
export const READ_BUSINESS_GROUP = (businessGroupId) => `${BASE_URL}/common/business-groups/${businessGroupId}`;


export const INDUSTRY_TYPE = `${BASE_URL}/common/industry-types`
export const LEAD_SOURCE = `${BASE_URL}/common/lead-sources`
export const LEAD_STATUSES = `${BASE_URL}/common/lead-statuses`
export const GET_RATING = `${BASE_URL}/common/ratings`
export const GET_COMPANIES = `${BASE_URL}/common/companies`
export const GET_COUNTRIES = `${BASE_URL}/common/countries`
export const GET_STATES = `${BASE_URL}/common/countries`
export const GET_CITY = `${BASE_URL}/common/states`
export const GET_TITLES = `${BASE_URL}/common/titles`

export const GET_COMPANY_TYPES = `${BASE_URL}/common/company-types`
export const GET_COMPANY_OWNERSHIP_TYPES = `${BASE_URL}/common/ownership-types`
export const GET_CALL_PURPOSE_TYPES = `${BASE_URL}/common/call-purpose-types`
export const GET_CALL_OUTCOME_TYPES = `${BASE_URL}/common/call-outcome-types`
export const GET_PRIORITIES_LOSS_REASONS = `${BASE_URL}/common/loss-reasons`
export const CREATE_PRIORITIES_LOSS_REASONS = `${BASE_URL}/common/loss-reasons-create`
export const UPDATE_PRIORITIES_LOSS_REASONS = (lossReasonId) => `${BASE_URL}/common/loss-reasons/${lossReasonId}`;
export const READ_PRIORITIES_LOSS_REASONS = (lossReasonId) => `${BASE_URL}/common/loss-reasons/${lossReasonId}`;


export const GET_COMPANY_MODULES = `${BASE_URL}/common/company-modules`
export const GET_CONTACT_DEPARTMENTS = `${BASE_URL}/common/contact-departments`
export const GET_CONTACT_GROUPS = `${BASE_URL}/common/contact-groups`
export const CREATE_CONTACT_GROUPS = `${BASE_URL}/common/contact-groups-create`
export const UPDATE_CONTACT_GROUPS = (contactGroupId) => `${BASE_URL}/common/contact-groups/${contactGroupId}`;
export const READ_CONTACT_GROUPS = (contactGroupId) => `${BASE_URL}/common/contact-groups/${contactGroupId}`;


export const GET_CONTACT_TYPES = `${BASE_URL}/common/contact-types`
export const CREATE_CONTACT_TYPES = `${BASE_URL}/common/contact-types-create`
export const UPDATE_CONTACT_TYPES = (contactTypeId) => `${BASE_URL}/common/contact-types/${contactTypeId}`;
export const READ_CONTACT_TYPES = (contactTypeId) => `${BASE_URL}/common/contact-types/${contactTypeId}`;



export const GET_MODULES = `${BASE_URL}/common/modules`
export const GET_TASK_STATUSES = `${BASE_URL}/common/task-statuses`
export const CREATE_TASK_STATUSES = `${BASE_URL}/common/task-statuses-create`
export const UPDATE_TASK_STATUSES = (taskStatusId) => `${BASE_URL}/common/task-statuses/${taskStatusId}`;
export const READ_TASK_STATUSES = (taskStatusId) => `${BASE_URL}/common/task-statuses/${taskStatusId}`;


export const GET_LEAD_STAGES = `${BASE_URL}/common/lead-stages`
export const GET_PRIORITIES = `${BASE_URL}/common/priorities`
export const CREATE_PRIORITIES = `${BASE_URL}/common/priorities-create`
export const UPDATE_PRIORITIES = (priorityId) => `${BASE_URL}/common/priorities/${priorityId}`;
export const READ_PRIORITIES = (priorityId) => `${BASE_URL}/common/priorities/${priorityId}`;


export const GET_CIF_FOB_LIST = `${BASE_URL}/common/cif-fob-list`;
export const GET_SHIPMENT_TYPES = `${BASE_URL}/common/shipment-types`;
export const GET_CURRENCY_LIST = `${BASE_URL}/common/currency-list`;
export const GET_SUPPLIERS_LIST = `${BASE_URL}/common/suppliers-list`;
export const GET_PRODUCTS_LIST = `${BASE_URL}/common/products-list`;
export const CREATE_PRODUCTS_LIST = `${BASE_URL}/common/products-list/create`;
export const UPDATE_PRODUCTS_LIST = (productsId) => `${BASE_URL}/common/products-list/${productsId}`;
export const READ_PRODUCTS_LIST = (productsId) => `${BASE_URL}/common/products-list/${productsId}`;


export const GET_CONTACT_PERSON_LIST = `${BASE_URL}/common/contact-person-list`;
export const GET_LEAD_OWNER = `${BASE_URL}/common/lead-owner`;
export const GET_SHIPPING_LINE = `${BASE_URL}/common/shipping-line`;
export const CREATE_SHIPPING_LINE = `${BASE_URL}/common/shipping-line-create`;
export const GET_FCL_LIST = `${BASE_URL}/common/fcl-list`;
export const GET_DIRECT_LIST = `${BASE_URL}/common/direct-list`;
export const GET_FREIGHT_FORWARD_AGENCY_LIST = `${BASE_URL}/common/freight-forward-agency-list`;
export const CREATE_FREIGHT_FORWARD_AGENCY = `${BASE_URL}/common/freight-forward-agency-create`;
export const GET_CUSTOM_CLEARING_AGENCY_LIST = `${BASE_URL}/common/custom-clearing-agency-list`;
export const CREATE_CUSTOM_CLEARING_AGENCY = `${BASE_URL}/common/custom-clearing-agency-create`;
export const GET_PAYMENT_TYPE_LIST = `${BASE_URL}/common/payment-type-list`;
export const GET_RECEIPT_TYPE_LIST = `${BASE_URL}/common/receipt-type-list`;
export const GET_EXPENSE_LEDGER_LIST = `${BASE_URL}/common/expense-ledger-list`;
export const CREATE_EXPENSE_LEDGER = `${BASE_URL}/common/expense-ledger-create`;
export const GET_Financial_Year = `${BASE_URL}/common/financial-year`;
export const GET_FREFIX_SUFFIX = `${BASE_URL}/common/frefix-suffix`;
export const GET_SHIPPING_STATUS = `${BASE_URL}/common/shipping-status`;
export const GET_UNIT_LIST = `${BASE_URL}/common/unit-list`;
export const CREATE_UNIT_LIST = `${BASE_URL}/common/unit-list`;
export const READ_UNIT_LIST = (unitId) => `${BASE_URL}/common/unit-list/${unitId}`;
export const UPDATE_UNIT_LIST = (unitId) => `${BASE_URL}/common/unit-list/${unitId}`;
export const DELETE_UNIT_lIST = (unitId) => `${BASE_URL}/common/unit-list/${unitId}`;
export const GET_UNIT_QUALITY_CODE = `${BASE_URL}/common/unit-quality-code`;
export const GET_VOUCHER = `${BASE_URL}/common/voucher`;
export const GET_TERM_CONDITIONS = `${BASE_URL}/common/term-conditios`;
export const CREATE_TERM_CONDITIONS = `${BASE_URL}/common/term-conditios-create`;
export const READ_TERM_CONDITIONS = (id) => `${BASE_URL}/common/term-conditios/${id}`;
export const DELETE_TERM_CONDITIONS = (id) => `${BASE_URL}/common/term-conditios/${id}`;
export const UPDATE_TERM_CONDITIONS = (id) => `${BASE_URL}/common/term-conditios/${id}`;

// Lead API
export const GET_LEADS = `${BASE_URL}/leads/get`
export const Creact_LEADS = `${BASE_URL}/leads/create`
export const GET_Lead_leadId = (leadId) => `${BASE_URL}/leads/${leadId}`;
export const Update_Lead_leadId = (leadId) => `${BASE_URL}/leads/${leadId}`;
export const Delete_Lead_leadId = (leadId) => `${BASE_URL}/leads/${leadId}`;
// Company API
export const CreateMyCompany = `${BASE_URL}/company/create`
export const GET_COMPANY = `${BASE_URL}/company/get`
export const GET_COMPANY_id = (id) => `${BASE_URL}/company/${id}`;
export const UPDATE_COMPANY = (companyId) => `${BASE_URL}/company/${companyId}`;
export const SET_DEFAULT_COMPANY = `${BASE_URL}/company/set/default`;

// contact API
export const GET_contact = `${BASE_URL}/contact/get`
export const Create_contact = `${BASE_URL}/contact/create`
export const GET_contactId = (contactId) => `${BASE_URL}/contact/${contactId}`;
export const Delete_Contact_contactId = (contactId) => `${BASE_URL}/contact/${contactId}`;
export const Update_contacts = (contactId) => `${BASE_URL}/contact/${contactId}`;


// client company API
export const GET_clientCompanies = `${BASE_URL}/client-company/get`
export const Delete_clientCompanies = (clientCompanyId) => `${BASE_URL}/client-company/${clientCompanyId}`;
export const Create_clientCompanies = `${BASE_URL}/client-company/create`
export const Update_clientCompanyId = (clientCompanyId) => `${BASE_URL}/client-company/${clientCompanyId}`;
export const GET_clientCompany_Id = (clientCompanyId) => `${BASE_URL}/client-company/${clientCompanyId}`;



// client company  Tax API
export const GET_clientCompanies_tax = `${BASE_URL}/client-company/tax/get`
export const Delete_clientCompanies_tax = (companyTaxId) => `${BASE_URL}/client-company/tax/${companyTaxId}`;
export const Create_clientCompanies_tax = `${BASE_URL}/client-company/tax/create`
export const Update_clientCompany_tax = (companyTaxId) => `${BASE_URL}/client-company/tax/${companyTaxId}`;
export const GET_clientCompany_tax = (companyTaxId) => `${BASE_URL}/client-company/tax/${companyTaxId}`;
export const GET_clientCompany_TaxId = (clientCompanyId) => `${BASE_URL}/client-company/tax/client/${clientCompanyId}`;

// calls
export const GET_call = `${BASE_URL}/call/get`
export const Create_call = `${BASE_URL}/call/create`
export const GET_call_id = (callId) => `${BASE_URL}/call/${callId}`;
export const Update_call_id = (callId) => `${BASE_URL}/call/${callId}`;
export const Delete_call_id = (callId) => `${BASE_URL}/call/${callId}`;

// Tasks
export const GET_task = `${BASE_URL}/task/get`
export const Create_task = `${BASE_URL}/task/create`
export const Get_task_taskId = (taskId) => `${BASE_URL}/task/${taskId}`;
export const Update_task = (taskId) => `${BASE_URL}/task/${taskId}`;
export const Delete_task = (taskId) => `${BASE_URL}/task/${taskId}`;

// Import Shipment  
export const CREATE_IMPORT_SHIPMENT = `${BASE_URL}/import-shipment/create`;
export const UPDATE_IMPORT_SHIPMENT = (importShipmentId) => `${BASE_URL}/import-shipment/${importShipmentId}`;
export const DELETE_IMPORT_SHIPMENT = (importShipmentId) => `${BASE_URL}/import-shipment/${importShipmentId}`;
export const READ_IMPORT_SHIPMENT = (importShipmentId) => `${BASE_URL}/import-shipment/${importShipmentId}`;
export const LIST_ALL_IMPORT_SHIPMENT = `${BASE_URL}/import-shipment/get`;
export const IMPORT_SHIPMENT_BUTTON_LIST = `${BASE_URL}/import-shipment/button/get`;




// Export shipment
export const CREATE_EXPORT_SHIPMENT = `${BASE_URL}/export-shipment/create`;
export const UPDATE_EXPORT_SHIPMENT = (exportShipmentId) => `${BASE_URL}/export-shipment/${exportShipmentId}`;
export const DELETE_EXPORT_SHIPMENT = (exportShipmentId) => `${BASE_URL}/export-shipment/${exportShipmentId}`;
export const READ_EXPORT_SHIPMENT = (exportShipmentId) => `${BASE_URL}/export-shipment/${exportShipmentId}`;
export const LIST_ALL_EXPORT_SHIPMENT = `${BASE_URL}/export-shipment/get`;
export const EXPORT_SHIPMENT_BUTTON_LIST = `${BASE_URL}/export-shipment/button/get`;

// Import Shipping Mark
export const CREATE_IMPORT_SHIPPING_MARK = `${BASE_URL}/import-shipment/shipping-marks/create`;
export const LIST_IMPORT_SHIPPING_MARKS = `${BASE_URL}/import-shipment/shipping-marks/get`;
export const READ_IMPORT_SHIPPING_MARK = (shippingMarkId) => `${BASE_URL}/import-shipment/shipping-marks/${shippingMarkId}`;
export const DELETE_IMPORT_SHIPPING_MARK = (shippingMarkId) => `${BASE_URL}/import-shipment/shipping-marks/${shippingMarkId}`;
export const UPDATE_IMPORT_SHIPPING_MARK = (shippingMarkId) => `${BASE_URL}/import-shipment/shipping-marks/${shippingMarkId}`;

// Import shipping tracker
export const CREATE_SHIPPING_TRACKER = `${BASE_URL}/import-shipment/shipping-tracker/create`;
export const LIST_SHIPPING_TRACKER = `${BASE_URL}/import-shipment/shipping-tracker/get`;



// Import Payment Voucher
export const CREATE_IMPORT_PAYMENT_VOUCHER = `${BASE_URL}/import-shipment/payment-voucher/create`;
export const LIST_IMPORT_PAYMENT_VOUCHERS = `${BASE_URL}/import-shipment/payment-voucher/get`;
export const READ_IMPORT_PAYMENT_VOUCHER = (paymentVoucherId) => `${BASE_URL}/import-shipment/payment-voucher/${paymentVoucherId}`;
export const DELETE_IMPORT_PAYMENT_VOUCHER = (paymentVoucherId) => `${BASE_URL}/import-shipment/payment-voucher/${paymentVoucherId}`;
export const UPDATE_IMPORT_PAYMENT_VOUCHER = (paymentVoucherId) => `${BASE_URL}/import-shipment/payment-voucher/${paymentVoucherId}`;

//Import Expense Voucher
export const CREATE_EXPENSE_VOUCHER = `${BASE_URL}/import-shipment/expense-voucher/create`;
export const LIST_EXPENSE_VOUCHERS = `${BASE_URL}/import-shipment/expense-voucher/get`;
export const READ_EXPENSE_VOUCHER = (ExpenseVoucherId) => `${BASE_URL}/import-shipment/expense-voucher/${ExpenseVoucherId}`;
export const DELETE_EXPENSE_VOUCHER = (ExpenseVoucherId) => `${BASE_URL}/import-shipment/expense-voucher/${ExpenseVoucherId}`;
export const UPDATE_EXPENSE_VOUCHER = (ExpenseVoucherId) => `${BASE_URL}/import-shipment/expense-voucher/${ExpenseVoucherId}`;

// Import Shipping Booking
export const CREATE_IMPORT_SHIPPING_BOOKING = `${BASE_URL}/import-shipment/shipping-booking/create`;
export const LIST_IMPORT_SHIPPING_BOOKINGS = `${BASE_URL}/import-shipment/shipping-booking/get`;
export const READ_IMPORT_SHIPPING_BOOKING = (shippingBookingId) => `${BASE_URL}/import-shipment/shipping-booking/${shippingBookingId}`;
export const DELETE_IMPORT_SHIPPING_BOOKING = (shippingBookingId) => `${BASE_URL}/import-shipment/shipping-booking/${shippingBookingId}`;
export const UPDATE_IMPORT_SHIPPING_BOOKING = (shippingBookingId) => `${BASE_URL}/import-shipment/shipping-booking/${shippingBookingId}`;

//Import Proforma Invoice
export const LIST_PROFORMA_INVOICES = `${BASE_URL}/import-shipment/proforma-invoice/get`;
export const CREATE_PROFORMA_INVOICE = `${BASE_URL}/import-shipment/proforma-invoice/create`;
export const READ_PROFORMA_INVOICE = (proformaInvoiceId) => `${BASE_URL}/import-shipment/proforma-invoice/${proformaInvoiceId}`;
export const UPDATE_PROFORMA_INVOICE = (proformaInvoiceId) => `${BASE_URL}/import-shipment/proforma-invoice/${proformaInvoiceId}`;
export const DELETE_PROFORMA_INVOICE = (proformaInvoiceId) => `${BASE_URL}/import-shipment/proforma-invoice/${proformaInvoiceId}`;
export const DELETE_PROFORMA_INVOICE_PARTICULAR = (proformaInvoiceParticularId) => `${BASE_URL}/import-shipment/proforma-invoice-particular/${proformaInvoiceParticularId}`;


// Import Commercial Invoice
export const LIST_COMMERCIAL_INVOICES = `${BASE_URL}/import-shipment/commercial-invoice/get`;
export const CREATE_COMMERCIAL_INVOICE = `${BASE_URL}/import-shipment/commercial-invoice/create`;
export const READ_COMMERCIAL_INVOICE = (commercialInvoiceId) => `${BASE_URL}/import-shipment/commercial-invoice/${commercialInvoiceId}`;
export const UPDATE_COMMERCIAL_INVOICE = (commercialInvoiceId) => `${BASE_URL}/import-shipment/commercial-invoice/${commercialInvoiceId}`;
export const DELETE_COMMERCIAL_INVOICE_PARTICULAR = (commercialInvoiceParticularId) => `${BASE_URL}/import-shipment/commercial-invoice-particular/${commercialInvoiceParticularId}`;

// Import Purchase Orders
export const LIST_PURCHASE_ORDERS = `${BASE_URL}/import-shipment/purchase-orders/get`;
export const CREATE_PURCHASE_ORDER = `${BASE_URL}/import-shipment/purchase-orders/create`;
export const READ_PURCHASE_ORDER = (purchaseOrderId) => `${BASE_URL}/import-shipment/purchase-orders/${purchaseOrderId}`;
export const UPDATE_PURCHASE_ORDER = (purchaseOrderId) => `${BASE_URL}/import-shipment/purchase-orders/${purchaseOrderId}`;
export const DELETE_PURCHASE_ORDER = (purchaseOrderId) => `${BASE_URL}/import-shipment/purchase-orders/${purchaseOrderId}`;
export const DELETE_PURCHASE_ORDER_PARTICULAR = (purchaseOrderParticularId) => `${BASE_URL}/import-shipment/purchase-orders-particular/${purchaseOrderParticularId}`;

// Import document
export const LIST_ATTATCH_DOCUMENTS = `${BASE_URL}/import-shipment/attatch-documents/get`;




// Export Proforma Invoices
export const LIST_PROFORMA_INVOICES_EXPORT = `${BASE_URL}/export-shipment/proforma-invoice/get`;
export const CREATE_PROFORMA_INVOICE_EXPORT = `${BASE_URL}/export-shipment/proforma-invoice/create`;
export const READ_PROFORMA_INVOICE_EXPORT = (proformaInvExpId) => `${BASE_URL}/export-shipment/proforma-invoice/${proformaInvExpId}`;
export const UPDATE_PROFORMA_INVOICE_EXPORT = (proformaInvExpId) => `${BASE_URL}/export-shipment/proforma-invoice/${proformaInvExpId}`;
export const DELETE_PROFORMA_INVOICE_EXPORT = (proformaInvExpId) => `${BASE_URL}/export-shipment/proforma-invoice/${proformaInvExpId}`;
export const DELETE_PROFORMA_INVOICE_PARTICULAR_EXPORT = (proformaParticularsExpId) => `${BASE_URL}/export-shipment/proforma-invoice-particular/${proformaParticularsExpId}`;


// Export Sales Contracts
export const LIST_SALES_CONTRACTS_EXPORT = `${BASE_URL}/export-shipment/sales-contract/get`;
export const CREATE_SALES_CONTRACT_EXPORT = `${BASE_URL}/export-shipment/sales-contract/create`;
export const READ_SALES_CONTRACT_EXPORT = (salesContractId) => `${BASE_URL}/export-shipment/sales-contract/${salesContractId}`;
export const UPDATE_SALES_CONTRACT_EXPORT = (salesContractId) => `${BASE_URL}/export-shipment/sales-contract/${salesContractId}`;
export const DELETE_SALES_CONTRACT_EXPORT = (salesContractId) => `${BASE_URL}/export-shipment/sales-contract/${salesContractId}`;
export const DELETE_SALES_CONTRACT_PARTICULAR_EXPORT = (salesContractParticularsId) => `${BASE_URL}/export-shipment/sales-contract-particular/${salesContractParticularsId}`;

// Export Commercial Invoices
export const LIST_COMMERCIAL_INVOICES_EXPORT = `${BASE_URL}/export-shipment/commercial-invoice/get`;
export const CREATE_COMMERCIAL_INVOICE_EXPORT = `${BASE_URL}/export-shipment/commercial-invoice/create`;
export const READ_COMMERCIAL_INVOICE_EXPORT = (commercialInvExpId) => `${BASE_URL}/export-shipment/commercial-invoice/${commercialInvExpId}`;
export const UPDATE_COMMERCIAL_INVOICE_EXPORT = (commercialInvExpId) => `${BASE_URL}/export-shipment/commercial-invoice/${commercialInvExpId}`;
export const DELETE_COMMERCIAL_INVOICE_EXPORT = (commercialInvExpId) => `${BASE_URL}/export-shipment/commercial-invoice/${commercialInvExpId}`;
export const DELETE_COMMERCIAL_INVOICE_PARTICULAR_EXPORT = (commercialParticularsExpId) => `${BASE_URL}/export-shipment/commercial-invoice-particular/${commercialParticularsExpId}`;

// Export Receipt Vouchers
export const LIST_RECEIPT_VOUCHERS_EXPORT = `${BASE_URL}/export-shipment/receipt-voucher/get`;
export const CREATE_RECEIPT_VOUCHER_EXPORT = `${BASE_URL}/export-shipment/receipt-voucher/create`;
export const READ_RECEIPT_VOUCHER_EXPORT = (receiptVoucherId) => `${BASE_URL}/export-shipment/receipt-voucher/${receiptVoucherId}`;
export const UPDATE_RECEIPT_VOUCHER_EXPORT = (receiptVoucherId) => `${BASE_URL}/export-shipment/receipt-voucher/${receiptVoucherId}`;


// Export BL Drafts
export const LIST_BL_DRAFTS_EXPORT = `${BASE_URL}/export-shipment/bl-draft/get`;
export const CREATE_BL_DRAFT_EXPORT = `${BASE_URL}/export-shipment/bl-draft/create`;
export const READ_BL_DRAFT_EXPORT = (blDraftId) => `${BASE_URL}/export-shipment/bl-draft/${blDraftId}`;
export const UPDATE_BL_DRAFT_EXPORT = (blDraftId) => `${BASE_URL}/export-shipment/bl-draft/${blDraftId}`;
export const DELETE_BL_DRAFT_EXPORT = (blDraftId) => `${BASE_URL}/export-shipment/bl-draft/${blDraftId}`;
export const DELETE_BL_DRAFT_PARTICULAR_EXPORT = (blDraftParticularsId) => `${BASE_URL}/export-shipment/bl-draft-particular/${blDraftParticularsId}`;

// Export COA
export const LIST_COAS_EXPORT = `${BASE_URL}/export-shipment/coa/get`;
export const CREATE_COA_EXPORT = `${BASE_URL}/export-shipment/coa/create`;
export const READ_COA_EXPORT = (coaId) => `${BASE_URL}/export-shipment/coa/${coaId}`;
export const UPDATE_COA_EXPORT = (coaId) => `${BASE_URL}/export-shipment/coa/${coaId}`;
export const DELETE_COA_EXPORT = (coaId) => `${BASE_URL}/export-shipment/coa/${coaId}`;
export const DELETE_COA_PARTICULAR_EXPORT = (coaParticularsId) => `${BASE_URL}/export-shipment/coa-particular/${coaParticularsId}`;

// Export COO
export const LIST_COO_EXPORT = `${BASE_URL}/export-shipment/coo/get`;
export const CREATE_COO_EXPORT = `${BASE_URL}/export-shipment/coo/create`;
export const READ_COO_EXPORT = (cooId) => `${BASE_URL}/export-shipment/coo/${cooId}`;
export const UPDATE_COO_EXPORT = (cooId) => `${BASE_URL}/export-shipment/coo/${cooId}`;
export const DELETE_COO_EXPORT = (cooId) => `${BASE_URL}/export-shipment/coo/${cooId}`;

// Export Packing Lists
export const LIST_PACKING_LISTS_EXPORT = `${BASE_URL}/export-shipment/packing-list/get`;
export const CREATE_PACKING_LIST_EXPORT = `${BASE_URL}/export-shipment/packing-list/create`;
export const READ_PACKING_LIST_EXPORT = (packingListId) => `${BASE_URL}/export-shipment/packing-list/${packingListId}`;
export const UPDATE_PACKING_LIST_EXPORT = (packingListId) => `${BASE_URL}/export-shipment/packing-list/${packingListId}`;
export const DELETE_PACKING_LIST_EXPORT = (packingListId) => `${BASE_URL}/export-shipment/packing-list/${packingListId}`;


// Sales Order
export const LIST_SALES_ORDERS_EXPORT = `${BASE_URL}/export-shipment/sales-order/get`;
export const CREATE_SALES_ORDER_EXPORT = `${BASE_URL}/export-shipment/sales-order/create`;
export const READ_SALES_ORDER_EXPORT = (salesOrderId) => `${BASE_URL}/export-shipment/sales-order/${salesOrderId}`;
export const UPDATE_SALES_ORDER_EXPORT = (salesOrderId) => `${BASE_URL}/export-shipment/sales-order/${salesOrderId}`;
export const DELETE_SALES_ORDER_EXPORT = (salesOrderId) => `${BASE_URL}/export-shipment/sales-order/${salesOrderId}`;
export const DELETE_SALES_ORDER_PARTICULAR_EXPORT = (salesContractParticularsId) => `${BASE_URL}/export-shipment/sales-order-particular/${salesContractParticularsId}`;


// SUBSCRIPTION
export const CREATE_ORDER = `${BASE_URL}/subscription/create-order`;
export const SAVE_PAYMENT_INFO = `${BASE_URL}/subscription/save-payment-info`;
export const SAVE_ADDON_PAYMENT_INFO = `${BASE_URL}/subscription/save-addon-payment-info`;
export const SUBSCRIPTION_HISTORY = `${BASE_URL}/subscription/subscription-history`;

// Reports
export const GET_IMPORT_SHIPMENT_REPORT = `${BASE_URL}/report/import-shipment`;
export const GET_EXPORT_PROFORM_REPORT = `${BASE_URL}/report/export-proforma-invoice`;
export const GET_SALES_CONTRACT_REPORT = `${BASE_URL}/report/sales-contract-report`;
export const GET_COMMERCIAL_INVOICE_REGISTER = `${BASE_URL}/report/commercial-invoice-register`;
export const GET_CUSTOMER_LEDGER = `${BASE_URL}/report/customer-ledger`;
export const GET_SUPPLIER_LEDGER = `${BASE_URL}/report/supplier-ledger`;
export const READ_SUPPLIER_LEDGER = (supplierNamesId) => `${BASE_URL}/report/supplier-ledger/${supplierNamesId}`;
export const READ_CUSTOMER_LEDGER = (customerNamesId) => `${BASE_URL}/report/customer-ledger/${customerNamesId}`;
export const GET_IMPORT_PAYMENT_REPORT = `${BASE_URL}/report/import-payment-report`;
export const GET_EXPORT_PAYMENT_REPORT = `${BASE_URL}/report/export-payment-report`;
export const GET_BL_REPORT = `${BASE_URL}/report/bl-report`;

export const GET_FREE_DAYS_REPORT = `${BASE_URL}/report/free-days-report`;
export const GET_BATCH_WISE_IMP_SHIPMENT = `${BASE_URL}/report/batch-wise-imp-shipment`;
export const GET_EXPORT_PROFORMA_INVOICE = `${BASE_URL}/report/export-proforma-invoice`;
export const GET_BATCH_WISE_EXP_SHIPMENT = `${BASE_URL}/report/batch-wise-exp-shipment`;
export const GET_PAYMENT_REGISTER = `${BASE_URL}/report/payment-register`;
export const GET_RECEIPT_REGISTER = `${BASE_URL}/report/receipt-register`;
export const GET_IMPORT_SHIPMENT_TRACKING = `${BASE_URL}/report/import-shipment-tracking`;
export const GET_EXPORT_SHIPMENT_TRACKING = `${BASE_URL}/report/export-shipment-tracking`;
export const GET_PRODUCT_WISE_IMPORT = `${BASE_URL}/report/product-wise-import`;
export const GET_PRODUCT_WISE_EXPORT = `${BASE_URL}/report/product-wise-export`;
export const READ_PRODUCT_WISE_EXPORT = (particularName) => `${BASE_URL}/report/product-wise-export/${particularName}`;
export const READ_PRODUCT_WISE_IMPORT = (particularName) => `${BASE_URL}/report/product-wise-import/${particularName}`;
export const GET_PRODUCT_WISE_EXP_SHIPMENT = `${BASE_URL}/report/product-wise-exp-shipment`;
export const GET_IMPORT_SHIPMENT_COSTING = `${BASE_URL}/report/import-shipment-costing`;
export const READ_IMPORT_SHIPMENT_COSTING = (supplierNamesId) => `${BASE_URL}/report/import-shipment-costing/${supplierNamesId}`;
export const GET_EXPORT_SHIPMENT_COSTING = `${BASE_URL}/report/export-shipment-costing`;
export const READ_EXPORT_SHIPMENT_COSTING = (clientCompanyId) => `${BASE_URL}/report/export-shipment-costing/${clientCompanyId}`;
// export const GET_SHIPMENT_COSTING_REPORT = `${BASE_URL}/report/shipment-costing-report`;
