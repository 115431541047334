import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_BUSINESS_GROUP, READ_PRODUCTS_LIST, UPDATE_BUSINESS_GROUP, UPDATE_PRODUCTS_LIST } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';
import CustomAutocomplete from '../../common/CustomAutoComplete';

const ValidationSchema = Yup.object().shape({
    productName: Yup.string().required('Product Name is required'),
    clientCompanyId: Yup.number().required('Client Company is required'),
    hsn: Yup.string().required('HSN is required'),
    unitId: Yup.number().required('Unit is required'),
});

const UpdateManageProducts = ({ handleClose, productsId, setMessage, fetchData, clientCompanyList, UnitList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!productsId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PRODUCTS_LIST(productsId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.productsList);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [productsId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_PRODUCTS_LIST(productsId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    productName: data?.productName || '',
                    clientCompanyId: data?.clientCompanyId || '',
                    hsn: data?.hsn || '',
                    unitId: data?.unitId || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Product 
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={clientCompanyList}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={clientCompanyList.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                    error={touched.clientCompanyId && !!errors.clientCompanyId}
                                    helperText={touched.clientCompanyId && errors.clientCompanyId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter HSN Number"
                                    name="hsn"
                                    fieldLabel="HSN Number"
                                    required={true}
                                    value={values.hsn}
                                    onChange={handleChange}
                                    error={touched.hsn && !!errors.hsn}
                                    helperText={touched.hsn && errors.hsn}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Product Name"
                                    name="productName"
                                    fieldLabel="Product Name"
                                    required={true}
                                    value={values.productName}
                                    onChange={handleChange}
                                    error={touched.productName && !!errors.productName}
                                    helperText={touched.productName && errors.productName}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={UnitList}
                                    fieldLabel="Unit"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={UnitList.find((option) => option.id === values.unitId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('unitId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Unit"
                                    error={touched.unitId && !!errors.unitId}
                                    helperText={touched.unitId && errors.unitId}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateManageProducts;
