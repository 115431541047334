
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import { AppBarCompany } from '../../constants/DetailsPage'
import { Main } from '../../constants/DetailsMain';
import { Call } from '../Leads/OverViewMenu/Call';
import { Tasks } from '../Leads/OverViewMenu/Tasks';
import { Notes } from '../Leads/OverViewMenu/Notes';
import { useNavigate } from 'react-router-dom';
import { useClientCompanyDetails } from './CompanyMenu/UseClientCompanyDetails';
import CustomCollapse from '../common/CustomCollapse';
import ClientCompanyDetail from './CompanyMenu/ClientCompanyDetail';
import CustomModal from '../common/CustomModal';
import CreateCompanyInfo from './CompanyMenu/CreateCompanyInfo';
import MessageBox from '../.../../common/CustomMessageBox';
import { GET_clientCompany_TaxId } from '../../services/apiRoutes';
import axios from 'axios';
import UpdateCompanyInfo from './CompanyMenu/UpdateCompanyInfo';
import CustomLoader from '../common/CustomLoader';
import { Box } from '@mui/material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';


export default function PersistentDrawerLeft() {
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [updateModal, setUpdateModal] = useState(false);
    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview'); // Track active tab
    const [tax, setTax] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for fetch operation
    const [error, setError] = useState(null); // Error state


    const fetchCompanyTax = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(GET_clientCompany_TaxId(clientCompanyId), {
                headers: { Authorization: `Bearer ${authToken}` }
            });

            const { companyTax } = response.data;
            if (companyTax && companyTax.length > 0) {
                setTax(companyTax[0]);
            } else {
                setTax({});
            }
        } catch (err) {
            setError('Error fetching company tax details');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (clientCompanyId) {
            fetchCompanyTax();
        }
    }, [clientCompanyId]);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    if (!companyDetail) {
        return (
            <Box>
                <CustomLoader open={loading} />
            </Box>
        );
    }

    const clientCompanyTax = companyDetail.clientCompanyId;
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    const adminData = [
        {
            title: "Company Admin",
            defaultExpanded: false,
            details: [
                { label: 'Company Admin', value: `${companyDetail.companyAdmin.firstName || ''} ${companyDetail.companyAdmin.lastName || ''}`.trim() },
                { label: 'Email', value: companyDetail.user.email, sm: 12, md: 12 },
                { label: 'Mobile', value: companyDetail.user.mobile, sm: 12, md: 12 },

            ],
        },
    ];

    const clientCompany = [
        {
            title: companyDetail.clientCompanyName,
            defaultExpanded: false,
            details: [
                { label: 'Email', value: companyDetail.email, sm: 12, md: 4 },
                { label: 'Mobile', value: companyDetail.mobile, sm: 12, md: 4 },
                { label: 'Phone', value: companyDetail.phone, sm: 12, md: 4 },
                { label: 'Company Type', value: companyDetail.companyType.companyTypeName, sm: 12, md: 4 },
                { label: 'Industry', value: companyDetail.industryType.industryTypeName, sm: 12, md: 4 },
                { label: 'Business Group', value: companyDetail.BusinessGroup.businessGroupName || 'N/A', sm: 12, md: 4 },
                { label: 'Address', value: `${companyDetail.billingStreet || ''}, ${companyDetail.billingCity?.name || ''}, ${companyDetail.billingState?.name || ''}, ${companyDetail.billingCountry?.name || ''} ${companyDetail.billingPincode || ''}.`.trim() },

                { label: 'Pan Number', value: tax.panNumber || '', sm: 12, md: 4 },
                { label: 'Iec Number', value: tax.iecNumber || '', sm: 12, md: 4 },
                { label: 'Gst Number', value: tax.gstNumber || '', sm: 12, md: 12 },
            ],
        },
    ];

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }

    const handleOpenUpdate = () => {
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        // setShippingBookingId('');
    };


    const buttonTitle = tax.panNumber ? "Update Company Tax" : "Create Company Tax";
    // const handleChange = tax.panNumber ? handleOpen : handleOpenUpdate;
    // Change handler to open appropriate modal
    const handleChange = () => {
        if (tax.panNumber) {
            handleOpenUpdate(); // Opens update modal if PAN number exists
        } else {
            handleOpen(); // Opens create modal if PAN number does not exist
        }
    };

    return (
        <>
            <div>
                <CustomLoader open={loading} />

                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}> */}
                <ClientCompanyDetail
                    data={clientCompany}
                    Icon={BusinessOutlinedIcon}
                    handleOpen={handleChange}
                    buttonTitle={buttonTitle}
                />
                <CustomModal
                    open={modal}
                    handleClose={handleClose}
                    modalStyles={{
                        maxHeight: '50vh',
                        top: "10%",
                    }}
                >
                    <CreateCompanyInfo
                        fetchCompanyTax={fetchCompanyTax}
                        clientCompanyTax={clientCompanyTax}
                        companyDetail={clientCompany}
                        setMessage={setMessage}
                        handleClose={handleClose}
                    />
                </CustomModal>

                <CustomModal
                    modalStyles={{
                        maxHeight: '50vh',
                        top: "10%",
                    }}
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateCompanyInfo
                        clientCompanyId={clientCompanyId}
                        clientCompanyTax={clientCompanyTax}
                        tax={tax}
                        fetchCompanyTax={fetchCompanyTax}
                        companyDetail={clientCompany}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                    />
                </CustomModal>

                <br />
                <Call />
                <br />
                <Tasks />
                <br />
                <Notes />
                <br />
                <CustomCollapse Icon={PersonOutlineOutlinedIcon} data={adminData} />
                <br />

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
                {/* </Main> */}
            </div >
        </>
    );
}

