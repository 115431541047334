

// export const CompanyMenu = [
//     { value: 1, menu: 'Overview', to: '/app/company/455' },
//     { value: 2, menu: 'Lead', to: '/app/company/lead' },
//     { value: 3, menu: 'Contacts', to: '/app/company/contacts' },
//     { value: 5, menu: 'Import Shipment', to: '/app/company/import' },
//     { value: 6, menu: 'Export Shipment', to: '/app/company/export' }
// ];


export const AppBarCompany = (clientCompanyId) => [
    { menu: 'Overview', to: `/app/company/${clientCompanyId}` },
    { value: 2, menu: 'Lead', to: `/app/company/lead/${clientCompanyId}` },
    { value: 3, menu: 'Contacts', to: `/app/company/contacts/${clientCompanyId}` },
    { value: 5, menu: 'Import Shipment', to: `/app/company/import/${clientCompanyId}` },
    { value: 6, menu: 'Export Shipment', to: `/app/company/export/${clientCompanyId}` }
];


export const AppBarMenu = (importShipmentId) => [
    { menu: 'Overview', to: `/app/import/shipement/${importShipmentId}` },
    { value: 2, menu: 'Purchase Order', to: `/app/import/purchaseorder/${importShipmentId}` },
    { value: 3, menu: 'Shipping Mark', to: `/app/import/shippingmark/${importShipmentId}` },
    { value: 4, menu: 'Booking', to: `/app/import/booking/${importShipmentId}` },
    { value: 5, menu: 'Tracker', to: `/app/import/tracker/${importShipmentId}` },
    { value: 6, menu: 'Payment', to: `/app/import/payment/${importShipmentId}` },
    { value: 7, menu: 'Expense', to: `/app/import/expense/${importShipmentId}` },
    { value: 8, menu: 'Receipt Voucher', to: `/app/import/receiptvoucher/${importShipmentId}` },
    { value: 9, menu: 'Proforma Invoice', to: `/app/import/proformainvoice/${importShipmentId}` },
    { value: 10, menu: 'Commercial Invoice', to: `/app/import/commercialinvoice/${importShipmentId}` },
    { value: 11, menu: 'Document', to: `/app/import/document/${importShipmentId}` }
];

export const ExportMenu = (exportShipmentId) => [
    {   menu: 'Overview', to: `/app/export/shipement/${exportShipmentId}` },
    { value: 2, menu: 'Sales Order', to: `/app/export/salesorder/${exportShipmentId}` },
    { value: 3, menu: 'Shipping Mark', to: `/app/export/shippingmark/${exportShipmentId}` },
    { value: 4, menu: 'Proforma Invoice', to: `/app/export/proformainvoice/${exportShipmentId}` },
    { value: 5, menu: 'Sales Contract', to: `/app/export/salescontract/${exportShipmentId}` },
    { value: 6, menu: 'Commercial Invoice', to: `/app/export/commercialinvoice/${exportShipmentId}` },
    { value: 7, menu: 'Expense Voucher', to: `/app/export/expensevoucher/${exportShipmentId}` },
    { value: 8, menu: 'Receipt Voucher', to: `/app/export/receiptvoucher/${exportShipmentId}` },
    { value: 8, menu: 'Payment Voucher', to: `/app/export/payment/${exportShipmentId}` },
    { value: 9, menu: 'BL Draft', to: `/app/export/bldraft/${exportShipmentId}` },
    { value: 10, menu: 'COA', to: `/app/export/coa/${exportShipmentId}` },
    { value: 11, menu: 'COO', to: `/app/export/coo/${exportShipmentId}` },
    { value: 12, menu: 'Packing List', to: `/app/export/packinglist/${exportShipmentId}` },
    { value: 13, menu: 'Shipment Booking', to: `/app/export/shipmentbooking/${exportShipmentId}` },
    { value: 14, menu: 'Document', to: `/app/export/document/${exportShipmentId}` },


];



export const TaskCardColumns = [
    { id: 'sn', label: 'Sn', accessor: 'sn' },
    { id: 'taskOwner', label: 'Task Owner', accessor: 'taskOwner' },
    { id: 'assignTo', label: 'Assign To', accessor: 'assignTo' },
    { id: 'contactPerson', label: 'Contact Person', accessor: 'contactPerson' },
    { id: 'taskName', label: 'Task Name', accessor: 'taskName' },
    { id: 'subject', label: 'Subject', accessor: 'subject' },
    { id: 'dueDate', label: 'Due Date', accessor: 'dueDate' },
    { id: 'status', label: 'Status', accessor: 'status' },
    { id: 'priority', label: 'Priority', accessor: 'priority' },
    { id: 'description', label: 'Description', accessor: 'description' },
    { id: 'createdAt', label: 'Created At', accessor: 'createdAt' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const CallCardColumns = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'callTo', label: 'Call To', accessor: 'callTo' },
    { id: 'callType', label: 'Call Type', accessor: 'callType' },
    { id: 'subject', label: 'Subject', accessor: 'subject' },
    { id: 'details', label: 'Details', accessor: 'details' },
    { id: 'startTime', label: 'Start Time', accessor: 'startTime' },
    { id: 'duration', label: 'Duration', accessor: 'duration' },
    { id: 'purpose', label: 'Purpose', accessor: 'purpose' },
    { id: 'outcome', label: 'Outcome', accessor: 'outcome' },
    { id: 'description', label: 'Description', accessor: 'description' },
    { id: 'calledAt', label: 'Called At', accessor: 'calledAt' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const NotesCardColumns = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'notes', label: 'Notes', accessor: 'notes' },
];



export const ShippingBookingCardColumns = [
    // done 
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'shipmentId', label: 'Shipment ID', accessor: 'shipmentId' },
    { id: 'lp', label: 'LP', accessor: 'lp' },
    { id: 'dp', label: 'DP', accessor: 'dp' },
    { id: 'etd', label: 'ETD', accessor: 'etd' },
    { id: 'eta', label: 'ETA', accessor: 'eta' },
    { id: 'cifFob', label: 'CIF/FOB', accessor: 'cifFob' },
    { id: 'destinationCharges', label: 'Destination Charges', accessor: 'destinationCharges' },
    { id: 'slc', label: 'SLC', accessor: 'slc' },
    { id: 'blCharges', label: 'BL Charges', accessor: 'blCharges' },
    { id: 'noOfContainers', label: 'No. of Containers', accessor: 'noOfContainers' },
    { id: 'shippingLine', label: 'Shipping Line', accessor: 'shippingLine' },
    { id: 'ffa', label: 'FFA', accessor: 'ffa' },
    { id: 'cca', label: 'CCA', accessor: 'cca' },
    { id: 'action', label: 'Action', accessor: 'action' },
];


export const PaymentVoucherCardColumns = [
    // done 
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo' },
    { id: 'supplierName', label: 'Supplier Name', accessor: 'supplierName' },
    { id: 'paymentType', label: 'Payment Type', accessor: 'paymentType' },
    { id: 'amount1', label: 'Amount', accessor: 'amount1' },
    { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
    { id: 'amount2', label: 'Amount', accessor: 'amount2' },
    { id: 'bankCharges', label: 'Bank Charges', accessor: 'bankCharges' },
    { id: 'action', label: 'Action', accessor: 'action' },
];


export const ExpenseVoucherCardColumns = [
    // done 
    { id: 'srNo', label: 'Sr. No.', accessor: 'srNo' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo' },
    { id: 'expenseLedger', label: 'Expense Ledger', accessor: 'expenseLedger' },
    { id: 'amount1', label: 'Amount', accessor: 'amount1' },
    { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
    { id: 'amount2', label: 'Amount', accessor: 'amount2' },
    { id: 'action', label: 'Action', accessor: 'action' },
];

export const CompanyLeadColumn = [
    { id: 'srNo', label: 'SN', accessor: 'srNo' },
    { id: 'client', label: 'Client', accessor: 'client' },
    { id: 'mobile', label: 'Mobile', accessor: 'mobile' },
    { id: 'email', label: 'Email', accessor: 'email' },
    { id: 'state', label: 'State', accessor: 'state' },
    { id: 'city', label: 'City', accessor: 'city' },
    { id: 'gstin', label: 'GSTIN', accessor: 'gstin' },
    { id: 'contactPersons', label: 'Contact Persons', accessor: 'contactPersons' },
    { id: 'action', label: 'Action', accessor: 'action' }
];


export const ExportShipmentColumn = [
    { id: 'sn', label: 'SN', accessor: 'sn' },
    { id: 'performNo', label: 'Porform No.', accessor: 'performNo' },
    { id: 'date', label: 'Date', accessor: 'date' },
    { id: 'shippingId', label: 'Shipping Id', accessor: 'shippingId' },
    { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
    { id: 'amount', label: 'Amount', accessor: 'amount' },
    { id: 'action', label: 'Action', accessor: 'action' }
];


// Export Header

