import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_EXPORT_SHIPMENT_TRACKING } from "../../services/apiRoutes";
const title = "Export Shipment Tracking";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'shippingBookingId', header: 'SN', size: 50 },
            { accessorKey: 'etaDate', header: 'Date', size: 100 },
            { accessorKey: 'portOfDestination', header: 'Export  Shipment', size: 100 },
            { accessorKey: 'numberOfContainers', header: 'No. of Containers', size: 100 },

            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_EXPORT_SHIPMENT_TRACKING });
            if (response.data.success && response.data.shippingBooking) {
                const shippingBookingData = response.data.shippingBooking.map((booking) => ({
                    shippingBookingId: booking.shippingBookingId || '',
                    numberOfContainers: booking.numberOfContainers || '',
                    portOfLoading: booking.portOfLoading || '',
                    portOfDestination: booking.portOfDestination || '',
                    etaDate: booking.etaDate || '',
                    etdDate: booking.etdDate || '',
                    destinationCharges: booking.destinationCharges || '',
                    shippingLineCharges: booking.shippingLineCharges || '',
                    blCharges: booking.blCharges || '',
                    cifFobName: booking.cifFob?.cifFobName || '',
                    shippingLineName: booking.shippingLines?.shippingLineName || '',
                    agencyName: booking.freightForwardAgency?.agencyName || '',
                    chaName: booking.customClearingAgency?.chaName || '',

                }));
                setData(shippingBookingData);
            } else {
                setError('No shipping bookings found');
            }
        } catch (error) {
            setError('Error fetching shipping bookings');
            console.error('Error fetching shipping bookings', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])


    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />
            </Box>
        </>
    );
};
export default Function;
