import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { READ_IMPORT_SHIPPING_BOOKING, UPDATE_IMPORT_SHIPPING_BOOKING } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";

const ValidationSchema = Yup.object().shape({
    shippingTypeId: Yup.number().required('Shipping Type is required'),
    portOfLoading: Yup.string().required('Port of Loading is required'),
    portOfDestination: Yup.string().required('Port of Destination is required'),
    directIndirect: Yup.string().required('Direct/Indirect is required'),
    shippingLine: Yup.string().required('Shipping Line is required'),
    seaFreight: Yup.string().required('Sea Freight is required'),
    currencyId: Yup.number().required('Currency is required'),
    etdDate: Yup.date().required('ETD Date is required'),
    cutOffDate: Yup.date().required('Cut-Off Date is required'),
    etaDate: Yup.date().required('ETA Date is required'),
    destinationCharges: Yup.number().required('Destination Charges are required'),
    cfsLocalCharges: Yup.number().required('CFS Local Charges are required'),
    shippingLineCharges: Yup.number().required('Shipping Line Charges are required'),
    blCharges: Yup.number().required('BL Charges are required'),
    numberOfContainers: Yup.number().required('Number of Containers is required'),
    containerType: Yup.number().required('Container Type is required'),
    freightForwardAgencyId: Yup.number().required('Freight Forward Agency is required'),
    clearingAgencyId: Yup.number().required('Clearing Agency is required'),
    freeDaysAtDestination: Yup.number().required('Free Days at Destination are required'),
    note: Yup.string(),
});

const UpdateBooking = ({ handleClose, setMessage, fetchData, shippingBookingId, shippingLines, fclData, directListData, freightForwardAgencyData, customClearingAgencyData, cifFobList, currencyList, clientCompanyId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!shippingBookingId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPPING_BOOKING(shippingBookingId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.shippingBooking);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shippingBookingId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_IMPORT_SHIPPING_BOOKING(shippingBookingId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipping Booking updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    shippingTypeId: data?.shippingTypeId || '',
                    portOfLoading: data?.portOfLoading || '',
                    portOfDestination: data?.portOfDestination || '',
                    directIndirect: data?.directIndirect || '',
                    shippingLine: parseInt(data?.shippingLine || '',),
                    seaFreight: data?.seaFreight || '',
                    currencyId: data?.currencyId || '',
                    etdDate: data?.etdDate || '',
                    cutOffDate: data?.cutOffDate || '',
                    etaDate: data?.etaDate || '',
                    destinationCharges: data?.destinationCharges || '',
                    cfsLocalCharges: data?.cfsLocalCharges || '',
                    shippingLineCharges: data?.shippingLineCharges || '',
                    blCharges: data?.blCharges || '',
                    numberOfContainers: data?.numberOfContainers || '',
                    containerType: data?.containerType || '',
                    freightForwardAgencyId: data?.freightForwardAgencyId || '',
                    clearingAgencyId: data?.clearingAgencyId || '',
                    freeDaysAtDestination: data?.freeDaysAtDestination || '',
                    note: data?.note || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Shipping  Booking
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={cifFobList}
                                    fieldLabel="Select Type"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={cifFobList.find((option) => option.id === values.shippingTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('shippingTypeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Select Type"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Port of Loading"
                                    name="portOfLoading"
                                    fieldLabel="Port of Loading"
                                    required={true}
                                    value={values.portOfLoading}
                                    onChange={handleChange}
                                    error={touched.portOfLoading && !!errors.portOfLoading}
                                    helperText={touched.portOfLoading && errors.portOfLoading}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Port of Destination"
                                    name="portOfDestination"
                                    fieldLabel="Port of Destination"
                                    required={true}
                                    value={values.portOfDestination}
                                    onChange={handleChange}
                                    error={touched.portOfDestination && !!errors.portOfDestination}
                                    helperText={touched.portOfDestination && errors.portOfDestination}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={directListData}
                                    fieldLabel="Direct/Indirect"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={directListData.find((option) => option.id === values.directIndirect) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('directIndirect', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Direct/Indirect"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={shippingLines}
                                    fieldLabel="Shipping Line"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={shippingLines.find((option) => option.id === values.shippingLine) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('shippingLine', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Shipping Line"
                                    error={touched.shippingLine && !!errors.shippingLine}
                                    helperText={touched.shippingLine && errors.shippingLine}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Sea Freight"
                                    name="seaFreight"
                                    fieldLabel="Sea Freight"
                                    required={true}
                                    type="number"
                                    value={values.seaFreight}
                                    onChange={handleChange}
                                    error={touched.seaFreight && !!errors.seaFreight}
                                    helperText={touched.seaFreight && errors.seaFreight}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Select Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter ETD Date"
                                    type="date"
                                    name="etdDate"
                                    fieldLabel="ETD Date"
                                    required={true}
                                    value={values.etdDate}
                                    onChange={handleChange}
                                    error={touched.etdDate && !!errors.etdDate}
                                    helperText={touched.etdDate && errors.etdDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Cut-Off Date"
                                    type="date"
                                    name="cutOffDate"
                                    fieldLabel="Cut-Off Date"
                                    required={true}
                                    value={values.cutOffDate}
                                    onChange={handleChange}
                                    error={touched.cutOffDate && !!errors.cutOffDate}
                                    helperText={touched.cutOffDate && errors.cutOffDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter ETA Date"
                                    type="date"
                                    name="etaDate"
                                    fieldLabel="ETA Date"
                                    required={true}
                                    value={values.etaDate}
                                    onChange={handleChange}
                                    error={touched.etaDate && !!errors.etaDate}
                                    helperText={touched.etaDate && errors.etaDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Destination Charges"
                                    name="destinationCharges"
                                    fieldLabel="Destination Charges"
                                    required={true}
                                    type="number"
                                    value={values.destinationCharges}
                                    onChange={handleChange}
                                    error={touched.destinationCharges && !!errors.destinationCharges}
                                    helperText={touched.destinationCharges && errors.destinationCharges}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter CFS Local Charges"
                                    name="cfsLocalCharges"
                                    fieldLabel="CFS/Local Charges"
                                    required={true}
                                    type="number"
                                    value={values.cfsLocalCharges}
                                    onChange={handleChange}
                                    error={touched.cfsLocalCharges && !!errors.cfsLocalCharges}
                                    helperText={touched.cfsLocalCharges && errors.cfsLocalCharges}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Shipping Line Charges"
                                    name="shippingLineCharges"
                                    fieldLabel="Shipping Line Charges"
                                    required={true}
                                    type="number"
                                    value={values.shippingLineCharges}
                                    onChange={handleChange}
                                    error={touched.shippingLineCharges && !!errors.shippingLineCharges}
                                    helperText={touched.shippingLineCharges && errors.shippingLineCharges}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter BL Charges"
                                    name="blCharges"
                                    fieldLabel="BL Charges"
                                    required={true}
                                    type="number"
                                    value={values.blCharges}
                                    onChange={handleChange}
                                    error={touched.blCharges && !!errors.blCharges}
                                    helperText={touched.blCharges && errors.blCharges}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Number of Containers"
                                    name="numberOfContainers"
                                    fieldLabel="Number of Containers"
                                    required={true}
                                    type="number"
                                    value={values.numberOfContainers}
                                    onChange={handleChange}
                                    error={touched.numberOfContainers && !!errors.numberOfContainers}
                                    helperText={touched.numberOfContainers && errors.numberOfContainers}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={fclData}
                                    fieldLabel="20FCL/40FCL"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={fclData.find((option) => option.id === values.containerType) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('containerType', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select 20FCL/40FCL"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={freightForwardAgencyData}
                                    fieldLabel="Freight Forward Agency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={freightForwardAgencyData.find((option) => option.id === values.freightForwardAgencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('freightForwardAgencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Freight Forward Agency"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={customClearingAgencyData}
                                    fieldLabel="Clearing Agency (CHA)"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={customClearingAgencyData.find((option) => option.id === values.clearingAgencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clearingAgencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Clearing Agency"
                                    error={touched.clearingAgencyId && !!errors.clearingAgencyId}
                                    helperText={touched.clearingAgencyId && errors.clearingAgencyId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Free Days at Destination"
                                    name="freeDaysAtDestination"
                                    fieldLabel="Free Days at Destination"
                                    required={true}
                                    type="number"
                                    value={values.freeDaysAtDestination}
                                    onChange={handleChange}
                                    error={touched.freeDaysAtDestination && !!errors.freeDaysAtDestination}
                                    helperText={touched.freeDaysAtDestination && errors.freeDaysAtDestination}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextarea
                                    placeholder="Enter Note"
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateBooking;

