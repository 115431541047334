import React, { useState } from "react";
import {
    Drawer,
    Button,
    Box,
    Typography,
    IconButton,
    Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomFilter = ({
    drawerTitle,
    drawerContent,
    drawerWidth,
    openDrawer,
    onClick,
    onClose
}) => {
    return (
        <>
            {/* Button to toggle the drawer */}

            {/* Drawer Component */}
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={onClose}
                sx={{ width: drawerWidth, }}
            >
                <Box
                    sx={{
                        width: drawerWidth,
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}
                    >
                        <Typography variant="h6" component="div" sx={{ fontWeight: "600" }}>
                            {drawerTitle}
                        </Typography>
                        <IconButton onClick={onClose} >
                            <CloseIcon sx={{ fontWeight: "600" }} />
                        </IconButton>
                    </Box>
                    <Divider sx={{ paddingTop: "0px", paddingBottom: "10px" }} />
                    <Box sx={{ marginTop: "10px" }}>
                        {drawerContent}
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};
export default CustomFilter;