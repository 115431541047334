import axios from 'axios';
import { PhoneEnabledOutlined, West } from '@mui/icons-material';
import CustomLoader from '../common/CustomLoader';
import { useParams, useNavigate } from 'react-router-dom';
import { READ_IMPORT_SHIPMENT, GET_call, GET_task, IMPORT_SHIPMENT_BUTTON_LIST } from '../../services/apiRoutes';
import React, { useState, useEffect, useMemo } from 'react';
import { Box, IconButton, Toolbar, Stack, Button, Avatar, Drawer, List, ListItem, ListItemText, ListItemButton, Menu, MenuItem, } from '@mui/material'
import { styled, useTheme } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Link } from 'react-router-dom';
import { genericGet } from '../../services/apiServices';
import AppBar from '@mui/material/AppBar';
import { CustomTitile, Main } from '../../constants/DetailsMain';
import PersistentDrawerLeft from './CompanyDetails';
import { genericPost } from '../../services/authServices';
import { useClientCompanyDetails } from './CompanyMenu/UseClientCompanyDetails';
import LeadMenu from './CompanyMenu/LeadMenu';
import ContactsMenu from './CompanyMenu/ContactsMenu';
import ImportMenu from './CompanyMenu/ImportMenu';
import ExportMenu from './CompanyMenu/ExportMenu';

const drawerWidth = 200;
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function CompanyDetailsView() {
    // const { importShipmentId } = useParams();
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);

    const navigate = useNavigate();

    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [activeTable, setActiveTable] = useState('overView');
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [callData, setCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [taskData, setTaskData] = useState([]);
    const [totalCallIdSum, setTotalCallIdSum] = useState(0);
    const [numberOfTasks, setNumberOfTasks] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonListCount, setButtonList] = useState([]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const handleTableChange = (table) => setActiveTable(table);

    // const fetchShipmentDetails = async () => {
    //     setLoading(true);
    //     try {
    //         const authToken = sessionStorage.getItem('accessToken');
    //         const response = await axios.get(READ_IMPORT_SHIPMENT(importShipmentId), {
    //             headers: { Authorization: `Bearer ${authToken}` },
    //         });
    //         setShipmentDetails(response.data.shipment);
    //     } catch (error) {
    //         console.error('Error fetching shipment details:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // useEffect(() => {
    //     fetchShipmentDetails();
    // }, [importShipmentId]);


    const fetchButtonList = async () => {
        if (!shipmentDetails?.supplierId) {
            setError('Supplier ID is missing in shipment details');
            return;
        }
        setLoading(true);
        setError(null);
        try {
            const payload = {
                clientCompanyId: shipmentDetails.supplierId,
            };
            const response = await genericPost(IMPORT_SHIPMENT_BUTTON_LIST, payload);
            if (response.data.success && response.data.buttonList) {
                setButtonList(response.data.buttonList);
            } else {
                setError('No button list data found');
            }
        } catch (error) {
            setError('Error fetching button list data');
            console.error('Error fetching button list:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const response = await genericGet({ url: GET_call });
            if (response.data.success && response.data.calls) {
                setCallData(response.data.calls);
                const numberOfCalls = response.data.calls.length;
                setTotalCallIdSum(numberOfCalls);

            } else {
                setError('No calls found');
            }
        } catch (error) {
            setError('Error fetching call data');
            console.error('Error fetching call data', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchTaskData = async () => {
        try {
            const response = await genericGet({ url: GET_task });

            if (response.data.success && response.data.tasks) {
                setTaskData(response.data.tasks);
                const numberOfTasks = response.data.tasks.length;
                setNumberOfTasks(numberOfTasks);
            } else {
                setError('No tasks found');
            }
        } catch (error) {
            setError('Error fetching task data');
            console.error('Error fetching task data', error);
        } finally {
            setLoading(false);
        }
    };

    // const companyName = shipmentDetails?.supplier?.clientCompanyName;
    // const phoneNumber = shipmentDetails?.supplier?.mobile;
    // const clientCompanyId = shipmentDetails?.supplierId;
    // const initial = shipmentDetails?.supplier?.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';
    const clientCompanyTax = companyDetail?.clientCompanyId || '';
    const companyName = companyDetail?.clientCompanyName || '';
    const phoneNumber = companyDetail?.mobile || '';
    const initial = companyDetail?.clientCompanyName
        ? companyDetail.clientCompanyName.charAt(0).toUpperCase()
        : '';

    const handleBackClick = () => {
        navigate("/app/company");
    };


    useEffect(() => {
        fetchData();
        fetchTaskData();
    }, []);

    useEffect(() => {
        if (shipmentDetails) {
            fetchButtonList();
        }
    }, [shipmentDetails]);


    const buttonList = [
        { label: 'overView', count: "", event: "overView" },
        { label: 'lead', count: 4, event: "lead" },
        { label: 'contacts', count: 2, event: "contacts" },
        { label: 'import shipment', count: 6, event: "importshipment" },
        { label: 'export shipment', count: 2, event: "exportshipment" },
    ];


    return (
        <>
            <CustomTitile
                handleBackClick={handleBackClick}
                initial={initial}
                companyName={companyName}
                phoneNumber={phoneNumber}
            />
            <AppBar
                position="fixed"
                open={open}
                style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    top: 115,
                    transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    ...(open && {
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: `${drawerWidth}px`,
                        transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    }),
                }}
            >
                <Toolbar>
                    <IconButton
                        style={{ color: "#373A40" }}
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Stack spacing={1} direction="row" sx={stackStyle}>
                        {buttonList.slice(0, 6).map((table) => (
                            <Button
                                key={table.event}
                                variant={activeTable === table.event ? "contained" : "outlined"}
                                onClick={() => handleTableChange(table.event)}
                                endIcon={
                                    table.count !== "" && (
                                        <Avatar
                                            style={{
                                                width: 20,
                                                height: 20,
                                                fontSize: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: activeTable === table.event ? '#ffff' : '#007bff',
                                                color: activeTable === table.event ? '#007bff' : '#ffff',
                                            }}
                                        >
                                            {table.count}
                                        </Avatar>
                                    )
                                }
                                style={{
                                    backgroundColor: activeTable === table.event ? '#007bff' : '#ffff',
                                    color: activeTable === table.event ? '#ffff' : '#007bff',
                                    textTransform: "capitalize",
                                    borderRadius: 4,
                                    border: '1px solid #007bff',
                                    height: 30,
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {table.label}
                            </Button>
                        ))}
                    </Stack>
                </Toolbar>
            </AppBar >
            <Drawer
                sx={drawerStyle}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}
                        style={{ color: "#373A40" }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton> <span style={{ color: "#212529", fontSize: "24px", fontWeight: "600" }}> Related List</span>
                </DrawerHeader>
                <List >
                    <ListItem>
                        <ListItemButton  >
                            <ListItemText primary="Calls" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}> {totalCallIdSum} </Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Tasks" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>{numberOfTasks}</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Notes" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>

                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Emails" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}  >
                <CustomLoader open={loading} />
                {activeTable === 'overView' && <PersistentDrawerLeft shipmentDetails={shipmentDetails} />}
                {activeTable === 'lead' && <LeadMenu shipmentDetails={shipmentDetails} />}
                {activeTable === 'contacts' && <ContactsMenu shipmentDetails={shipmentDetails} />}
                {activeTable === 'importshipment' && <ImportMenu shipmentDetails={shipmentDetails} />}
                {activeTable === 'exportshipment' && <ExportMenu shipmentDetails={shipmentDetails} />}
            </Main>
        </>
    );
}

const stackStyle = {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingBottom: '3px',
    '&::-webkit-scrollbar': {
        height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#E7E9EB',
        borderRadius: 4,
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
    },
}
const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        marginTop: '115px',
    },
}

const styles = {
    boxheading: {
        width: 70,
        height: 70,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingname: {
        paddingTop: "10px",
        fontWeight: "600",
        fontSize: "20px",
        color: "#212529"
    },
    avatarcircle: {
        backgroundColor: "#007BFF",
        width: "30px",
        height: "30px",
        fontSize: "15px"
    },
};


