import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_RECEIPT_VOUCHER_EXPORT } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomStyle from "../../common/CustomStyle";

const ValidationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    receiptVoucherNo: Yup.string().required('Receipt Voucher No is required'),
    customerName: Yup.string().required('Customer Name is required'),
    receiptTypeId: Yup.number().required('Payment Type is required'),
    amount: Yup.number().required('Amount is required'),
    currencyId: Yup.number().required('Currency is required'),
    bankCharges: Yup.number().required('Bank Charges are required'),
    baseCurrencyId: Yup.number().required('Base Currency is required'),
    conversionRate: Yup.number().required('Conversion Rate is required'),
    baseAmount: Yup.number().required('Base Amount is required'),
    note: Yup.string().required('Note is required'),
});

const CreateReceiptVoucher = ({ handleClose, setMessage, fetchData, currencyList, receiptType, customerName, customerList, commanShipmentId }) => {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                commanShipmentId: values.commanShipmentId,
                customerName: values.customerName,
                date: values.date,
                receiptVoucherNo: values.receiptVoucherNo,
                receiptTypeId: values.receiptTypeId,
                amount: values.amount,
                currencyId: values.currencyId,
                bankCharges: values.bankCharges,
                baseCurrencyId: values.baseCurrencyId,
                conversionRate: values.conversionRate,
                baseAmount: values.baseAmount,
                note: values.note,
            };

            const response = await genericPost(CREATE_RECEIPT_VOUCHER_EXPORT, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Receipt Voucher added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        commanShipmentId: commanShipmentId,
        customerName: customerName || '',
        date: '',
        receiptVoucherNo: '',
        receiptTypeId: '',
        amount: '',
        currencyId: '',
        bankCharges: '',
        baseCurrencyId: '',
        conversionRate: '',
        baseAmount: '',
        note: '',
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Receipt Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={customerList}
                                    placeholder="Customer Name"
                                    fieldLabel="Customer Name"
                                    required={true}
                                    readOnly={true}
                                    getOptionLabel={(option) => option.title}
                                    value={customerList.find((option) => option.id === values.customerName) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('customerName', newValue ? newValue.id : '')
                                    }
                                    error={touched.customerName && !!errors.customerName}
                                    helperText={touched.customerName && errors.customerName}
                                    sx={{
                                        backgroundColor: "#E9ECEF"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    value={values.date}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    name="receiptVoucherNo"
                                    fieldLabel="Receipt Voucher No."
                                    value={values.receiptVoucherNo}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="Enter Receipt Voucher No."
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={receiptType}
                                    fieldLabel="Receipt Type"
                                    placeholder="Select Receipt Type"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={receiptType.find((option) => option.id === values.receiptTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('receiptTypeId', newValue ? newValue.id : '')
                                    }
                                    error={touched.receiptTypeId && !!errors.receiptTypeId}
                                    helperText={touched.receiptTypeId && errors.receiptTypeId}
                                />


                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    placeholder="Select Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    fieldLabel="Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="amount"
                                    fieldLabel="Amount"
                                    value={values.amount}
                                    required={true}
                                    type="number"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("amount", value);
                                        setFieldValue("baseAmount", value * values.conversionRate || 0);
                                    }}
                                    placeholder="Enter Amount"
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Base Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.baseCurrencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('baseCurrencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Base Currency"
                                    error={touched.baseCurrencyId && !!errors.baseCurrencyId}
                                    helperText={touched.baseCurrencyId && errors.baseCurrencyId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    name="conversionRate"
                                    fieldLabel="Conversion Rate"
                                    value={values.conversionRate}
                                    required={true}
                                    type="number"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setFieldValue("conversionRate", value);
                                        setFieldValue("baseAmount", values.amount * value || 0);
                                    }}
                                    placeholder="Enter Conversion Rate"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <CustomTextField
                                    name="baseAmount"
                                    fieldLabel="Base Amount"
                                    value={values.baseAmount}
                                    required={true}
                                    type="number"
                                    onChange={handleChange}
                                    placeholder="Enter Base Amount"
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="bankCharges"
                                    fieldLabel="Bank Charges"
                                    value={values.bankCharges}
                                    required={true}
                                    type="number"
                                    onChange={handleChange}
                                    placeholder="Enter Bank Charges"
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="note"
                                    type="text"
                                    fieldLabel="Note"
                                    value={values.note}
                                    onChange={handleChange}
                                    placeholder="Write a Note"
                                    sx={{
                                        height: "80px !important"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateReceiptVoucher;
