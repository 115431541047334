import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_IMPORT_SHIPPING_BOOKING } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const ShippingBookingView = ({ handleClose, setMessage, fetchData, shippingBookingId, shippingLines, fclData, directListData, freightForwardAgencyData, customClearingAgencyData, cifFobList, currencyList, clientCompanyId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!shippingBookingId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPPING_BOOKING(shippingBookingId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.shippingBooking);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shippingBookingId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const labels = [
        { key: 'shippingTypeId', label: "Shipping Type", sm: 12, md: 4, },
        { key: 'portOfLoading', label: "Port of Loading", sm: 12, md: 4, },
        { key: 'portOfDestination', label: "Port of Destination", sm: 12, md: 4, },
        { key: 'shippingLine', label: "Shipping Line", sm: 12, md: 4, },
        { key: 'seaFreight', label: "Sea Freight", sm: 12, md: 4, },
        { key: 'currencyId', label: "Currency", sm: 12, md: 4, },
        { key: 'etdDate', label: "ETD Date", sm: 12, md: 4, },
        { key: 'cutOffDate', label: "Cut Off Date", sm: 12, md: 4, },
        { key: 'etaDate', label: "ETA Date", sm: 12, md: 4, },
        { key: 'destinationCharges', label: "Destination Charges", sm: 12, md: 4, },
        { key: 'cfsLocalCharges', label: "CFS Local Charges", sm: 12, md: 4, },
        { key: 'shippingLineCharges', label: "Shipping Line Charges", sm: 12, md: 4, },
        { key: 'blCharges', label: "BL Charges", sm: 12, md: 4, },
        { key: 'numberOfContainers', label: "Number of Containers", sm: 12, md: 4, },
        { key: 'containerType', label: "Container Type", sm: 12, md: 4, },
        { key: 'freeDaysAtDestination', label: "Free Days at Destination", sm: 12, md: 4, },
        { key: 'fclName', label: "fcl Name", sm: 12, md: 4, },
        { key: 'agencyName', label: "Freight Forward Agency", sm: 12, md: 12, },
        { key: 'chaName', label: "Custom Clearing Agency", sm: 12, md: 12, },
        { key: 'note', label: "Note", sm: 12, md: 4, },
    ];


    const values = {
        shippingTypeId: data?.shippingLines?.shippingLineName,
        portOfLoading: data?.portOfLoading,
        portOfDestination: data?.portOfDestination,
        directIndirect: data?.directIndirect,
        shippingLine: data?.shippingLines?.shippingLineName,
        seaFreight: data?.seaFreight,
        currencyId: data?.currency?.currency,
        etdDate: data?.etdDate,
        cutOffDate: data?.cutOffDate,
        etaDate: data?.etaDate,
        destinationCharges: data?.destinationCharges,
        cfsLocalCharges: data?.cfsLocalCharges,
        shippingLineCharges: data?.shippingLineCharges,
        blCharges: data?.blCharges,
        numberOfContainers: data?.numberOfContainers,
        containerType: data?.containerType,
        freightForwardAgencyId: data?.freightForwardAgencyId,
        clearingAgencyId: data?.clearingAgencyId,
        freeDaysAtDestination: data?.freeDaysAtDestination,
        note: data?.note,
        fclName: data?.fcl?.fclName,
        agencyName: data?.freightForwardAgency?.agencyName,
        chaName: data?.customClearingAgency?.chaName,
    };
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529" }}>
                                    Shipping Booking
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ShippingBookingView;

