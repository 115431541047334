import axios from 'axios';
import { PhoneEnabledOutlined, West } from '@mui/icons-material';
import RenderCommercial from '../Reports/renderCommercial';
import CustomLoader from '../common/CustomLoader';
import RenderPurchaseOrderTable from '../Reports/renderPurchaseOrderTable';
import { useNavigate, useParams } from 'react-router-dom';
import { LIST_PURCHASE_ORDERS, READ_IMPORT_SHIPMENT, GET_call, GET_task, READ_EXPORT_SHIPMENT, EXPORT_SHIPMENT_BUTTON_LIST } from '../../services/apiRoutes';
import React, { useState, useEffect, useMemo } from 'react';
import { Box, IconButton, Toolbar, Stack, Button, Avatar, Drawer, List, ListItem, ListItemText, ListItemButton, Menu, MenuItem, } from '@mui/material'
import { styled, useTheme } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Link } from 'react-router-dom';
import { genericGet } from '../../services/apiServices';
import AppBar from '@mui/material/AppBar';
import { CustomTitile, Main } from '../../constants/DetailsMain';
import SalesOrder from './ExportMenu/SalesOrder';
import PersistentDrawerLeft from './ExportDetails';
import ShippingMark from './ExportMenu/ShippingMark';
import ProformaInvoice from './ExportMenu/ProformaInvoice';
import SalesContract from './ExportMenu/SalesContract';
import CommercialInvoice from './ExportMenu/CommercialInvoice';
import ExpenseVoucher from './ExportMenu/ExpenseVoucher';
import ReceiptVoucher from './ExportMenu/ReceiptVoucher';
import PaymentVoucher from './ExportMenu/PaymentVoucher';
import BLDraft from './ExportMenu/BLDraft';
import COO from './ExportMenu/COO';
import COA from './ExportMenu/COA';
import PackingList from './ExportMenu/PackingList';
import Booking from './ExportMenu/ShipmentBooking';
import Document from './ExportMenu/Document';
import { genericPost } from '../../services/authServices';

const drawerWidth = 200;
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

export default function ExportDetailsView() {
    const { exportShipmentId } = useParams();
    const navigate = useNavigate();

    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [activeTable, setActiveTable] = useState('overView');
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [callData, setCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [taskData, setTaskData] = useState([]);
    const [totalCallIdSum, setTotalCallIdSum] = useState(0);
    const [numberOfTasks, setNumberOfTasks] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [buttonList, setButtonList] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(true);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const handleTableChange = (table) => setActiveTable(table);

    const handleBackClick = () => {
        navigate('/app/export/shipement');
    };


    const fetchData = async () => {
        try {
            const response = await genericGet({ url: GET_call });
            if (response.data.success && response.data.calls) {
                setCallData(response.data.calls);
                const numberOfCalls = response.data.calls.length;
                setTotalCallIdSum(numberOfCalls);

            } else {
                setError('No calls found');
            }
        } catch (error) {
            setError('Error fetching call data');
            console.error('Error fetching call data', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchTaskData = async () => {
        try {
            const response = await genericGet({ url: GET_task });

            if (response.data.success && response.data.tasks) {
                setTaskData(response.data.tasks);
                const numberOfTasks = response.data.tasks.length;
                setNumberOfTasks(numberOfTasks);
            } else {
                setError('No tasks found');
            }
        } catch (error) {
            setError('Error fetching task data');
            console.error('Error fetching task data', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchShipmentDetails = async () => {
            setLoading(true);
            try {
                const authToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setShipmentDetails(response.data.shipment);
            } catch (error) {
                console.error('Error fetching shipment details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchShipmentDetails();
    }, [exportShipmentId]);

    useEffect(() => {
        if (shipmentDetails) {
            fetchButtonList();
        }
    }, [shipmentDetails]);


    const fetchButtonList = async () => {
        if (!shipmentDetails?.customerId) {
            setError('Supplier ID is missing in shipment details');
            return;
        }
        setButtonLoading(true);
        setError(null);
        try {
            const payload = {
                clientCompanyId: shipmentDetails.customerId,
            };
            const response = await genericPost(EXPORT_SHIPMENT_BUTTON_LIST, payload);
            if (response.data.success && response.data.buttonList) {
                setButtonList(response.data.buttonList);
            } else {
                setError('No button list data found');
            }
        } catch (error) {
            setError('Error fetching button list data');
            console.error('Error fetching button list:', error);
        } finally {
            setButtonLoading(false);
        }
    };



    const companyName = shipmentDetails?.customer?.clientCompanyName;
    const phoneNumber = shipmentDetails?.customer?.mobile;
    const clientCompanyId = shipmentDetails?.customerId;
    const initial = shipmentDetails?.customer?.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';

    useEffect(() => {
        fetchData();
        fetchTaskData();
    }, []);


    const buttonListBeforLoading = [
        { label: 'overView', count: "", event: "overView" },
        { label: 'sales Order', count: 4, event: "salesOrder" },
        { label: 'shipping mark', count: 4, event: "shippingmark" },
        { label: 'proforma invoice', count: 4, event: "proformainvoice" },
        { label: 'sales contract', count: 4, event: "salescontract" },
        { label: 'commercial invoice', count: 4, event: "commercialinvoice" },
        { label: 'more', count: '', event: "more" },
        // { label: 'expense voucher', count: 4, event: "expensevoucher" },
        // { label: 'receipt voucher', count: 4, event: "receiptvoucher" },
        // { label: 'payment', count: 4, event: "payment" },
        // { label: 'bl draft', count: 4, event: "bldraft" },
        // { label: 'coa', count: 4, event: "coa" },
        // { label: 'coo', count: 4, event: "coo" },
        // { label: 'packing list', count: 4, event: "packinglist" },
        // { label: 'shipment booking', count: 4, event: "shipmentbooking" },
        // { label: 'document', count: 4, event: "document" },
    ];

    return (
        <>
            <CustomTitile
                handleBackClick={handleBackClick}
                initial={initial}
                companyName={companyName}
                phoneNumber={phoneNumber}
            />
            <AppBar
                position="fixed"
                open={open}
                style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    top: 115,
                    transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    ...(open && {
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: `${drawerWidth}px`,
                        transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    }),
                }}
            >
                <Toolbar>
                    <IconButton
                        style={{ color: "#373A40" }}
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                    <Stack spacing={1} direction="row" sx={stackStyle}>

                        {buttonLoading ? (

                            buttonListBeforLoading.map((button, index) => (
                                <>
                                    <Button
                                        key={button.event}
                                        variant="outlined"
                                        endIcon={
                                            button.count && button.count !== "" && (
                                                <Avatar style={{
                                                    width: 20, height: 20, fontSize: 10, alignItems: 'center',
                                                    justifyContent: 'center', backgroundColor: '#007bff', color: '#ffff'
                                                }}>
                                                    0
                                                </Avatar>
                                            )
                                        }
                                        style={{
                                            backgroundColor: button.count === "" ? '#007bff' : '#ffff',
                                            color: button.count === "" ? '#ffff' : '#007bff',
                                            textTransform: "capitalize",
                                            borderRadius: 4, border: '1px solid #007bff', height: 30,
                                            display: 'inline-flex', alignItems: 'center', justifyContent: 'center',
                                        }}
                                    >
                                        {button.label}
                                    </Button>
                                </>

                            ))

                        ) : (
                            buttonList.slice(0, 6).map((table) => (
                                <Button
                                    key={table.event}
                                    variant={activeTable === table.event ? "contained" : "outlined"}
                                    onClick={() => handleTableChange(table.event)}
                                    endIcon={
                                        table.count !== "" && (
                                            <Avatar
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    fontSize: 10,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: activeTable === table.event ? '#ffff' : '#007bff',
                                                    color: activeTable === table.event ? '#007bff' : '#ffff',
                                                }}
                                            >
                                                {table.count}
                                            </Avatar>
                                        )
                                    }
                                    style={{
                                        backgroundColor: activeTable === table.event ? '#007bff' : '#ffff',
                                        color: activeTable === table.event ? '#ffff' : '#007bff',
                                        textTransform: "capitalize",
                                        borderRadius: 4,
                                        border: '1px solid #007bff',
                                        height: 30,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {table.label}
                                </Button>
                            )))
                        }
                        {buttonList.length > 6 && (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    style={{
                                        backgroundColor: '#007bff',
                                        color: '#ffff',
                                        textTransform: 'capitalize',
                                        borderRadius: 4,
                                        border: '1px solid #007bff',
                                        height: 30,
                                    }}
                                >
                                    More
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {buttonList.slice(6).map((table) => (
                                        <MenuItem
                                            key={table.count}
                                            onClick={() => {
                                                // handleScrollTo(table.label);
                                                handleClose();
                                            }}
                                            style={{
                                                backgroundColor: activeTable === table.event ? '#007bff' : 'inherit',
                                                color: activeTable === table.event ? '#ffff' : 'inherit',
                                            }}
                                        >
                                            <Link
                                                onClick={() => handleTableChange(table.event)}
                                                style={{
                                                    textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                    width: '100%'
                                                }}
                                            >
                                                <span style={{ marginRight: 4, textTransform: "capitalize" }}>
                                                    {table.label}
                                                </span>
                                                <Avatar
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        fontSize: 10,
                                                        backgroundColor: activeTable === table.event ? '#ffff' : '#007bff',
                                                        color: activeTable === table.event ? '#007bff' : '#ffff',
                                                    }}
                                                >
                                                    <span>{table.count} </span>
                                                </Avatar>
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>
                        )}

                    </Stack>
                </Toolbar>
            </AppBar >
            <Drawer
                sx={drawerStyle}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}
                        style={{ color: "#373A40" }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton> <span style={{ color: "#212529", fontSize: "24px", fontWeight: "600" }}> Related List</span>
                </DrawerHeader>
                <List >
                    <ListItem>
                        <ListItemButton  >
                            <ListItemText primary="Calls" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}> {totalCallIdSum} </Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Tasks" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>{numberOfTasks}</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Notes" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>

                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton  >
                            <ListItemText primary="Emails" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Main open={open}  >
                <CustomLoader open={loading} />
                {activeTable === 'overView' && <PersistentDrawerLeft shipmentDetails={shipmentDetails} />}
                {activeTable === 'salesOrder' && <SalesOrder shipmentDetails={shipmentDetails} />}
                {activeTable === 'shippingmark' && <ShippingMark shipmentDetails={shipmentDetails} />}
                {activeTable === 'proformainvoice' && <ProformaInvoice shipmentDetails={shipmentDetails} />}
                {activeTable === 'salescontract' && <SalesContract shipmentDetails={shipmentDetails} />}
                {activeTable === 'commercialinvoice' && <CommercialInvoice shipmentDetails={shipmentDetails} />}
                {activeTable === 'expensevoucher' && <ExpenseVoucher shipmentDetails={shipmentDetails} />}
                {activeTable === 'receiptvoucher' && <ReceiptVoucher shipmentDetails={shipmentDetails} />}
                {activeTable === 'payment' && <PaymentVoucher shipmentDetails={shipmentDetails} />}
                {activeTable === 'bldraft' && <BLDraft shipmentDetails={shipmentDetails} />}
                {activeTable === 'coo' && <COO shipmentDetails={shipmentDetails} />}
                {activeTable === 'coa' && <COA shipmentDetails={shipmentDetails} />}
                {activeTable === 'packinglist' && <PackingList shipmentDetails={shipmentDetails} />}
                {activeTable === 'shipmentbooking' && <Booking shipmentDetails={shipmentDetails} />}
                {activeTable === 'document' && <Document shipmentDetails={shipmentDetails} />}

            </Main>
        </>
    );
}

const stackStyle = {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    paddingBottom: '3px',
    '&::-webkit-scrollbar': {
        height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#E7E9EB',
        borderRadius: 4,
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
    },
}
const drawerStyle = {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        boxSizing: 'border-box',
        marginTop: '115px',
    },
}

const styles = {
    boxheading: {
        width: 70,
        height: 70,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingname: {
        paddingTop: "10px",
        fontWeight: "600",
        fontSize: "20px",
        color: "#212529"
    },
    avatarcircle: {
        backgroundColor: "#007BFF",
        width: "30px",
        height: "30px",
        fontSize: "15px"
    },
};


