import { useState, useEffect } from 'react';
import axios from 'axios';
import { GET_clientCompany_Id } from '../../../services/apiRoutes';

export const useClientCompanyDetails = (clientCompanyId) => {
    const [companyDetail, setCompanyDetail] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');
        setLoading(true);

        axios.get(GET_clientCompany_Id(clientCompanyId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetail(response.data.clientCompany);
                setLoading(false);

            })
            .catch(error => {
                console.error('Error fetching company details:', error);
                setLoading(false);

            });
    }, [clientCompanyId]);

    return { companyDetail, loading };
};
