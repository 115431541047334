// ExportDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { READ_EXPORT_SHIPMENT } from '../../../services/apiRoutes';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import CustomLoader from '../../common/CustomLoader';

const ExportDetail = () => {
    const { exportShipmentId } = useParams();
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setShipmentDetails(response.data.shipment);
            })
            .catch(error => {
                console.error('Error fetching shipment details:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [exportShipmentId]);

    if (!shipmentDetails) {
        return <p> <CustomLoader open={loading} />
        </p>;
    }

    const tableData = [
        { label: 'Company Name', value: shipmentDetails.customer?.clientCompanyName || 'N/A' },
        { label: 'mobile', value: shipmentDetails.customer?.mobile || 'N/A' },
        { label: 'Shipment ID', value: shipmentDetails.shipmentId || 'N/A' },
        { label: 'Shipment Type', value: shipmentDetails.shipmentType?.shipmentTypeName || 'N/A' },
        { label: 'CIF/FOB Name', value: shipmentDetails.cifFob?.cifFobName || 'N/A' },
        { label: 'No. of Containers', value: shipmentDetails.noOfContainers || 'N/A' },
        { label: 'currency', value: shipmentDetails.currency?.currency || 'N/A' },
        // { label: 'Expected ETD', value: shipmentDetails.expectedEtd ? new Date(shipmentDetails.expectedEtd).toLocaleString() : 'N/A' },

    ];

    return <DetailPageCustum Icon={RocketLaunchOutlinedIcon} title="Export Shipment Details" data={tableData} />;
};

export default ExportDetail;
