import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { READ_BUSINESS_GROUP, READ_PRIORITIES, UPDATE_BUSINESS_GROUP, UPDATE_PRIORITIES } from '../../../services/apiRoutes';
import CustomButton from '../../common/CustomButton';
import CustomTextField from '../../common/CustomTextField';
import CustomStyle from '../../common/CustomStyle';

const ValidationSchema = Yup.object().shape({
    priorityName: Yup.string().required('priorityName is required'),
    shortName: Yup.string().required('shortName is required'),
});

const UpdatePriorities = ({ handleClose, priorityId, setMessage, fetchData, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!priorityId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PRIORITIES(priorityId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.priorities);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [priorityId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_PRIORITIES(priorityId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    priorityName: data?.priorityName || '',
                    shortName: data?.shortName || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Priorities
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter priority Name"
                                    name="priorityName"
                                    fieldLabel="Priority Name"
                                    required
                                    value={values.priorityName}
                                    onChange={handleChange}
                                    error={touched.priorityName && !!errors.priorityName}
                                    helperText={touched.priorityName && errors.priorityName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Short Name"
                                    name="shortName"
                                    fieldLabel="Short Name"
                                    required
                                    value={values.shortName}
                                    onChange={handleChange}
                                    error={touched.shortName && !!errors.shortName}
                                    helperText={touched.shortName && errors.shortName}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdatePriorities;
