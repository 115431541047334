import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createcompany from "../components/company/createcompany";
import UpdateClientCompany from "../components/company/UpdateClientCompany";
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getStates, getCities, getCompanyTypes, getCompanyOwnershipType, getOwner } from '../services/CommonServices';
import { Delete_clientCompanies, GET_clientCompanies } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import CustomLoader from "../components/common/CustomLoader";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../components/common/CustomButton";
import CustomTextField from "../components/common/CustomTextField";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import CustomFilter from "../components/common/CustomFilter";
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { genericPost } from "../services/authServices";

const title = 'Company';

const Company = () => {
  const { token } = useContext(AuthContext)
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
  const [clientCompanies, setClientCompanies] = useState([]);
  const [owner, setOwner] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })
  const [getAllLead, setGetAllLead] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [industryType, setIndustryType] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [rating, setRating] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [companyOwnershipTypes, setCompanyOwnershipTypes] = useState([]);
  const [filterPayload, setFilterPayload] = useState({
    clientCompanyName: '',
    ratingId: '',
    companyTypeId: '',
    ownershipId: '',
    industryTypeId: '',
    businessGroupId: '',
    billingCountryId: '',
    fromDate: '',
    toDate: ''

  });

  const filterOpen = () => setOpenDrawer(true);
  const filterClose = () => setOpenDrawer(false);



  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleUpdateClick = (clientCompanyId) => {
    setSelectedId(clientCompanyId); // Updated to use selectedId
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };

  const handleDelete = async (clientCompanyId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(Delete_clientCompanies(clientCompanyId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchClientCompanies();
        setDeleteAlert(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting client company", error);
    }
  };
  const handleFilterChange = (field, value) => {
    setFilterPayload((prev) => ({
      ...prev,
      [field]: value || '',
    }));
  };
  const handleClearFilters = () => {
    setFilterPayload({
      clientCompanyId: '',
      leadSourceId: '',
      leadStatusId: '',
      industry: '',
      businessGroup: '',
      countryId: '',
      fromDate: '',
      toDate: ''
    });
  };
  useEffect(() => {
    fetchClientCompanies()
  }, [token])

  useEffect(() => {
    getCommonData();
  }, []);

  const columns = useMemo(
    () => [
      { accessorKey: "clientCompanyId", header: "SN.", size: 50 },
      { accessorKey: "clientCompanyName", header: "Company", size: 150 },
      { accessorKey: "country", header: "Country", size: 100 },
      { accessorKey: "mobile", header: "Contact No.", size: 150 },
      { accessorKey: "email", header: "Email ID", size: 200 },
      { accessorKey: "Contacts", header: "Company Admin", size: 100 },
      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton>
            <IconButton onClick={() => handleUpdateClick(row.original.clientCompanyId)}>
              <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteAlert(row.original.clientCompanyId)}>
              <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );

  const fetchClientCompanies = async () => {
    setLoading(true);
    try {
      // const response = await genericGet({ url: GET_clientCompanies });
      const response = await genericPost(GET_clientCompanies, filterPayload, {});

      if (response.data.success && response.data.clientCompanies) {
        const clientCompanyData = response.data.clientCompanies.map((company) => ({
          clientCompanyId: company.clientCompanyId || '',
          clientCompanyName: company.clientCompanyName || '',
          phone: company.phone || '',
          mobile: company.mobile || '',
          email: company.email || '',
          address: company.address || '',
          country: company.mailingCountry?.name || '',
          Contacts: company.companyAdmin?.firstName || '',
          Contacts: `${company.companyAdmin?.firstName || ''} ${company.companyAdmin?.lastName || ''}`,

        }));
        setClientCompanies(clientCompanyData);
      } else {
        setError('No client companies found');
      }
    } catch (error) {
      setError('Error fetching client companies');
      console.error('Error fetching client companies', error);
    } finally {
      setLoading(false);
    }
  };


  // const getCommonData = async () => {
  //   try {
  //     const [industryTypeData, ratingData, conutriesData] = await Promise.all([
  //       getIndustryType(),
  //       getRating(),
  //       getCountries()
  //     ]);

  //     setIndustryType(industryTypeData);
  //     setRating(ratingData);
  //     setConutries(conutriesData);
  //   } catch (error) {
  //     console.error('Error fetching common data', error);
  //   }
  // };
  const getCommonData = async () => {

    const industryTypeData = await getIndustryType();
    setIndustryType(industryTypeData);

    const ratingData = await getRating();
    setRating(ratingData);

    const countriesData = await getCountries();
    setCountries(countriesData);

    const businessGroupData = await getBusinessGroup();
    setBusinessGroup(businessGroupData);
    const owner = await getOwner();
    setOwner(owner);
    const companyTypesData = await getCompanyTypes();
    setCompanyTypes(companyTypesData);

    const companyOwnershipData = await getCompanyOwnershipType();
    setCompanyOwnershipTypes(companyOwnershipData);
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (clientCompanyId) => {
    // navigate(`/app/company/${clientCompanyId}`);
    navigate(`/app/companyview/${clientCompanyId}`);
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={clientCompanies}
          handleOpen={handleOpen}
          buttonTitle="Create"
          handleFilterButton={filterOpen}
          fiterButtonTitile="Fiter"

        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createcompany
            handleClose={handleClose}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateClientCompany
            handleClose={handleCloseUpdate}
            getAllLead={getAllLead.find((v) => v.leadId == selectedId)}
            clientCompanyId={selectedId}
            fetchClientCompanies={fetchClientCompanies}
            setMessage={setMessage}
            owner={owner}
            industryType={industryType}
            businessGroups={businessGroup}
            rating={rating}
            countries={countries}
            states={states}
            cities={cities}
            companyTypes={companyTypes}
            companyOwnershipTypes={companyOwnershipTypes}
          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
        <CustomFilter
          drawerTitle="Filter Options"
          drawerWidth={350}
          openDrawer={openDrawer}
          onClose={filterClose}
          drawerContent={
            <>
              <Box>
                <Grid container spacing={1} sx={{ padding: 0, }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Company</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      placeholder="Search Company"
                      type="text"
                      value={filterPayload.clientCompanyName}
                      onChange={(e) => handleFilterChange('clientCompanyName', e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Country</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={countries}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Country"
                      onChange={(event, newValue) => handleFilterChange("billingCountryId", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>company Types </Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={companyTypes}
                      getOptionLabel={(option) => option.title}
                      placeholder="company Types"
                      onChange={(event, newValue) => handleFilterChange("companyTypeId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>Business Group</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={businessGroup}
                      getOptionLabel={(option) => option.title}
                      placeholder="Business Group"
                      onChange={(event, newValue) => handleFilterChange("businessGroupId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>Industry Type</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={industryType}
                      getOptionLabel={(option) => option.title}
                      placeholder="Industry Type"
                      onChange={(event, newValue) => handleFilterChange("industryTypeId", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>Ownership</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={companyOwnershipTypes}
                      getOptionLabel={(option) => option.title}
                      placeholder="Ownership"
                      onChange={(event, newValue) => handleFilterChange("ownershipId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>Rating</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={rating}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Rating"
                      onChange={(event, newValue) => handleFilterChange("ratingId", newValue?.id)}

                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>From Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.fromDate}
                      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                      placeholder="From Date"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>To Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.toDate}
                      onChange={(e) => handleFilterChange('toDate', e.target.value)}
                      placeholder="To Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "50px" }}>
                    <CustomButton
                      startIcon={<FindReplaceOutlinedIcon />}
                      title="Apply"
                      size="small"
                      variant="contained"
                      onClick={fetchClientCompanies}
                    />
                    <CustomButton
                      variant="outlined"
                      title="Clear"
                      startIcon={<ClearRounded />}
                      style={cif_fob_style}
                      onClick={handleClearFilters}
                    />

                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />

      </Box>
    </>
  );
};

export default Company;

const filterLabelStyle = {
  fontSize: '14px',
  color: "#212529",
  paddingTop: "10px",
  textTransform: "capitalize"
}
const cif_fob_style = {
  backgroundColor: '#ffff',
  color: '#007bff',
  textTransform: "capitalize",
  borderRadius: 4,
  border: '1px solid #007bff',
  height: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100px"

}
