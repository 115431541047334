import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_EXPORT_SHIPMENT_COSTING } from "../../services/apiRoutes";
const title = "Export Shipment Costing Report";

const ExportShipmentCostingReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'commercialInvExpId', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'piNo', header: 'PI No.', size: 100 },
            { accessorKey: 'customerName', header: 'Customer Name', size: 150 },
            { accessorKey: 'country', header: 'Country', size: 100 },
            { accessorKey: 'noOfContainer', header: 'No. Of Container', size: 150 },
            { accessorKey: 'totalQty', header: 'Total Qty', size: 100 },
            { accessorKey: 'totalAmount', header: 'Total Cost', size: 100 },
            { accessorKey: 'coastPerUnit', header: 'Cost per Unit', size: 100 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.customerNamesId)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const handleViewClick = (customerNamesId) => {
        navigate(`/app/reports/exportshipmentcostingreport/${customerNamesId}`);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_EXPORT_SHIPMENT_COSTING });
            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice) => {
                    const totalQty = invoice.particulars.reduce((acc, item) => acc + item.qty, 0);
                    const totalAmount = invoice.particulars.reduce((acc, item) => acc + (item.qty * parseFloat(item.rate)), 0);
                    const costPerUnit = totalQty > 0 ? (totalAmount / totalQty).toFixed(2) : '0.00';

                    return {
                        commercialInvExpId: invoice.commercialInvExpId || '',
                        customerNamesId: invoice.customerNamesId || '',
                        customerName: invoice.customer.clientCompanyName || '',
                        country: invoice.customer.mailingCountry.name || '',
                        reference: invoice.reference || '',
                        proformaInvoiceNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        upload: invoice.ciUpload || '',
                        totalQty,
                        totalAmount: totalAmount.toFixed(2),
                        coastPerUnit: costPerUnit,
                        particulars: invoice.particulars.map(({
                            particularName: name = '',
                            qty = '',
                            rate = '',
                            unit = '',
                            description = ''
                        }) => ({
                            name, qty, rate, unit, description
                        }))
                    };
                });

                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [token])


    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default ExportShipmentCostingReport;
