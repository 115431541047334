
// import { useState, useEffect } from 'react';
// import axios from 'axios';
// import { READ_EXPORT_SHIPMENT } from '../../../../services/apiRoutes';

// export const useExportShipmentDetails = (exportShipmentId) => {
//     const [shipmentDetails, setShipmentDetails] = useState(null);

//     useEffect(() => {
//         const authToken = sessionStorage.getItem('accessToken');

//         axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
//             headers: {
//                 Authorization: `Bearer ${authToken}`,
//             }
//         })
//             .then(response => {
//                 setShipmentDetails(response.data.shipment);
//             })
//             .catch(error => {
//                 console.error('Error fetching shipment details:', error);
//             });
//     }, [exportShipmentId]);

//     return { shipmentDetails };
// };

import { useState, useEffect } from 'react';
import axios from 'axios';
import { READ_EXPORT_SHIPMENT } from '../../../../services/apiRoutes';

// Custom hook to fetch shipment details
export const useExportShipmentDetails = (exportShipmentId) => {
    const [shipmentDetails, setShipmentDetails] = useState(null);
    const [loading, setLoading] = useState(true); // State for loading
    const [error, setError] = useState(null); // State for error handling

    useEffect(() => {
        const fetchShipmentDetails = async () => {
            const authToken = sessionStorage.getItem('accessToken');

            try {
                const response = await axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                setShipmentDetails(response.data.shipment);
            } catch (err) {
                console.error('Error fetching shipment details:', err);
                setError('Failed to fetch shipment details. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        if (exportShipmentId) {
            fetchShipmentDetails();
        }
    }, [exportShipmentId]);

    return { shipmentDetails, loading, error };
};



