import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, Container, Grid, Typography } from '@mui/material';
import { Divider, styled } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Save } from '@mui/icons-material';
import CustomTextField from '../common/CustomTextField';
import { TextField, InputAdornment } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../common/CustomAutoComplete';
import { CircularProgress } from '@mui/material';
import { SaveRounded } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GET_COMPANY, UPDATE_COMPANY } from '../../services/apiRoutes';
import { genericGet } from '../../services/apiServices';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import MessageBox from '../common/CustomMessageBox';

const SystemCompany = () => {
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const { token } = useContext(AuthContext);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const fetchCompanies = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY });
            const companyData = response?.data?.companies || [];
            const defaultCompanies = companyData.filter((company) => company.isDefault);

            const defaultCompany = defaultCompanies[0] || null;
            const getCompanyId = defaultCompany?.companyId || null;
            setCompanies(defaultCompanies);
            setCompanyId(getCompanyId);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    useEffect(() => {
        fetchCompanies();
    }, [token]);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const formData = new FormData();
            Object.keys(values).forEach((key) => formData.append(key, values[key]));
            await axios.put(UPDATE_COMPANY(companyId), formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage({
                open: true,
                text: 'Successfully updated',
                type: 'success'
            });
            fetchCompanies();
        } catch (error) {
            console.error('Error updating company:', error);
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };
    const StyledButton = ({ isActive, onClick, children, activeColor, deactiveColor, style = {},
    }) => (
        <button
            type="submit"
            onClick={onClick}
            style={{
                ...style,
                backgroundColor: isActive ? activeColor : deactiveColor,
                color: '#fff',
                border: 'none',
                padding: '8px 16px',
                cursor: 'pointer',
            }}
        >
            {children}
        </button>
    );
    return (
        <>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <Container >
                <Box >
                    <Formik
                        initialValues={{
                            companyName: companies[0]?.companyName || '',
                            email: companies[0]?.email || '',
                            userId: companies[0]?.userId || '',
                            purchasePrefix: companies[0]?.purchasePrefix || '',
                            purchaseSuffix: companies[0]?.purchaseSuffix || '',
                            purchasePrefixActivity: companies[0]?.purchasePrefixActivity.toString() || '0',
                            purchaseSuffixActivity: companies[0]?.purchaseSuffixActivity.toString() || '0',
                            financialYearStart: companies[0]?.financialYearStart || '',
                            financialYearEnd: companies[0]?.financialYearEnd || '',

                            impPaymentPrefix: companies[0]?.impPaymentPrefix || '',
                            impPaymentSuffix: companies[0]?.impPaymentSuffix || '',
                            impPaymentPrefixActivity: companies[0]?.impPaymentPrefixActivity.toString() || '0',
                            impPaymentSuffixActivity: companies[0]?.impPaymentSuffixActivity.toString() || '0',

                            impExpensePrefix: companies[0]?.impExpensePrefix || '',
                            impExpenseSuffix: companies[0]?.impExpenseSuffix || '',
                            impExpensePrefixActivity: companies[0]?.impExpensePrefixActivity.toString() || '0',
                            impExpenseSuffixActivity: companies[0]?.impExpenseSuffixActivity.toString() || '0',

                            impReceiptPrefix: companies[0]?.impReceiptPrefix || '',
                            impReceiptSuffix: companies[0]?.impReceiptSuffix || '',
                            impReceiptPrefixActivity: companies[0]?.impReceiptPrefixActivity.toString() || '0',
                            impReceiptSuffixActivity: companies[0]?.impReceiptSuffixActivity.toString() || '0',

                            expSalesPrefix: companies[0]?.expSalesPrefix || '',
                            expSalesSuffix: companies[0]?.expSalesSuffix || '',
                            expSalesPrefixActivity: companies[0]?.expSalesPrefixActivity.toString() || '0',
                            expSalesSuffixActivity: companies[0]?.expSalesSuffixActivity.toString() || '0',

                            expProformaPrefix: companies[0]?.expProformaPrefix || '',
                            expProformaSuffix: companies[0]?.expProformaSuffix || '',
                            expProformaPrefixActivity: companies[0]?.expProformaPrefixActivity.toString() || '0',
                            expProformaSuffixActivity: companies[0]?.expProformaSuffixActivity.toString() || '0',

                            expContractPrefix: companies[0]?.expContractPrefix || '',
                            expContractSuffix: companies[0]?.expContractSuffix || '',
                            expContractPrefixActivity: companies[0]?.expContractPrefixActivity.toString() || '0',
                            expContractSuffixActivity: companies[0]?.expContractSuffixActivity.toString() || '0',

                            expCommercialPrefix: companies[0]?.expCommercialPrefix || '',
                            expCommercialSuffix: companies[0]?.expCommercialSuffix || '',
                            expCommercialPrefixActivity: companies[0]?.expCommercialPrefixActivity.toString() || '0',
                            expCommercialSuffixActivity: companies[0]?.expCommercialSuffixActivity.toString() || '0',

                            expExpensePrefix: companies[0]?.expExpensePrefix || '',
                            expExpenseSuffix: companies[0]?.expExpenseSuffix || '',
                            expExpensePrefixActivity: companies[0]?.expExpensePrefixActivity.toString() || '0',
                            expExpenseSuffixActivity: companies[0]?.expExpenseSuffixActivity.toString() || '0',

                            expReceiptPrefix: companies[0]?.expReceiptPrefix || '',
                            expReceiptSuffix: companies[0]?.expReceiptSuffix || '',
                            expReceiptPrefixActivity: companies[0]?.expReceiptPrefixActivity.toString() || '0',
                            expReceiptSuffixActivity: companies[0]?.expReceiptSuffixActivity.toString() || '0',

                            expPaymentPrefix: companies[0]?.expPaymentPrefix || '',
                            expPaymentSuffix: companies[0]?.expPaymentSuffix || '',
                            expPaymentPrefixActivity: companies[0]?.expPaymentPrefixActivity.toString() || '0',
                            expPaymentSuffixActivity: companies[0]?.expPaymentSuffixActivity.toString() || '0',

                            expCoaPrefix: companies[0]?.expCoaPrefix || '',
                            expCoaSuffix: companies[0]?.expCoaSuffix || '',
                            expCoaPrefixActivity: companies[0]?.expCoaPrefixActivity.toString() || '0',
                            expCoaSuffixActivity: companies[0]?.expCoaSuffixActivity.toString() || '0',

                            expPackingPrefix: companies[0]?.expPackingPrefix || '',
                            expPackingSuffix: companies[0]?.expPackingSuffix || '',
                            expPackingPrefixActivity: companies[0]?.expPackingPrefixActivity.toString() || '0',
                            expPackingSuffixActivity: companies[0]?.expPackingSuffixActivity.toString() || '0',
                        }}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                            <Form>
                                <div>
                                    <Accordion style={{ boxShadow: 'none' }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                                            Import Shipment Prefix and suffix Setting
                                        </AccordionSummary>
                                        <AccordionDetails style={{ marginTop: "-10px" }} >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    Purchase Order
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Prefix"
                                                                    name="purchasePrefix"
                                                                    value={values.purchasePrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.purchasePrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'purchasePrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.purchasePrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'purchasePrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Suffix"
                                                                    name="purchaseSuffix"
                                                                    value={values.purchaseSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.purchaseSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'purchaseSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.purchaseSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'purchaseSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Payment Voucher</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Payment Prefix"
                                                                    name="impPaymentPrefix"
                                                                    value={values.impPaymentPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impPaymentPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impPaymentPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impPaymentPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impPaymentPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Payment Suffix"
                                                                    name="impPaymentSuffix"
                                                                    value={values.impPaymentSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impPaymentSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impPaymentSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impPaymentSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impPaymentSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Expense Voucher</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Expense Prefix"
                                                                    name="impExpensePrefix"
                                                                    value={values.impExpensePrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impExpensePrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impExpensePrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impExpensePrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impExpensePrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Expense Suffix"
                                                                    name="impExpenseSuffix"
                                                                    value={values.impExpenseSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impExpenseSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impExpenseSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impExpenseSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impExpenseSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Receipt Voucher</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Receipt Prefix"
                                                                    name="impReceiptPrefix"
                                                                    value={values.impReceiptPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impReceiptPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impReceiptPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impReceiptPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impReceiptPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Receipt Suffix"
                                                                    name="impReceiptSuffix"
                                                                    value={values.impReceiptSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.impReceiptSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'impReceiptSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.impReceiptSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'impReceiptSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ fontWeight: "600", fontSize: "14px" }}>
                                            Export Shipment Prefix and suffix Setting
                                        </AccordionSummary>
                                        <AccordionDetails style={{ marginTop: "-10px" }} >
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    Sales Order
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Prefix"
                                                                    name="expSalesPrefix"
                                                                    value={values.expSalesPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expSalesPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expSalesPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expSalesPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expSalesPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Suffix"
                                                                    name="expSalesSuffix"
                                                                    value={values.expSalesSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expSalesSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expSalesSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expSalesSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expSalesSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Proforma Invoice</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Prefix"
                                                                    name="expProformaPrefix"
                                                                    value={values.expProformaPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expProformaPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expProformaPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expProformaPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expProformaPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Suffix"
                                                                    name="expProformaSuffix"
                                                                    value={values.expProformaSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expContractSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expContractSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expContractSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expContractSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Commercial Invoice</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Prefix"
                                                                    name="expCommercialPrefix"
                                                                    value={values.expCommercialPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expCommercialPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expCommercialPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expCommercialPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expCommercialPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Suffix"
                                                                    name="expCommercialSuffix"
                                                                    value={values.expCommercialSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expCommercialSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expCommercialSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expCommercialSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expCommercialSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Expense Voucher</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Prefix"
                                                                    name="expExpensePrefix"
                                                                    value={values.expExpensePrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expExpensePrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expExpensePrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expExpensePrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expExpensePrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Suffix"
                                                                    name="expExpenseSuffix"
                                                                    value={values.expExpenseSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expExpenseSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expExpenseSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expExpenseSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expExpenseSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    Receipt Voucher
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Prefix"
                                                                    name="expReceiptPrefix"
                                                                    value={values.expReceiptPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expReceiptPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expReceiptPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expReceiptPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expReceiptPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter Suffix"
                                                                    name="expReceiptSuffix"
                                                                    value={values.expReceiptSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expReceiptSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expReceiptSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expReceiptSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expReceiptSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Payment Voucher</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Prefix"
                                                                    name="expPaymentPrefix"
                                                                    value={values.expPaymentPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expPaymentPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expPaymentPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expPaymentPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expPaymentPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Suffix"
                                                                    name="expPaymentSuffix"
                                                                    value={values.expPaymentSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expPaymentSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expPaymentSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expPaymentSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expPaymentSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>COA </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Prefix"
                                                                    name="expCoaPrefix"
                                                                    value={values.expCoaPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expCoaPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expCoaPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expCoaPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expCoaPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter  Suffix"
                                                                    name="expCoaSuffix"
                                                                    value={values.expCoaSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expCoaSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expCoaSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expCoaSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expCoaSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>Packing List</div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px' }}>
                                                        <Box>Prefix</Box>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '8px', paddingTop: "20px" }}>
                                                        <Box>Suffix</Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter   Prefix"
                                                                    name="expPackingPrefix"
                                                                    value={values.expPackingPrefix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expPackingPrefixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expPackingPrefixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expPackingPrefixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expPackingPrefixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div style={styles.container}>
                                                            <Box>
                                                                <CustomTextField
                                                                    placeholder="Enter   Suffix"
                                                                    name="expPackingSuffix"
                                                                    value={values.expPackingSuffix}
                                                                    onChange={handleChange}
                                                                />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    paddingLeft: "10px"
                                                                }}
                                                            >
                                                                <StyledButton
                                                                    isActive={values.expPackingSuffixActivity === '1'}
                                                                    onClick={() => handleChange({ target: { name: 'expPackingSuffixActivity', value: '1' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "4px 0 0 4px" }}
                                                                >
                                                                    Yes
                                                                </StyledButton>
                                                                <StyledButton
                                                                    isActive={values.expPackingSuffixActivity === '0'}
                                                                    onClick={() => handleChange({ target: { name: 'expPackingSuffixActivity', value: '0' } })}
                                                                    activeColor="#007BFF"
                                                                    deactiveColor="#D50000"
                                                                    style={{ borderRadius: "0px 4px 4px 0px" }}
                                                                >
                                                                    No
                                                                </StyledButton>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div>
                                    <Card style={{ boxShadow: 'none', marginTop: "5px" }} sx={{ border: '1px solid #ccc', borderRadius: '4px', paddingLeft: "12px", paddingRight: "12px" }}>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={7}>
                                                <div>
                                                    <h3 style={styles.heading}>Financial Year</h3>
                                                    <div style={styles.container}>
                                                        <div style={styles.details}>
                                                            Current Period
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                gap: "5px",
                                                            }}
                                                        >
                                                            <CustomTextField
                                                                name="financialYearStart"
                                                                required={true}
                                                                type="date"
                                                                value={values.financialYearStart}
                                                                onChange={handleChange}
                                                                placeholder="Enter financialYearStart Date"
                                                                error={touched.financialYearStart && !!errors.financialYearStart}
                                                                helperText={touched.financialYearStart && errors.financialYearStart}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                            <span >-</span>
                                                            <CustomTextField
                                                                name="financialYearEnd"
                                                                required={true}
                                                                type="date"
                                                                value={values.financialYearEnd}
                                                                onChange={handleChange}
                                                                placeholder="Enter financialYearEnd Date"
                                                                error={touched.financialYearEnd && !!errors.financialYearEnd}
                                                                helperText={touched.financialYearEnd && errors.financialYearEnd}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <div>
                                                    <h3 style={styles.heading}>Date Formate</h3>
                                                    <div style={styles.container}>
                                                        <div style={styles.details}>
                                                            Date Formate
                                                        </div>
                                                        <div style={{ display: "flex", }}>
                                                            <CustomAutocomplete
                                                                options={dateFormats}
                                                                required
                                                                getOptionLabel={(option) => option.title}
                                                                placeholder="Select Date"
                                                                style={{ width: "200px" }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title='Save'
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                    </CardActions>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    {/* <Grid container spacing={2} sx={{ mt: 4 }}>
                        {companies.map((company) => (
                            <Grid item xs={12} sm={6} md={12} key={company.companyId}>
                                <Typography>Company ID: {company.companyId}</Typography>
                                <Typography>Company Name: {company.companyName}</Typography>
                                <Typography>User ID: {company.userId}</Typography>
                            </Grid>
                        ))}
                    </Grid> */}
                </Box>
            </Container >
        </>
    );
};

export default SystemCompany;
const dateFormats = [
    { id: 1, title: 'DD/MM/YYYY' },
    { id: 2, title: 'MM/DD/YYYY' },
    { id: 3, title: 'YYYY/MM/DD' },
];

const styles = {
    heading: {
        marginBottom: '1px',
        fontSize: '14px'
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1px'
    },
    details: {
        flex: 1,
        marginRight: '1px'
    }
};
