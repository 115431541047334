import { Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { UPDATE_COA_EXPORT, READ_COA_EXPORT } from '../../../services/apiRoutes';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import IconButton from '@mui/material/IconButton';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import Card from '@mui/material/Card';
import CustomAutocomplete from '../../common/CustomAutoComplete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import CustomStyle from '../../common/CustomStyle';


const ValidationSchema = Yup.object().shape({
    toAddress: Yup.string().required("To Address is required"),
    invoiceNo: Yup.string().required("Invoice No. is required"),
    date: Yup.date().required("Date is required"),
    loadingPort: Yup.string().required("Loading Port is required"),
    destinationPort: Yup.string().required("Destination Port is required"),
    productName: Yup.string().required("Product Name is required"),
    conculation: Yup.string().required("Conclusion is required"),
    analyst: Yup.string().required("Analyst is required"),
    examiner: Yup.string().required("Examiner is required"),
    approved: Yup.string().required("Approval status is required"),
    casNo: Yup.string().required("CAS No. is required"),
    iupac: Yup.string().required("IUPAC No. is required"),
    particulars: Yup.array()
        .of(
            Yup.object().shape({
                items: Yup.string().required("Item name is required"),
                standard: Yup.string().required("Standard is required"),
                result: Yup.string().required("Result is required"),
                levelId: Yup.number().required("Level  is required"),
            })
        )
        .required("Particulars are required"),
});

const UpdateCOA = ({ handleClose, setMessage, fetchData, coaId, companyName, phoneNumber, productsList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch data for the commercial invoice
    useEffect(() => {
        if (!coaId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_COA_EXPORT(coaId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [coaId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_COA_EXPORT(coaId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Proforma Invoice updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    toAddress: data?.toAddress || '',
                    invoiceNo: data?.invoiceNo || '',
                    date: data?.date || '',
                    loadingPort: data?.loadingPort || '',
                    destinationPort: data?.destinationPort || '',
                    productName: parseInt(data?.productName || ''),
                    conculation: data?.conculation || '',
                    analyst: data?.analyst || '',
                    examiner: data?.examiner || '',
                    approved: data?.approved || '',
                    casNo: data?.casNo || '',
                    iupac: data?.iupac || '',
                    particulars: data?.particulars || [{
                        items: '',
                        standard: '',
                        result: '',
                        levelId: '',
                    }],
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update COA
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save And New"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                    <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "15px", textTransform: "uppercase" }}>
                                        Certification Of Analysis
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} >
                                    <Grid xs={2} sm={2} md={2} lg={2}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            To:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextarea
                                            name="toAddress"
                                            readOnly={true}
                                            value={values.toAddress}
                                            onChange={handleChange}
                                            error={touched.toAddress && !!errors.toAddress}
                                            helperText={touched.toAddress && errors.toAddress}
                                            placeholder="Enter Means Of Transport And Route"
                                            sx={{ height: '85px !important', }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                    <Box sx={{ width: '130px' }}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                            Inv. No:
                                        </Typography>
                                    </Box>
                                    <CustomTextField
                                        name="invoiceNo"
                                        required={true}
                                        value={values.invoiceNo}
                                        onChange={handleChange}
                                        error={touched.invoiceNo && !!errors.invoiceNo}
                                        helperText={touched.invoiceNo && errors.invoiceNo}
                                        placeholder="Enter Inv. No."
                                        sx={{ width: "200px !important" }}

                                    />
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                    <Box sx={{ width: '130px' }}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                            Date:
                                        </Typography>
                                    </Box>
                                    <CustomTextField
                                        type="date"
                                        placeholder="Enter Date"
                                        name="date"
                                        required={true}
                                        value={values.date}
                                        onChange={handleChange}
                                        error={touched.date && !!errors.date}
                                        helperText={touched.date && errors.date}
                                        sx={{ width: "200px !important" }}

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} >
                                    <Grid xs={4} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            Loading Port:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextField
                                            name="loadingPort"
                                            required={true}
                                            value={values.loadingPort}
                                            onChange={handleChange}
                                            error={touched.loadingPort && !!errors.loadingPort}
                                            helperText={touched.loadingPort && errors.loadingPort}
                                            placeholder="Enter Loading Port"

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} style={{ marginLeft: "5px" }}>
                                    <Grid xs={4} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            Destination Port:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextField
                                            name="destinationPort"
                                            required={true}
                                            value={values.destinationPort}
                                            onChange={handleChange}
                                            error={touched.destinationPort && !!errors.destinationPort}
                                            helperText={touched.destinationPort && errors.destinationPort}
                                            placeholder="Enter Destination Port"
                                            sx={{ width: "200px !important" }}

                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} >
                                    <Grid xs={4} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            Product Name:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomAutocomplete
                                            options={productsList}
                                            required={true}
                                            getOptionLabel={(option) => option.productName}
                                            value={productsList.find((option) => option.productsId === values.productName) || null}
                                            onChange={(e, newValue) =>
                                                setFieldValue('productName', newValue ? newValue.productsId : '')
                                            }
                                            placeholder="Select Product Name"
                                            error={touched.productName && !!errors.productName}
                                            helperText={touched.productName && errors.productName}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{ overflowX: 'auto', overflowY: 'auto' }}>
                                            <Table style={{ width: "900px" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>SN.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "200px" }} rowSpan={2}>Items</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "200px" }} rowSpan={2}>Standard</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "200px" }} rowSpan={2}>Result</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "300px" }} rowSpan={2}>Level</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "30px" }} rowSpan={2}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.particulars.map((particular, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="items"
                                                                    name={`particulars[${index}].items`}
                                                                    value={particular.items}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.items && !!errors.particulars?.[index]?.items}
                                                                    helperText={touched.particulars?.[index]?.items && errors.particulars?.[index]?.items}
                                                                />

                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="standard"
                                                                    name={`particulars[${index}].standard`}
                                                                    value={particular.standard}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.standard && !!errors.particulars?.[index]?.standard}
                                                                    helperText={touched.particulars?.[index]?.standard && errors.particulars?.[index]?.standard}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="result"
                                                                    name={`particulars[${index}].result`}
                                                                    value={particular.result}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.result && !!errors.particulars?.[index]?.result}
                                                                    helperText={touched.particulars?.[index]?.result && errors.particulars?.[index]?.result}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomAutocomplete
                                                                    options={levelIdOptions}
                                                                    required={true}
                                                                    getOptionLabel={(option) => option.title}
                                                                    value={levelIdOptions.find((option) => option.id === particular.levelId) || null}
                                                                    onChange={(e, newValue) =>
                                                                        setFieldValue(`particulars[${index}].levelId`, newValue ? newValue.id : '')
                                                                    }
                                                                    placeholder="Select Level"
                                                                    error={touched.particulars?.[index]?.levelId && !!errors.particulars?.[index]?.levelId}
                                                                    helperText={touched.particulars?.[index]?.levelId && errors.particulars?.[index]?.levelId}
                                                                />


                                                            </TableCell>


                                                            <TableCell style={styles.tablecell}>
                                                                <IconButton onClick={() => arrayHelpers.remove(index)} color="error">
                                                                    <DeleteRounded />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>

                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={7}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                sx={Additemstyle}
                                                                startIcon={<AddRounded />}
                                                                title="Add Item"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({
                                                                    items: '',
                                                                    standard: '',
                                                                    result: '',
                                                                    levelId: ''
                                                                })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>

                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        Conculation:
                                    </Typography>
                                    <CustomTextField
                                        name="conculation"
                                        required={true}
                                        value={values.conculation}
                                        onChange={handleChange}
                                        error={touched.conculation && !!errors.conculation}
                                        helperText={touched.conculation && errors.conculation}
                                        placeholder="Enter Conculation"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        Analyst:
                                    </Typography>
                                    <CustomTextField
                                        placeholder="Enter Analyst"
                                        name="analyst"
                                        required={true}
                                        value={values.analyst}
                                        onChange={handleChange}
                                        error={touched.analyst && !!errors.analyst}
                                        helperText={touched.analyst && errors.analyst}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        Examiner:
                                    </Typography>
                                    <CustomTextField
                                        placeholder="Enter Examiner"
                                        name="examiner"
                                        required={true}
                                        value={values.examiner}
                                        onChange={handleChange}
                                        error={touched.examiner && !!errors.examiner}
                                        helperText={touched.examiner && errors.examiner}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        Approved:
                                    </Typography>
                                    <CustomTextField
                                        name="approved"
                                        required={true}
                                        value={values.approved}
                                        onChange={handleChange}
                                        error={touched.approved && !!errors.approved}
                                        helperText={touched.approved && errors.approved}
                                        placeholder="Enter Approved"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        CAS No:
                                    </Typography>
                                    <CustomTextField
                                        placeholder="Enter CAS No"
                                        name="casNo"
                                        required={true}
                                        value={values.casNo}
                                        onChange={handleChange}
                                        error={touched.casNo && !!errors.casNo}
                                        helperText={touched.casNo && errors.casNo}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                        IUPAC:
                                    </Typography>
                                    <CustomTextField
                                        placeholder="Enter IUPAC"
                                        name="iupac"
                                        required={true}
                                        value={values.iupac}
                                        onChange={handleChange}
                                        error={touched.iupac && !!errors.iupac}
                                        helperText={touched.iupac && errors.iupac}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateCOA;

const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,  // Width of the box
        height: 70, // Height of the box, making it square
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};


const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};

const levelIdOptions = [
    { id: 1, title: 'Pass' },
    { id: 2, title: 'Fail' },
];