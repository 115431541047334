import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const CustomStatus = ({ title, sx }) => {
    return (
        <Box
            sx={{
                display: 'inline-flex',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                borderRadius: '4px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                backgroundColor: "#FCFFC1",
                color: "#3C2A21",
                border: 'none',
                textTransform: "capitalize",
                height: "25px",
                padding: "2px 6px 2px 6px",
                // width: "180px",
                width: 'fit-content',
                zIndex: -1,
                ...sx,
            }}
        >
            {title}
        </Box>
    );
};

CustomStatus.propTypes = {
    title: PropTypes.string.isRequired,
    sx: PropTypes.object,
};

CustomStatus.defaultProps = {
    sx: {},
};

export default CustomStatus;
