import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import { genericGet } from "../../services/apiServices";
import { GET_IMPORT_PAYMENT_REPORT } from "../../services/apiRoutes";
const title = "Import Payment Report";

const ImportPaymentReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: 'sn', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 100 },
            { accessorKey: 'piNo', header: 'PI No.', size: 100 },
            { accessorKey: 'supplierName', header: 'Supplier Name', size: 150 },
            { accessorKey: 'mailingCountry', header: 'Country', size: 100 },
            { accessorKey: 'amount', header: 'Advance Amount Paid', size: 150 },
            { accessorKey: 'conversionRate', header: 'Conversion Rate', size: 150 },
            { accessorKey: 'amountInBaseCurrency', header: 'Amount in Base Currency', size: 200 },
            { accessorKey: 'finalAmountPaid', header: 'Final Amount Paid', size: 150 },
            { accessorKey: 'conversionRateFinal', header: 'Conversion Rate (Final)', size: 150 },
            { accessorKey: 'sf', header: 'Amount in Base Currency (Final)', size: 200 },
            { accessorKey: 'shipmentStatus', header: 'Shipment Status', size: 150 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_IMPORT_PAYMENT_REPORT });
            if (response.data.success && response.data.paymentVoucher) {
                const paymentVoucherData = response.data.paymentVoucher.map((voucher) => ({
                    sn: voucher.paymentVoucherId || '',
                    date: voucher.date || '',
                    voucherNo: voucher.paymentVoucherNo || '',
                    amountInBaseCurrency: voucher.amountInBaseCurrency || '',
                    amount: voucher.amount || '',
                    supplierName: voucher.clientCompany?.clientCompanyName || '',
                    mailingCountry: voucher.clientCompany?.mailingCountry?.name || '',
                    paymentType: voucher.paymentType?.paymentName || '',
                    conversionRate: voucher.conversionRate ? parseFloat(voucher.conversionRate).toFixed(2) : '',
                    amount1: voucher.amount || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amountInBaseCurrency: voucher.amountInBaseCurrency || '',
                    bankCharges: voucher.bankCharges || '',
                }));
                setData(paymentVoucherData);
            } else {
                setError('No payment vouchers found');
            }
        } catch (error) {
            setError('Error fetching payment vouchers');
            console.error('Error fetching payment vouchers', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default ImportPaymentReport;
