import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_CUSTOMER_LEDGER } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
const title = "Customer Ledger";

const CustomerLedger = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'clientCompanyId', header: 'SN', size: 50 },
            { accessorKey: 'clientCompanyName', header: 'Customer Name', size: 150 },
            { accessorKey: 'country', header: 'Country', size: 100 },
            { accessorKey: 'Contacts', header: 'Contact Person', size: 150 },
            { accessorKey: 'Billing', header: 'Billing Amount', size: 50 },
            { accessorKey: 'Receipt', header: 'Receipt Amount', size: 50 },
            { accessorKey: 'Balance', header: 'Balance Amount', size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const handleViewClick = (clientCompanyId) => {
        navigate(`/app/reports/customerledger/${clientCompanyId}`);
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_CUSTOMER_LEDGER });
            if (response.data.success && response.data.clientCompanies) {
                const clientCompanyData = response.data.clientCompanies.map((company) => ({
                    clientCompanyId: company.clientCompanyId || '',
                    clientCompanyName: company.clientCompanyName || '',
                    phone: company.phone || '',
                    mobile: company.mobile || '',
                    email: company.email || '',
                    address: company.address || '',
                    country: company.mailingCountry?.name || '',
                    Contacts: `${company.companyAdmin?.firstName || ''} ${company.companyAdmin?.lastName || ''}`,

                }));
                setData(clientCompanyData);
            } else {
                setError('No client companies found');
            }
        } catch (error) {
            setError('Error fetching client companies');
            console.error('Error fetching client companies', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default CustomerLedger;
