import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_COMMERCIAL_INVOICE_REGISTER } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";

const title = "Commercial Invoice Register";

const CommercialInvoiceRegister = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'commercialInvExpId', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 50 },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 50 },
            { accessorKey: 'proformaInvoiceNo', header: 'Commercial Invoice', size: 50 },
            { accessorKey: 'customerName', header: 'Customer Name', size: 50 },
            { accessorKey: 'country', header: 'Country', size: 50 },
            { accessorKey: 'totalAmount', header: 'Amount', size: 50 },
            { accessorKey: 'documents', header: 'Documents', size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_COMMERCIAL_INVOICE_REGISTER });
            if (response.data.success) {
                const commercialInvoices = response.data.data.map((invoice) => {
                    const particulars = invoice.particulars.map((particular) => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));

                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);

                    return {
                        commercialInvExpId: invoice.commercialInvExpId || '',
                        userId: invoice.userId || '',
                        companyId: invoice.companyId || '',
                        customerName: invoice.customer.clientCompanyName || '',
                        country: invoice.customer.mailingCountry.name || '',
                        reference: invoice.reference || '',
                        proformaInvoiceNo: invoice.proformaInvoiceNo || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        createdAt: invoice.createdAt || '',
                        updatedAt: invoice.updatedAt || '',
                        particulars,
                        totalAmount: totalAmount || 0,

                    };
                });

                // Update the state with the processed commercial invoices
                setData(commercialInvoices);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />
            </Box>
        </>
    );
};
export default CommercialInvoiceRegister;
