import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, colors, Grid, IconButton, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createimportshipement from "../components/importshipement/createimportshipement";
import UpdateImportshipement from "../components/importshipement/UpdateImportshipement";
import { DELETE_IMPORT_SHIPMENT, LIST_ALL_IMPORT_SHIPMENT } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { formatDate, getCIFFOBList, getCountries, getCurrencyList, getShipmentTypes, getSuppliersList } from "../services/CommonServices";
import { AuthContext } from "../context/AuthContext";
import CustomLoader from "../components/common/CustomLoader";
import CustomFilter from "../components/common/CustomFilter";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import CustomTextField from "../components/common/CustomTextField";
import CustomButton from "../components/common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import CustomStatus from "../components/common/CustomStatus";
import { genericPost } from "../services/authServices";


const title = 'Import Shipment';

const ImportShipment = () => {
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [importShipments, setImportShipments] = useState([]);
  const [cifFobList, setCIFFOBList] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [conutries, setConutries] = useState([]);
  const { token } = useContext(AuthContext)
  const [openDrawer, setOpenDrawer] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  });
  const [filterPayload, setFilterPayload] = useState({
    shipmentId: '',
    supplierId: '',
    cifFobId: '',
    currencyId: '',
    shipmentTypeId: '',
    fromDate: '',
    toDate: '',

  });
  const [activeButton, setActiveButton] = useState(null);  

  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const filterOpen = () => setOpenDrawer(true);
  const filterClose = () => setOpenDrawer(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUpdateClick = (importShipmentId) => {
    setSelectedId(importShipmentId);
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };
  const handleFilterChange = (field, value) => {
    setFilterPayload((prev) => ({
      ...prev,
      [field]: value || '',
    }));
  };

  const handleFOBClick = () => {
    setActiveButton("2");
    setFilterPayload((prev) => ({
      ...prev,
      cifFobId: "1",
    }));
    fetchImportShipments();
  };
  const handleCIFClick = () => {
    setActiveButton("1");
    setFilterPayload((prev) => ({
      ...prev,
      cifFobId: "2",
    }));
    fetchImportShipments();
  };

  const handleClearFilters = () => {
    setFilterPayload({
      shipmentId: '',
      supplierId: '',
      cifFobId: '',
      currencyId: '',
      shipmentTypeId: '',
      fromDate: '',
      toDate: '',
    });
    setActiveButton(null);
  };


  const handleDelete = async (importShipmentId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(DELETE_IMPORT_SHIPMENT(importShipmentId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchImportShipments();
        setDeleteAlert(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting import shipment", error);
    }
  };

  useEffect(() => {
    fetchImportShipments()
  }, [token, filterPayload])

  useEffect(() => {
    getCommonData();
  }, []);

  const getCommonData = async () => {
    const cifFobListData = await getCIFFOBList();
    setCIFFOBList(cifFobListData);
    const shipmentTypesData = await getShipmentTypes();
    setShipmentTypes(shipmentTypesData);
    const currencyListData = await getCurrencyList();
    setCurrencyList(currencyListData);
    const suppliersListData = await getSuppliersList();
    setSuppliersList(suppliersListData);
    const getConutriesData = await getCountries()
    setConutries(getConutriesData)

  }

  const columns = useMemo(
    () => [
      { accessorKey: "importShipmentId", header: "SN.", size: 50 },
      { accessorKey: "createdAt", header: "Date", size: 50 },
      { accessorKey: "shipmentId", header: "Shipment ID", size: 50 },
      { accessorKey: "supplierName", header: "Supplier Name", size: 50 },
      { accessorKey: "countryName", header: "Country", size: 50 },
      { accessorKey: "noOfContainers", header: "No. Of Container", size: 50 },
      {
        accessorKey: "cifFobName",
        header: "CIF/FOB",
        size: 50,
        Cell: ({ row }) => (
          <CustomStatus
            title={row.original.cifFobName}
            sx={{
              backgroundColor: "#B1D690",
              color: "#361001",
              width: "50px",
            }}
          />
        ),
      },

      { accessorKey: "currency", header: "Currency", size: 50 },
      {
        accessorKey: "action",
        header: "Action",
        size: 100,
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => handleViewClickView(row.original.importShipmentId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton>
            {/* <IconButton onClick={() => handleViewClick(row.original.importShipmentId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton> */}
            <IconButton onClick={() => handleUpdateClick(row.original.importShipmentId)}>
              <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteAlert(row.original.importShipmentId)}>
              <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );

  const fetchImportShipments = async () => {
    setLoading(true);
    try {
      const response = await genericPost(LIST_ALL_IMPORT_SHIPMENT, filterPayload, {});
      if (response.data.success && response.data.shipments) {
        const shipmentData = response.data.shipments.map((shipment) => ({
          importShipmentId: shipment.importShipmentId || '',
          supplierId: shipment.supplierId || '',
          shipmentId: shipment.shipmentId || '',
          createdAt: formatDate(shipment.createdAt) || '',
          noOfContainers: shipment.noOfContainers || '',
          cifFobName: shipment.cifFob.cifFobName || '',
          currency: shipment.currency.currency || '',
          supplierName: shipment.supplier.clientCompanyName || '',
          countryName: shipment.supplier.mailingCountry.name || '',
          expectedEtd: shipment.expectedEtd || '',
        }));
        setImportShipments(shipmentData);
      } else {
        setError('No import shipments found');
      }
    } catch (error) {
      setError('Error fetching import shipments');
      console.error('Error fetching import shipments', error);
    } finally {
      setLoading(false);
    }
  };

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (importShipmentId) => {
    navigate(`/app/import/shipement/${importShipmentId}`);
  };

  const handleViewClickView = (importShipmentId) => {
    navigate(`/app/import/shipementview/${importShipmentId}`);
  };

  const filterLabelStyle = {
    fontSize: '14px',
    color: "#212529",
    paddingTop: "10px",
    textTransform: "capitalize"
  }
  const cif_fob_style = {
    backgroundColor: '#ffff',
    color: '#007bff',
    textTransform: "capitalize",
    borderRadius: 4,
    border: '1px solid #007bff',
    height: 30,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100px"

  }

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={importShipments}
          handleOpen={handleOpen}
          buttonTitle="Create"
          handleFilterButton={filterOpen}
          fiterButtonTitile="Fiter"

        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createimportshipement
            handleClose={handleClose}
            fetchImportShipments={fetchImportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            suppliersList={suppliersList}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateImportshipement
            handleClose={handleCloseUpdate}
            importShipmentId={selectedId}
            fetchImportShipments={fetchImportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            suppliersList={suppliersList}
          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />

        <CustomFilter
          drawerTitle="Filter Options"
          drawerWidth={350}
          openDrawer={openDrawer}
          onClose={filterClose}
          drawerContent={
            <>
              <Box>
                <Grid container spacing={1} sx={{ padding: 0, }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Shipment</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="number"
                      placeholder="Enter Shipment ID"
                      value={filterPayload.shipmentId}
                      onChange={(e) => handleFilterChange('shipmentId', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Supplier</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={suppliersList}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Supplier"
                      onChange={(event, newValue) => handleFilterChange("supplierId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Currency</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={currencyList}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Currency"
                      onChange={(event, newValue) => handleFilterChange("currencyId", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>From Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.fromDate}
                      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                      placeholder="From Date"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>To Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.toDate}
                      onChange={(e) => handleFilterChange('toDate', e.target.value)}
                      placeholder="To Date"
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>CIF/FOB</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CustomButton
                      onClick={handleCIFClick}
                      title="CIF"
                      size="small"
                      variant="outlined"
                       style={{
                        ...cif_fob_style,
                        ...(activeButton === "1" ? activeStyle : {}),
                      }}

                    />
                    <CustomButton
                      onClick={handleFOBClick}
                      title="FOB"
                      size="small"
                      variant="outlined"
                       style={{
                        ...cif_fob_style,
                        ...(activeButton === "2" ? activeStyle : {}),
                      }}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "130px" }}>
                    <CustomButton
                      startIcon={<FindReplaceOutlinedIcon />}
                      title="Apply"
                      size="small"
                      variant="contained"
                      onClick={fetchImportShipments}
                    />
                    <CustomButton
                      variant="outlined"
                      title="Clear"
                      startIcon={<ClearRounded />}
                      style={cif_fob_style}
                      onClick={handleClearFilters}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />
      </Box >
    </>
  );
};

export default ImportShipment;

const activeStyle = {
  backgroundColor: "#007BFF",
  color: "#ffffff",
  border: "1px solid #007BFF",
};
