import React, { useContext, useMemo, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_PAYMENT_REGISTER } from "../../services/apiRoutes";
const title = "Payment Register";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: 'paymentVoucherId', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'voucherNo', header: 'Voucher No.', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 100 },
            { accessorKey: 'shipmentType', header: 'Type', size: 150 },
            { accessorKey: 'supplierName', header: 'Supplier Name', size: 150 },
            { accessorKey: 'amount1', header: 'Amount', size: 100 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_PAYMENT_REGISTER });
            if (response.data.success && response.data.paymentVoucher) {
                const paymentVoucherData = response.data.paymentVoucher.map((voucher) => ({
                    paymentVoucherId: voucher.paymentVoucherId || '',
                    date: voucher.date || '',
                    voucherNo: voucher.paymentVoucherNo || '',
                    supplierName: voucher.clientCompany?.clientCompanyName || '',
                    paymentType: voucher.paymentType?.paymentName || '',
                    shipmentType: voucher.commanShipmentId === 1 ? 'Export Shipment' : 'Import Shipment',
                    amount1: voucher.amount || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amount2: voucher.amountInBaseCurrency || '',
                    bankCharges: voucher.bankCharges || '',
                }));
                setData(paymentVoucherData);
            } else {
                setError('No payment vouchers found');
            }
        } catch (error) {
            setError('Error fetching payment vouchers');
            console.error('Error fetching payment vouchers', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])


    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />
            </Box>
        </>
    );
};
export default Function;
