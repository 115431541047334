import React, { useState } from 'react';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CreateNotes from '../CreateOverView/CreateNotes';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';

export function Notes() {
    const [leadModal, setLeadModal] = useState(false);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };

    const NotesColumn = [
        {
            id: 'sn',
            label: 'SN',
            accessor: 'sn',
            sx: { width: { xs: "40px", sm: "40px", md: "40px" } },
        },
        { id: 'notes', label: 'Notes', accessor: 'notes' },

    ];

    return (
        <>
            <DetailsPageCard
                Icon={NoteOutlinedIcon}
                columns={NotesColumn}
                title={'Notes'}
                buttonTitle={'Create Notes'}
                handleOpen={handleOpen}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateNotes />
            </CustomModal>
        </>
    );
}
