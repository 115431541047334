import React from "react";
import {
  Divider,
  Button,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableFooter,
  Card,
  Box,
  TableHead,
  Grid,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { PhoneEnabled, West } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";


const DetailsPageCard = ({ columns, data, title, buttonTitle, handleOpen,
  footerData,
  footerCellStyles,
  // Icon,
  Icon = ReceiptOutlinedIcon,
  style
}) => {
  return (
    <>
      <Box id="tasks">
        <Card variant="outlined"
          sx={{ borderBottom: 'none', borderRadius: 0 }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={styles.cardtittile}>
              {Icon && <Icon style={{ marginRight: "8px", fontSize: "20px", color: "#6c757d" }} />}
              {title}
            </span>
            <span style={{ marginRight: "12px" }}>
              {buttonTitle && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowDropDownRoundedIcon />}
                  // onClick={handleMenuClick}
                  style={styles.tablebutton}
                  onClick={handleOpen}
                >
                  {buttonTitle}
                </Button>
              )}

            </span>
          </div>
        </Card>
        {/* <Divider /> */}
        <div style={{ padding: "0rem" }}>
          <TableContainer >
            <Table
              style={{
                width: "100%",
                border: "1px solid #dee2e6",
                borderCollapse: "collapse",
              }}
            >
              <TableHead>
                <TableRow style={{ border: "1px solid #dee2e6" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      colSpan={column.colSpan || 1}
                      sx={{
                        ...column.sx,
                      }}
                      style={{ ...styles.cellLabel, ...column.style }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((row, rowIndex) => (
                    <TableRow key={rowIndex}
                      style={{
                        border: "1px solid #dee2e6",
                        color: "#212529",
                        fontWeight: "600",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "clip",
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{ ...styles.cellAccessor, ...column.styleAccessor }}
                        >
                          {column.Cell ? (
                            <column.Cell row={row} />
                          ) : (
                            // row[column.accessor]
                            column.accessor.split('.').reduce((acc, key) => acc && acc[key], row) || ''
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ border: "1px solid #dee2e6" }}>
                    <TableCell
                      colSpan={columns.length}
                      style={{
                        textAlign: "center",
                        border: "1px solid #dee2e6",
                        color: "#adb5bd",
                      }}
                    >
                      No Record Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {/* Table Footer */}
              {footerData && (
                <TableFooter>
                  <TableRow>
                    {footerData.map((footer, index) => (
                      <TableCell
                        colSpan={footer.colSpan || 1}
                        key={index} style={{ ...styles.footerCell, ...footer.style }}>
                        {footer.label} {footer.footerValue}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </div>
      </Box>
    </>
  );
};

export default DetailsPageCard;

const styles = {
  table: {
    minWidth: 650,
  },
  textMuted: {
    color: "#6c757d",
  },
  tableCell: {
    paddingBottom: "8px",
    paddingTop: "8px",
    borderBottom: "0px",
  },
  labelCell: {
    width: "180px",
  },
  tableContainer: {
    marginBottom: "16px",
  },
  header: {
    marginBottom: "16px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  customTableRow: {
    height: "20px",
  },
  editButton: {
    textAlign: "right",
  },
  showDetailsSpan: {
    padding: "8px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#212529",
    cursor: "pointer",
  },
  expandIcon: {
    color: "#212529",
  },
  boxheading: {
    width: 70, // Width of the box
    height: 70, // Height of the box, making it square
    // background: 'radial-gradient(circle, #0086c9 0, rgba(0, 134, 201, .5) 0, #0086c9 100%)', // Radial gradient background
    background:
      "radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)", // Radial gradient background
    color: "white", // Text color
    display: "flex", // Center content horizontally
    alignItems: "center", // Center content vertically
    justifyContent: "center", // Center content horizontally
    borderRadius: 14, // Rounded corners
    boxShadow: 3, // Box shadow for depth
  },
  headingname: {
    paddingTop: "10px",
    fontWeight: "600",
    fontSize: "20px",
    color: "#212529",
  },
  avatarcircle: {
    backgroundColor: "#007BFF",
    width: "30px",
    height: "30px",
    fontSize: "15px",
  },
  cardtittile: {
    padding: "8px 8px 8px 8px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#212529",
    // color: "#007BFF",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",

  },
  tablebutton: {
    backgroundColor: "#007bff",
    color: "#ffff",
    textTransform: "capitalize",
    borderRadius: 4,
    border: "2px solid #007bff",
    height: 30,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tablecell: {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "left",
  },
  footerCell: {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
    whiteSpace: "nowrap",
    padding: "5px",
  },

  cellLabel: {
    border: "1px solid #dee2e6",
    // color: "#212529",
    color: "#434b53",
    fontWeight: "500",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "clip",
    padding: "5px",
    textAlign: 'center',
    textTransform: 'capitalize',
  },

  cellAccessor: {
    border: "1px solid #dee2e6",
    padding: "5px",
    textAlign: "left",
    textTransform: 'capitalize',
  },
};
