import React, { useState } from 'react';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, CallCardColumns, NotesCardColumns, ExportMenu } from '../../constants/DetailsPage'
import { Main } from '../../constants/DetailsMain';
import { Call } from '../Leads/OverViewMenu/Call';
import { Tasks } from '../Leads/OverViewMenu/Tasks';
import { Notes } from '../Leads/OverViewMenu/Notes';
import ExportDetail from './ExportMenu/ExportDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { useExportShipmentDetails } from './ExportMenu/Hook/useExportShipmentDetails';
import CustomCollapse from '../common/CustomCollapse';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CustomLoader from '../common/CustomLoader';

export default function PersistentDrawerLeft() {

    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview'); // Track active tab
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();

    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    if (!shipmentDetails) {
        return <p> <CustomLoader open={shipmentLoading} /></p>;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const firstName = shipmentDetails.user.firstName;
    const lastName = shipmentDetails.user.lastName;
    const email = shipmentDetails.user.email;
    const mobile = shipmentDetails.user.mobile;

    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const adminData = [
        {
            title: "Owner",
            defaultExpanded: false,
            details: [
                { label: 'Owner', value: `${firstName || ''} ${lastName || ''}`.trim() },
                { label: 'Email', value: email, sm: 12, md: 12 },
                { label: 'Mobile', value: mobile, sm: 12, md: 12 },

            ],
        },
    ];

    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}> */}
                {/* <DetailPageClient /> */}
                <ExportDetail />
                <br />
                <Call />
                <br />
                <Tasks />
                <br />
                <Notes />
                <br />
                {/* <DetailsPageCompany /> */}
                <CustomCollapse Icon={PersonOutlineOutlinedIcon} data={adminData} />

                {/* <br />
                </Main> */}
            </div >
        </>
    );
}

