import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateReceiptVoucher from '../CreateForm/CreateReceiptVoucher';
import UpdateReceiptVoucher from '../UpdateForm/UpdateReceiptVoucher';
import MessageBox from '../../common/CustomMessageBox';
import { LIST_RECEIPT_VOUCHERS_EXPORT, GET_SUPPLIERS_LIST } from '../../../services/apiRoutes';
import { getCommanImportShipmentId, getCurrencyList, getReceiptType } from '../../../services/CommonServices';
import { genericPost } from '../../../services/authServices';
import ReceiptVoucherView from '../ViewDetails/ReceiptVoucherView';
import { useShipmentDetails } from './useShipmentDetails';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CustomStatus from '../../common/CustomStatus';


const ReceiptVoucher = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Receipt Voucher');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [receiptVoucherId, setReceiptVoucherId] = useState('');
    const [currencyList, setCurrencyList] = useState([]);
    const [receiptType, setReceiptType] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const navigate = useNavigate();
    const { importShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);


    let customerId, shipmentId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;
            shipmentId = shipmentDetails.shipmentId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            getCommonData();
            getCustomersList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, receiptVoucherId, token, modal]);


    const getCustomersList = async () => {
        try {
            const payload = {
                clientCompanyId: customerId,
            };

            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });

            if (response.data && response.data.success && response.data.clientCompanies) {
                const customersData = response.data.clientCompanies.map((customer) => ({
                    id: customer.clientCompanyId,
                    title: customer.clientCompanyName,
                }));
                setCustomerList(customersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching customer list:', error);
            return [];
        }
    };


    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const receiptType = await getReceiptType();
        setReceiptType(receiptType);
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                customerName: customerName,
                commanShipmentId: getCommanImportShipmentId

            };
            const response = await genericPost(LIST_RECEIPT_VOUCHERS_EXPORT, payload);

            if (response.data.success) {
                const receiptVouchers = response.data.receiptVouchers.map((voucher) => ({
                    receiptVoucherId: voucher.receiptVoucherId || '',
                    userId: voucher.userId || '',
                    companyId: voucher.companyId || '',
                    customerName: voucher.customerName || '',
                    date: voucher.date || '',
                    receiptVoucherNo: voucher.receiptVoucherNo || '',
                    receiptTypeName: voucher.receiptType?.receiptTypeName || '',
                    amount: parseFloat(voucher.amount) || 0,
                    currencyName: voucher.currency?.currency || '',
                    finalAmount: `${voucher.currency?.currency || ''} ${parseFloat(voucher.amount) || ""}`,
                    baseCurrencyName: voucher.baseCurrency?.currency || '',
                    bankCharges: parseFloat(voucher.bankCharges) || 0,
                    baseCurrencyId: voucher.baseCurrencyId || '',
                    conversionRate: parseFloat(voucher.conversionRate) || 0,
                    baseAmount: parseFloat(voucher.baseAmount) || 0,
                    note: voucher.note || '',
                    createdAt: voucher.createdAt || '',
                    updatedAt: voucher.updatedAt || '',
                    shipmentIdName: shipmentId || '',
                }));

                setData(receiptVouchers);
            } else {
                setError('No receipt vouchers found');
            }
        } catch (error) {
            setError('Error fetching receipt vouchers');
            console.error('Error fetching receipt vouchers', error);
        } finally {
            setLoading(false);
        }
    };


    // Table columns
    const columns = [
        { id: 'receiptVoucherId', label: 'SN', accessor: 'receiptVoucherId' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'receiptVoucherNo', label: 'Receipt No.', accessor: 'receiptVoucherNo' },
        {
            id: 'receiptTypeName',
            label: 'Receipt Type',
            accessor: 'receiptTypeName',
            Cell: ({ row }) => (
                <CustomStatus
                    title={row.receiptTypeName}
                    sx={{
                        backgroundColor: "#F9CEEE",
                        color: "#38001c",
                        width: "150px",
                    }}
                />
            ),
        },

        {
            id: 'finalAmount', label: 'Amount', accessor: 'finalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'conversionRate', label: 'Conversion Rate', accessor: 'conversionRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'baseAmount', label: 'Amount Base currency', accessor: 'baseAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'baseCurrencyName', label: 'Base currency', accessor: 'baseCurrencyName'
        },
        {
            id: 'bankCharges', label: 'Bank Charges', accessor: 'bankCharges', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.receiptVoucherId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.receiptVoucherId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.receiptVoucherId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];



    const handleUpdateClick = (id) => {
        setReceiptVoucherId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setReceiptVoucherId('');
    };
    const handleViewClick = (id) => {
        setReceiptVoucherId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setReceiptVoucherId('');
    };

    if (!shipmentDetails) {
        return <p> <CustomLoader open={shipmentLoading} /> </p>;
    }
    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const customerName = shipmentDetails.supplierId;
    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';


    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}
            <CustomLoader open={loading} />
            <DetailsPageCard
                Icon={ReceiptLongOutlinedIcon}
                columns={columns}
                title="Receipt Voucher"
                buttonTitle="Create Receipt Voucher"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateReceiptVoucher
                    fetchData={fetchData}
                    customerList={customerList}
                    currencyList={currencyList}
                    receiptType={receiptType}
                    customerName={customerName}
                    commanShipmentId={getCommanImportShipmentId}
                    setMessage={setMessage}
                    handleClose={handleClose} />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateReceiptVoucher
                    fetchData={fetchData}
                    currencyList={currencyList}
                    receiptType={receiptType}
                    customerList={customerList}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    receiptVoucherId={receiptVoucherId}
                />
            </CustomModal>
            <CustomModal
                modalStyles={{
                    maxHeight: '50vh',
                    top: "10%",
                }}
                open={viewModal} handleClose={handleCloseView}>
                <ReceiptVoucherView
                    fetchData={fetchData}
                    currencyList={currencyList}
                    receiptType={receiptType}
                    customerList={customerList}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    receiptVoucherId={receiptVoucherId}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div>
    );
};

export default ReceiptVoucher;
