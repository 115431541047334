import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { GET_BATCH_WISE_EXP_SHIPMENT } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import { formatDate } from "../../services/CommonServices";
const title = "Batch No Wise Export Shipment Report";

const BatchNoWiseExportShipmentReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: 'shippingMarkId', header: 'SN', size: 50 },
            { accessorKey: 'createdAt', header: 'Date', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 100 },
            { accessorKey: 'piNo', header: 'PI No.', size: 100 },
            { accessorKey: 'BatchNo', header: 'Batch No.', size: 100 },
            { accessorKey: 'clientCompanyName', header: 'Customer Name', size: 150 },
            { accessorKey: 'country', header: 'Country', size: 100 },
            { accessorKey: 'ShippingMark', header: 'Shipping Mark', size: 150 },
            { accessorKey: 'blNo', header: 'BL No.', size: 100 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_BATCH_WISE_EXP_SHIPMENT });
            if (response.data.success && response.data.shippingmark) {
                const shippingMarkData = response.data.shippingmark.map((mark) => ({
                    shippingMarkId: mark.shippingMarkId || '',
                    companyId: mark.companyId || '',
                    clientCompanyName: mark.clientCompany?.clientCompanyName || '',
                    country: mark.clientCompany?.mailingCountry?.name || '',
                    BatchNo: mark.batchNo || '',
                    ProductId: mark.productsList.productName || '',
                    ShippingMark: mark.shippingMark || '',
                    createdAt: formatDate(mark.createdAt),
                    updatedAt: mark.updatedAt || '',
                }));
                setData(shippingMarkData);
            } else {
                setError('No shipping marks found');
            }
        } catch (error) {
            setError('Error fetching shipping marks');
            console.error('Error fetching shipping marks', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])


    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default BatchNoWiseExportShipmentReport;
