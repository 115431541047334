import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import { Box, colors, Grid, Typography } from '@mui/material';

// Styles for the components
const styles = {
    title: {
        display: 'flex',
        textTransform: 'capitalize',
        padding: '0px 0px 0px 8px',
        fontWeight: 600,
        fontSize: '16px',
        color: 'rgb(33, 37, 41)',

    },
    expandIcon: {
        color: '#007BFF',
    },
};

const labelStyle = {
    fontWeight: 400,
    minWidth: '100px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: "rgb(108, 117, 125);",
    fontSize: "16px",
};

const dataStyle = {
    textAlign: 'left',
    ml: 2,
    mr: 2,
    textTransform: 'capitalize',
    fontSize: "16px",
};

const dessStyle = {
    fontWeight: 300,
    textAlign: 'left',
    ml: 1,
};

const bodyStyle = {
    overflowX: 'auto',
    padding: '15px 0px 0px 35px',

};

const CustomCollapse = ({ data, Icon }) => {
    return (
        <div>
            {data.map((item, index) => (
                <Accordion key={index} defaultExpanded={item.defaultExpanded} sx={{ borderRadius: '8px', boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.12)', pr: "8px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={styles.expandIcon} />} aria-controls={`panel${index + 1}-content`} id={`panel${index + 1}-header`} style={styles.title}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {Icon && <Icon style={{ marginRight: "8px", fontSize: "20px", color: "#6c757d" }} />}
                            <span>{item.title}</span>
                        </div>
                    </AccordionSummary>
                    <Box>
                        <Divider />
                        <Box sx={bodyStyle}>
                            <Grid container spacing={1}>
                                {item.details.map((detail, i) => (
                                    <Grid key={i} item xs={12} sm={detail.sm} md={detail.md}>
                                        <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
                                            <Typography variant="h5" sx={labelStyle}>
                                                {detail.label}
                                            </Typography>
                                            <Typography variant="h5" sx={dessStyle}>-</Typography>
                                            <Typography variant="h5" sx={dataStyle}>{detail.value}</Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Accordion>
            ))}
        </div>
    );
};

export default CustomCollapse;
