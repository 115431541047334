import React, { useState, useEffect, useMemo } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import { AppBar as MuiAppBar } from '@mui/material';
import { Box, IconButton, Toolbar, Stack, Button, Avatar, Drawer, List, ListItem, ListItemText, ListItemButton, Menu, MenuItem } from '@mui/material'
import { styled, useTheme } from '@mui/system';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DetailsPageHeader from './DetailsPageHeader'
import { Link } from 'react-router-dom';
import { genericGet } from '../../../services/apiServices';
import { GET_call, GET_task } from '../../../services/apiRoutes';

import { CardContent, Typography, Card } from '@mui/material';
import { PhoneEnabled, West } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import CustomLoader from '../CustomLoader';

const DetailsPageAppbar = ({
    handleScrollTo,
    activeTab,
    handleDrawerOpen,
    open,
    handleDrawerClose,
    AppBarMenu,
    menu,
    value,
    to,

    companyName,
    phoneNumber,
    initial,
    onBackClick

}) => {

    const drawerWidth = 200;


    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // justifyContent: 'flex-end',
        justifyContent: 'flex-start',
    }));
    const theme = useTheme();
    // const AppBarMenu = ['Calls', 'overview', 'Purchase Order', 'Shipping Mark', 'Shipment Booking', 'Document Management']
    const [callData, setCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [taskData, setTaskData] = useState([]);

    const [totalCallIdSum, setTotalCallIdSum] = useState(0);
    const [numberOfTasks, setNumberOfTasks] = useState(0);

    const fetchData = async () => {
        try {
            const response = await genericGet({ url: GET_call });
            if (response.data.success && response.data.calls) {
                setCallData(response.data.calls);
                // Calculate total sum of callId
                const numberOfCalls = response.data.calls.length;
                setTotalCallIdSum(numberOfCalls);

            } else {
                setError('No calls found');
            }
        } catch (error) {
            setError('Error fetching call data');
            console.error('Error fetching call data', error);
        } finally {
            setLoading(false);
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const fetchTaskData = async () => {
        try {
            const response = await genericGet({ url: GET_task });

            if (response.data.success && response.data.tasks) {
                setTaskData(response.data.tasks);
                // Calculate the number of objects in the tasks array
                const numberOfTasks = response.data.tasks.length;
                setNumberOfTasks(numberOfTasks);
            } else {
                setError('No tasks found');
            }
        } catch (error) {
            setError('Error fetching task data');
            console.error('Error fetching task data', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
        fetchTaskData();
    }, []);

    return (
        <>
            {/* <DetailsPageHeader /> */}

            <Card variant="outlined" style={{
                position: 'fixed',
                top: '40px',
                width: '100%',
                height: "85px",
                zIndex: 1,
            }}>
                <CustomLoader open={loading} />

                <CardContent style={{ display: "flex", }}>
                    <div style={{ paddingTop: "0px", paddingRight: "10px", display: "flex" }}>
                        <Box
                            onClick={onBackClick}
                            // to="/app/leads"

                            style={{ paddingTop: "15px", paddingRight: "3px" }}>
                            <West style={{ color: "rgb(33, 37, 41)" }} />
                        </Box>
                        <Box
                            style={stylesHeading.boxHeading}
                        >
                            <span style={{ fontSize: "45px" }}>{initial}</span>
                        </Box>
                    </div>
                    <div style={{ paddingTop: "1px" }}>
                        <Typography variant="h5" component="div"
                            style={stylesHeading.headingName}>
                            {companyName}
                        </Typography>
                        <Typography variant="body2">
                            <span style={{ padding: "1px", }}>
                                <PhoneEnabled style={{ fontSize: "15px" }} />
                            </span>
                            <a href={`tel:${phoneNumber}`} style={{ color: "#343a40" }}> {phoneNumber}</a>
                        </Typography>
                    </div>
                </CardContent>
            </Card>

            {/* <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff", color: "#000", top: 115 }}> */}
            <AppBar
                position="fixed"
                open={open}
                style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    top: 115,
                    transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    ...(open && {
                        width: `calc(100% - ${drawerWidth}px)`,
                        marginLeft: `${drawerWidth}px`,
                        transition: 'margin 0.0s ease-in-out, width 0.0s ease-in-out',
                    }),
                }}
            >
                <Toolbar>
                    <IconButton
                        // color="inherit"
                        style={{ color: "#373A40" }}
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <ChevronRightIcon />
                    </IconButton>

                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            paddingBottom: '3px',
                            '&::-webkit-scrollbar': {
                                height: 4,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#E7E9EB',
                                borderRadius: 4,
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#f1f1f1',
                            },
                        }}
                    >
                        {AppBarMenu.slice(0, 6).map((item) => <>
                            <Link to={item.to} key={item.value} style={{ textDecoration: 'none' }}>

                                <Button
                                    variant={activeTab === menu ? "contained" : "outlined"}
                                    onClick={() => handleScrollTo(item.menu)}
                                    endIcon={
                                        item.value && (
                                            <Avatar
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                    fontSize: 10,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: activeTab === item.menu ? '#ffff' : '#007bff',
                                                    color: activeTab === item.menu ? '#007bff' : '#ffff',
                                                }}
                                            >
                                                <span>{item.value}</span>
                                            </Avatar>
                                        )
                                    }
                                    style={{
                                        backgroundColor: activeTab === item.menu ? '#007bff' : '#ffff',
                                        color: activeTab === item.menu ? '#ffff' : '#007bff',
                                        textTransform: "capitalize",
                                        borderRadius: 4,
                                        border: '1px solid #007bff',
                                        height: 30,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {item.menu}

                                </Button>
                            </Link>
                        </>
                        )}



                        {AppBarMenu.length > 6 && (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    style={{
                                        backgroundColor: '#007bff',
                                        color: '#ffff',
                                        textTransform: 'capitalize',
                                        borderRadius: 4,
                                        border: '1px solid #007bff',
                                        height: 30,
                                    }}
                                >
                                    More
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {AppBarMenu.slice(6).map((item) => (

                                        <MenuItem
                                            key={item.value}
                                            onClick={() => {
                                                handleScrollTo(item.menu);
                                                handleClose();
                                            }}
                                            style={{
                                                backgroundColor: activeTab === item.menu ? '#007bff' : 'inherit',
                                                color: activeTab === item.menu ? '#ffff' : 'inherit',
                                            }}
                                        >
                                            <Link
                                                to={item.to}
                                                style={{
                                                    textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                    width: '100%'
                                                }}
                                            >
                                                <span style={{ marginRight: 4 }}>{item.menu}</span>

                                                <Avatar
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        fontSize: 10,
                                                        backgroundColor: activeTab === item.menu ? '#ffff' : '#007bff',
                                                        color: activeTab === item.menu ? '#007bff' : '#ffff',
                                                    }}
                                                >
                                                    <span>{item.value} </span>
                                                </Avatar>
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </>

                        )}
                    </Stack>
                </Toolbar>
            </AppBar >
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        marginTop: '115px',
                        // height: `calc(100% - 200px)`,
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}
                        style={{ color: "#373A40" }}
                    >
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton> <span style={{ color: "#212529", fontSize: "24px", fontWeight: "600" }}> Related List</span>
                </DrawerHeader>
                <List >
                    <ListItem>
                        <ListItemButton onClick={() => handleScrollTo('calls')}>
                            <ListItemText primary="Calls" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}> {totalCallIdSum} </Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton onClick={() => handleScrollTo('tasks')}>
                            <ListItemText primary="Tasks" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>{numberOfTasks}</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton onClick={() => handleScrollTo('notes')}>
                            <ListItemText primary="Notes" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>

                    <ListItem style={{ marginTop: "-22px" }}>
                        <ListItemButton onClick={() => handleScrollTo('emails')}>
                            <ListItemText primary="Emails" />
                            <span className="badge">
                                <Avatar style={styles.avatarcircle}>0</Avatar>
                            </span>
                        </ListItemButton>
                    </ListItem>
                </List>
                {/* <Divider />  */}

            </Drawer>
        </>
    )
}

export default DetailsPageAppbar

const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },
    tableCell: {
        paddingBottom: '8px',
        paddingTop: '8px',
        borderBottom: '0px',
    },
    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: "8px",
        fontWeight: 600,
        fontSize: "16px",
        color: "#212529",
        cursor: 'pointer'
    },
    expandIcon: {
        color: "#212529",
    },
    boxheading: {
        width: 70,  // Width of the box
        height: 70, // Height of the box, making it square
        // background: 'radial-gradient(circle, #0086c9 0, rgba(0, 134, 201, .5) 0, #0086c9 100%)', // Radial gradient background
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingname: {
        paddingTop: "10px",
        fontWeight: "600",
        fontSize: "20px",
        color: "#212529"
    },
    avatarcircle: {
        backgroundColor: "#007BFF",
        width: "30px",
        height: "30px",
        fontSize: "15px"
    },
    cardtittile: {
        padding: "8px",
        paddingLeft: "35px",
        fontWeight: 600,
        fontSize: "16px",
        color: "#212529"
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: "capitalize",
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'left'
    }
};

const stylesHeading = {
    boxHeading: {
        width: 50,  // Width of the box
        height: 50, // Height of the box, making it square
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingName: {
        paddingTop: "0px",
        fontWeight: "600",
        fontSize: "16px",
        color: "#212529",
        textTransform: 'capitalize',
    },
};
