const CustomStyle = {
    container: {
        position: "sticky",
        display: "flex",
        alignItems: "center",
    },
    title: {
        color: "#212529",
        fontSize: "18px",
        fontWeight: 600,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonBox: {
        display: "flex",
        gap: "1px",
    },
};

export default CustomStyle;
