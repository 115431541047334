import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_RECEIPT_REGISTER } from "../../services/apiRoutes";
const title = "Receipt Register";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: 'receiptVoucherId', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'receiptVoucherNo', header: 'Voucher No.', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 100 },
            { accessorKey: 'shipmentType', header: 'Type', size: 150 },
            { accessorKey: 'supplierName', header: 'Supplier Name', size: 150 },
            { accessorKey: 'amount', header: 'Amount', size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton  >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_RECEIPT_REGISTER });
            if (response.data.success) {
                const receiptVouchers = response.data.receiptVouchers.map((voucher) => ({
                    receiptVoucherId: voucher.receiptVoucherId || '',
                    userId: voucher.userId || '',
                    companyId: voucher.companyId || '',
                    commanShipmentId: voucher.commanShipmentId || '',
                    shipmentType: voucher.commanShipmentId === 1 ? 'Export Shipment' : 'Import Shipment',
                    supplierName: voucher.customer?.clientCompanyName || '',
                    date: voucher.date || '',
                    receiptVoucherNo: voucher.receiptVoucherNo || '',
                    paymentName: voucher.paymentType?.paymentName || '',
                    amount: parseFloat(voucher.amount) || 0,
                    currencyId: voucher.currencyId || '',
                    bankCharges: parseFloat(voucher.bankCharges) || 0,
                    baseCurrencyId: voucher.baseCurrencyId || '',
                    conversionRate: parseFloat(voucher.conversionRate) || 0,
                    baseAmount: parseFloat(voucher.baseAmount) || 0,
                    note: voucher.note || '',
                    createdAt: voucher.createdAt || '',
                    updatedAt: voucher.updatedAt || '',
                }));

                setData(receiptVouchers);
            } else {
                setError('No receipt vouchers found');
            }
        } catch (error) {
            setError('Error fetching receipt vouchers');
            console.error('Error fetching receipt vouchers', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default Function;
