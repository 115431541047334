import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Box, Typography, Divider, } from '@mui/material';
import { Formik, Form } from 'formik';
import CustomButton from "../common/CustomButton";
import { ClearRounded } from "@mui/icons-material";
import { GET_contactId } from '../../services/apiRoutes';
import CustomCardView from '../common/CustomCardView';


const ContactsView = ({ handleClose, contactId, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!contactId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_contactId(contactId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.contact);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [contactId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const labels = [
        { key: 'ContactOwner', label: "Contact Owner", sm: 12, md: 8, },
        { key: 'companyName', label: "Company", sm: 12, md: 4 },
        { key: 'ContactPerson', label: "Contact Person", sm: 12, md: 4 },
        { key: 'email', label: "Email", sm: 12, md: 4 },
        { key: 'mobile', label: "Mobile", sm: 12, md: 4 },
        { key: 'departmentName', label: "Department", sm: 12, md: 12 },
        { key: 'address', label: "address", sm: 12, md: 7 },
    ];

    const values = {
        ContactOwner: `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}`.trim(),
        ContactPerson: `${data?.titlesModel?.titleName || ''} ${data?.firstName || ''} ${data?.lastName || ''}`.trim(),
        email: data?.clientCompany?.email,
        mobile: data?.clientCompany?.mobile,
        companyName: data?.clientCompany?.clientCompanyName,
        departmentName: data?.department?.contactDepartmentName,
        address: `${data?.mailingStreet || ''}, ${data?.millingCity?.name || ''}, ${data?.millingState?.name || ''}, ${data?.millingCountry?.name || ''}, ${data?.millingPincode || ''}.`.trim(),
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={values}

            >
                {({ }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px", paddingTop: "5px" }}>
                                    Contact Details
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <CustomCardView
                            label={labels}
                            data={values}
                        />
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ContactsView;














